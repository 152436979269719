import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { SeoService } from '../services/seo.service';
import { first } from "rxjs/operators";
import { FirebaseService } from "../services/firebase.service";
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  poll:any;
  items:any;
  totalVotes:any;
  currentUser:any;

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private seo: SeoService,
    public afAuth: AngularFireAuth,
  ) { }

  ngOnInit() {
    this.seo.generateTags({
      title: 'Poll Sports | Home',
      description: `Instant fantasy football advice through real-time polls. Create a poll with your custom league settings and get votes from thousands of fantasy players.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: ``
    });
    let menu = document.getElementById("menu");
    if (menu) {
      menu.style.display = "none";
    }

    this.afAuth.authState.pipe(first()).subscribe((user) => {
      this.currentUser = user;
    });

    this.firebaseService.getMostRecentPoll().then((response:any) => {
      this.poll = response.poll
    });

    this.firebaseService.getStats().subscribe((stats) => {
      this.totalVotes = this.numberWithCommas(stats.payload.get('votes'));
    });

    this.items = [
      {user: "brianrzeppa", quote: "I like the ability to get instant feedback on how the fantasy community is leaning in terms of start/sits and trades."},
      {user: "J", quote: "I like that it's easy to quickly get opinions of several real life people, and it offers a trade resource that is IMO better than anywhere else. Poll Sports paints a more accurate picture since it gathers more data points."},
      {user: "archi_hoo", quote: "Poll Sports has helped me to complete some fair trades this past season. I also love the credibility feature."},
      {user: "lukesidiotdad", quote: "I like the credibility and accuracy scores. If I or anyone else (average fantasy football players) look at a poll I don’t want to see what everyone says I only want to know what the smart people are saying lol."},
      {user: "Gambit2299", quote: "This site is badass. Everyone should get on it!"},
      {user: "zbarkley", quote: "I like that I am able to get a lot more opinions on my fantasy football decisions. Posting on Reddit, or Sleeper I'll maybe get 1 comment, usually it goes unanswered. Poll Sports makes it easier for both users. It's SUPER easy to help other user questions by using a multiple choice style format."},
      {user: "deRoyLight", quote: "This site is very useful so I hope more people find it and continue using it."},
      {user: "J", quote: "I really like killing time by answering other polls and comparing the sides I choose to the consensus to help me identify which players I view higher or lower than most people, which helps identify personalized buy/sell targets."},
      {user: "Akai-jam", quote: "I wish there was a way for me to invest in this website. I also wish there was a way for me to have a significant amount of money to invest."},
      {user: "Killtec7", quote: "I had a little rant a few days ago about content contributors and what they really provide. The gist of it was most content is bunk, rehashed, non-unique and contributes little if anything. None of that applies here. Seriously, this is a fairly original idea and excellently executed for an early release."},
      {user: "Akai-jam", quote: "Oh man this is a fucking fantastic idea for a website and so much better than trade threads or trying to get someone to retweet a poll on Twitter."},
    ]
  }

  continue() {
    this.router.navigate(['/feed/football']).then(() => {
      let menu = document.getElementById("menu");
      if (menu) {
        menu.style.display = "";
      }
    });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { Router } from '@angular/router';
import { TrackingService } from '../services/tracking.service';


@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit {
  @Input()
  vote:any;

  @Input()
  recipientIds:any;

  date:any;
  showBadge:boolean = false;
  percentile:any;
  displayPercentile:boolean = false;
  highlight:boolean = false;

  rank:string;

  constructor(
    private router: Router,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    if (this.vote.credibility) {
      this.showBadge = true;
    }
    if (this.vote.percentile > 0 && this.vote.percentile <= 0.25) {
      this.setPercentile();
      this.displayPercentile = true;
    }
    this.badgeSrc()
    if (this.vote.createdAt) {
      let d = new Date(0)
      d.setUTCSeconds(this.vote.createdAt.seconds);
      this.date = d;
    }
    if (this.recipientIds && this.recipientIds.includes(this.vote.uid)) {
      this.highlight = true;
    }
  }

  trackVoteClick(vote) {
    this.trackingService.track('Click Vote Credibility Badge', {
      rank: this.rank,
      credibility: vote.credibility,
      percentile: vote.percentile,
      username: vote.username
    });
  }

  setPercentile() {
    if (this.vote) {
      let percentile = this.vote.percentile
      if (percentile > 0 && percentile <= 0.01) {
        this.percentile = "1%"
      }
      if (percentile > 0.01 && percentile <= 0.05) {
        this.percentile = "5%"
      }
      if (percentile > 0.05 && percentile <= 0.10) {
        this.percentile = "10%"
      }
      if (percentile > 0.10 && percentile <= 0.15) {
        this.percentile = "15%"
      }
      if (percentile > 0.15 && percentile <= 0.20) {
        this.percentile = "20%"
      }
      if (percentile > 0.20 && percentile <= 0.25) {
        this.percentile = "25%"
      }
    }
  }
  badgeSrc() {
    if (this.vote) {
      const credibility = this.vote.credibility;
      if (this.vote.isInfluencer) {
        this.rank = "Analyst"
        return "/assets/badges/no-text/badge-analyst.png"
      } else if (credibility === 0) {
        this.rank = "Guest"
        return "/assets/badges/no-text/badge-00.svg"
      } else if (credibility < 1000) {
        this.rank = "Rookie"
        return "/assets/badges/no-text/badge-01.svg"
      } else if (credibility >= 1000 && credibility < 2500) {
        this.rank = "Sophomore"
        return "/assets/badges/no-text/badge-02.svg"
      } else if (credibility >= 2500 && credibility < 5000) {
        this.rank = "Captain"
        return "/assets/badges/no-text/badge-03.svg"
      } else if (credibility >= 5000 && credibility < 10000) {
        this.rank = "Veteran"
        return "/assets/badges/no-text/badge-04.svg"
      } else if (credibility >= 10000 && credibility < 20000) {
        this.rank = "All-Pro"
        return "/assets/badges/no-text/badge-05.svg"
      } else if (credibility >= 20000 && credibility < 50000) {
        this.rank = "Hall of Fame"
        return "/assets/badges/no-text/badge-06.svg"
      } else if (credibility >= 50000) {
        this.rank = "Legend"
        return "/assets/badges/no-text/badge-07.svg"
      }
    }
  }
}

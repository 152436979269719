import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss']
})
export class CookiePolicyComponent implements OnInit {

  constructor(private seo: SeoService, public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Cookie Policy',
      description: `Poll Sports Cookie Policy.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `cookie-policy`
    });
  }

}

import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { NavbarService } from '../services/navbar.service';
import { first } from 'rxjs/operators';
import sportTypes from '../../assets/types/sport-types.json'


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  items: MenuItem[];
  activeItem: MenuItem;
  displayAccountModal:boolean = false;
  displayPointsModal:boolean = false;
  displayCreateModal:boolean = false;
  points:number;
  pointsRequired:number = environment.points.required;
  pointsEarned:number = environment.points.earned;
  sportTypes:any = sportTypes.types;
  currentUser:any;

  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  constructor(
    private router: Router,
    private location: Location,
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    public nav: NavbarService
  ) { }

  ngOnInit() {
    this.initMobileMenu();
    this.afAuth.authState.subscribe((user:any) => {
      this.currentUser = user;
    });
  }

  toggleOption(e, sport) {
    this.router.navigateByUrl('/feed/'+sport);
    if (e.target) {
      const titles = document.getElementsByClassName('title');
      [].forEach.call(titles, (el) => {
        el.parentNode.classList.remove('active');
      });
      e.target.parentNode.parentNode.classList.add('active');
    }
  }

  expandOption(e) {
    if (e.target) {
       e.target.parentNode.classList.add('active');
    }
  }

  showCreateModal() {
    this.displayCreateModal = true;
    if (!this.currentUser || this.currentUser.isAnonymous) {
      this.displayAccountModal = true;
      this.displayCreateModal = false;
    } else {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.points = user.payload.get('points')
        // Must have required points to post a question
        if (this.points < this.pointsRequired) {
          this.displayPointsModal = true;
          this.displayCreateModal = false;
        } else {
          this.pollForm.showCreate();
        }
      });
    }
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  goToFeed() {
    const urlPath = this.location.path();
    const splitPath = urlPath.split("/");
    const pathLength = splitPath.length;
    let sport = pathLength == 4 ? splitPath[pathLength-2] : splitPath[pathLength-1];
    if (sport !== 'football' && sport !== 'baseball') {
      sport = 'football'
    }
    this.router.navigate([`/feed/${sport}`]);
  }

  initMobileMenu() {
    const menuItems = this.sportTypes.map(type => {
      return {
        label: type.name,
        items: [{
          label: 'Feed',
          command: (event) => {
            this.router.navigateByUrl('/feed/'+type.name);
          }
        }, {
          label: 'My Polls',
          command: (event) => {
            this.router.navigateByUrl('/polls/'+type.name);
          }
        }, {
          label: 'Search Polls',
          command: (event) => {
            this.router.navigateByUrl('/search/'+type.name);
          }
        }]
      }
    })
    this.items = menuItems;
  }
}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-votes-container',
  templateUrl: './votes-container.component.html',
  styleUrls: ['./votes-container.component.scss']
})
export class VotesContainerComponent implements OnInit {
  @Input()
  pollVotes:any;

  @Input()
  recipientIds:any;

  constructor() { }

  ngOnInit() {
  }

}

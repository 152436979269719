import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from '../services/navbar.service';
import { AppConfig } from '../../app-config';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { environment } from '../../environments/environment';
import { SeoService } from '../services/seo.service';
import { first } from "rxjs/operators";
import { DOCUMENT } from '@angular/common';

import * as firebase from 'firebase';

@Component({
  selector: 'app-group-show',
  templateUrl: './group-show.component.html',
  styleUrls: ['./group-show.component.scss']
})
export class GroupShowComponent implements OnInit {
  groupId:string;
  group:any;
  currentYear:any = environment.currentYear;
  origin:string = this.document.location.origin;
  showJoinBtn:boolean = false;
  currentUser:any;
  contest:any;
  displayAccountModal:boolean = false;
  polls:any;
  previousContest:any;
  membersExpanded:boolean = true;
  previousWeek:any;
  nextWeek:any;
  currentWeek:any;
  weeks:any;
  selectedWeek:any;
  leaderboardContest:any;
  showMsgBanner:boolean = false;
  p: number = 1;

  constructor(
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    public nav: NavbarService,
    private _config: AppConfig,
    private firebaseService: FirebaseService,
    private trackingService: TrackingService,
    private seo: SeoService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.weeks = [{name: `Week ${this._config.WEEK}`, code: this._config.WEEK}];
    window.scrollTo(0, 0);
    this.nav.show();
    this.route.params.subscribe(params => {
      this.groupId = params.id.replace(/ +/g, "");
      this.firebaseService.getGroup(this.groupId).pipe(first()).subscribe((group:any) => {
        this.group = Object.assign({}, group.payload.data(), { id: group.payload.id });
        this.generateSeoTags();
        if (this.group.contests && this.group.contests.hasOwnProperty(this.currentYear)) {
          let weeks = Object.keys(this.group.contests[this.currentYear])
          if (weeks && weeks[weeks.length-1] !== `week${this._config.WEEK}`) {
            weeks.push(`week${this._config.WEEK}`)
          }
          this.weeks = weeks.map((week) => {
            const weekInteger = parseInt(week.split("week")[1]);
            return {name: `Week ${weekInteger}`, code: weekInteger};
          });
        }
        this.selectedWeek = this.weeks[this.weeks.length-1]
        this.firebaseService.getOrCreateContest({
          groupId: this.groupId,
          groupName: this.group.name
        }).then((contest) => {
          // ToDo only set interval and display countdown if time now is less than startDate.
          this.contest = contest;
          this.getCurrentUser();
          this.populateContestPolls();
          this.getPreviousWeeksContest();
          this.listenForNewGroupMembers();

          let countdown = setInterval(() => {
            const date_future:any = new Date(this.contest.startDate);
            // const date_future:any = new Date()-100;
            const date_now:any = new Date();
            const distance:any = date_future - date_now;

            var delta:number = Math.abs(date_future - date_now) / 1000;
            // calculate (and subtract) whole days
            var days:number = Math.floor(delta / 86400);
            delta -= days * 86400;
            // calculate (and subtract) whole hours
            var hours:number = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;
            // calculate (and subtract) whole minutes
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            // what's left is seconds
            var seconds:number = Math.floor(delta % 60);

            if (document.getElementById("countdown")) {
              document.getElementById("days").innerHTML = days + " Days";
              document.getElementById("hours").innerHTML = hours + "H";
              document.getElementById("minutes").innerHTML = minutes + "M";
              document.getElementById("seconds").innerHTML = seconds + "S";
              document.getElementById("polls-container").style.display = "none";
              document.getElementById("default-msg").style.display = "block";

            }

            // Contest has begun
            if (distance < 0) {
              clearInterval(countdown);
              document.getElementById("countdown").style.display = "none";
              document.getElementById("polls-container").style.display = "block";
              document.getElementById("default-msg").style.display = "none";
            }
          }, 1000);

        });
      });
    });
  }

  copy() {
    var input:any = document.getElementById("shareUrlInput");
    var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    if (isiOSDevice) {

      var editable = input.contentEditable;
      var readOnly = input.readOnly;

      input.contentEditable = true;
      input.readOnly = false;

      var range = document.createRange();
      range.selectNodeContents(input);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      input.setSelectionRange(0, 999999);
      input.contentEditable = editable;
      input.readOnly = readOnly;

    } else {
       input.select();
    }

    this.trackingService.track('Copied contest share link', {
      uid: this.currentUser.uid,
      username: this.currentUser.username,
      groupId: this.groupId,
      groupName: this.group.name
    });

    document.execCommand('copy');
    alert("URL has been copied to clipboard.");
  }

  isJoinBtnVisible() {
    if (!this.group.memberIds.includes(this.currentUser.uid)) {
      this.showJoinBtn = true;
    } else {
      this.showJoinBtn = false;
    }
  }

  getCurrentUser() {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        const uid = user.uid;
        const isAnonymous = user.isAnonymous;
        if (!user.isAnonymous) {
          this.firebaseService.getUser(uid).pipe(first()).subscribe((user:any) => {
            this.currentUser = Object.assign({}, user.payload.data(), { uid: uid, isAnonymous:  isAnonymous});
            this.isJoinBtnVisible();
          });
        } else {
          this.showJoinBtn = true;
        }
      } else {
        this.showJoinBtn = true;
      }
    });
  }

  join() {
    const user = this.currentUser;
    if (this.currentUser.isAnonymous || !this.currentUser) {
      this.displayAccountModal = true;
    } else {
      this.firebaseService.joinGroup(this.groupId, {
        uid: this.currentUser.uid,
        username: this.currentUser.username,
        email: this.currentUser.email
      }).then(() => {
        console.log("Successfully joined community!");
        this.showJoinBtn = false;
      });
    }
  }

  populateContestPolls(week=null) {
    if (this.contest.custom) {
      // ToDo: Fetch custom pollIds from contest doc
    } else {
      this.firebaseService.getOrCreateGlobalContest(week).then((globalContest:any) => {
        const promises = []
        const db = firebase.firestore();
        globalContest.pollIds.forEach(pollId => promises.push(db.collection('polls').doc(pollId).get()));
        Promise.all(promises).then((polls:any) => {
          this.polls = polls.reverse();
          this.setVotingListener();
        });
      });
    }
  }

  getPreviousWeeksContest() {
    this.firebaseService.getPreviousWeeksContest({groupId: this.groupId}).then((previousWeeksContest:any) => {
      this.previousContest = previousWeeksContest;
      this.leaderboardContest = previousWeeksContest;
      this.previousWeek = this._config.WEEK == 1 ? 1 : this._config.WEEK - 1;
      this.nextWeek = this._config.WEEK == 1 ? 1 : this._config.WEEK + 1;
      this.currentWeek = this._config.WEEK;
    });
  }

  hideAccountModal() {
    this.getCurrentUser();
    this.displayAccountModal = false;
  }

  displayPercentage(user) {
    if (user) {
      return (user.accuracy * 100).toFixed(2)
    }
  }

  toggleMembers() {
    this.membersExpanded = !this.membersExpanded
  }

  changeWeek() {
    // ToDo: Remove year when updated in environment.
    const contestProperties = {
      groupId: this.groupId,
      year: this.currentYear,
      week: parseInt(this.selectedWeek.code)
    }
    this.firebaseService.getContest(contestProperties).pipe(first()).subscribe((contest:any) => {
      const promises = []
      const db = firebase.firestore();
      this.leaderboardContest = contest.payload.data();
      if (this.leaderboardContest) {
        // ToDo: Make this smarter so it checks if the global contest is set to custom or not.
        if (this.leaderboardContest.pollIds.length > 0) {
          this.leaderboardContest.pollIds.forEach(pollId => promises.push(db.collection('polls').doc(pollId).get()));
          Promise.all(promises).then((polls:any) => {
            this.polls = polls;
            this.setVotingListener();
          });
        } else {
          this.populateContestPolls(parseInt(this.selectedWeek.code));
        }
        this.firebaseService.getPreviousWeeksContest({groupId: this.groupId, week: this.selectedWeek.code}).then((previousWeeksContest:any) => {
          this.leaderboardContest = previousWeeksContest;
          this.previousWeek = this.selectedWeek.code;
        });
      } else {
        this.polls = [];
      }
    });
  }

  listenForNewGroupMembers() {
    this.firebaseService.getGroup(this.groupId).subscribe((group:any) => {
      this.group = Object.assign({}, group.payload.data(), { id: group.payload.id });
    });
  }

  setVotingListener() {
    const hasJoined = this.currentUser && this.group.memberIds.includes(this.currentUser.uid);
    if (hasJoined) {
      return;
    } else {
      setTimeout(() => {
        document.querySelectorAll('.checkbox-container').forEach(item => {
          item.addEventListener('click', event => {
            if (this.currentUser.isAnonymous || !this.currentUser) {
              // Display banner letting user they need to join
              this.showMsgBanner = true;
            } else {
              this.join();
            }
          });
        });
      }, 3000);
    }
  }

  closeMsgBanner() {
    this.showMsgBanner = false;
  }

  generateSeoTags() {
    this.seo.generateTags({
      title: `Week ${this._config.WEEK} ${this.group.name} Accuracy Contest`,
      description: `Compete to be the most accurate by voting on start/sit polls submitted by real fantasy football players.`,
      image: 'https://firebasestorage.googleapis.com/v0/b/poll-sports.appspot.com/o/SNAPSHOT.jpg?alt=media&token=c478ef16-8fd8-4576-bcd8-066de1206d10',
      slug: `communities/${this.group.id}`
    });
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRouteSnapshot, RouteReuseStrategy, Router, ActivatedRoute, ParamMap, NavigationEnd } from "@angular/router";
import { FirebaseService } from "../services/firebase.service";
import { TrackingService } from '../services/tracking.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first, filter } from "rxjs/operators";
import { async } from "@angular/core/testing";
import { environment } from '../../environments/environment';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { ToastModule } from 'primeng/toast';
import { MessagingService } from '../services/messaging.service';
import { NavbarService } from '../services/navbar.service';
import { MessageService } from 'primeng/api';
import { SeoService } from '../services/seo.service';
import { ConfirmationService } from 'primeng/api';

import pollTypes from '../../assets/types/poll-types.json';

@Component({
  selector: "app-poll-show",
  templateUrl: "./poll-show.component.html",
  styleUrls: ["./poll-show.component.scss"]
})
export class PollShowComponent implements OnInit {
  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  sportPollTypes:any = pollTypes;
  pollKey: any;
  similarPolls:any[] = [];
  poll:any;
  points:number;
  pollVotes: any;
  showComments:boolean = true;
  showVotes:boolean = false;
  showPolls:boolean = false;
  displayAccountModal:boolean = false;
  displayCreateModal:boolean = false;
  pointsRequired:number = environment.points.required;
  pointsEarned:number = environment.points.earned;
  displayPointsModal:boolean = false;
  loading:boolean = true;
  msgs:any[] = [];
  tab:string;
  currentUser:any;
  currentUsername:string;
  currentEmail:string;
  credibility:number;
  accuracyTotal:any;
  sport:string;
  notificationsDismissed:boolean;
  isAdmin:boolean = false;
  isPro:boolean = false;
  title:string;
  players:any;
  tags:any;
  splitPlayers:any;
  pollData:any;

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private router: Router,
    public msg: MessagingService,
    private messageService: MessageService,
    private seo: SeoService,
    public nav: NavbarService,
    private confirmationService: ConfirmationService,
    private trackingService: TrackingService
  ) {
    this.route.params.subscribe(params => (this.pollKey = params.id));
    this.route.queryParams.pipe(filter(params => params.tab)).subscribe(params => {
      this.tab = params.tab
    });
    this.pollVotes = [];
  }

  ngOnInit() {
    this.nav.show();
    this.sport = this.router.url.split("/")[2];
    this.getPollData();
    // ToDo: Trigger poll detail update after logging in.
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user
      if (user) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
          this.currentEmail = user.payload.get('email') ? user.payload.get('email') : '';
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
          this.notificationsDismissed = user.payload.get('notificationsDismissed');
          this.isAdmin = user.payload.get('roles') ? user.payload.get('roles').admin : false;
          this.isPro = user.payload.get('isPro') ? user.payload.get('isPro') : false;
        });
      }
    });
    // Refreshes page if another poll details is clicked
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    };

    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });

    if (this.tab == 'comments') {
      this.toggleComments();
    }
    else if (this.tab == "votes") {
      this.toggleVotes();
    }
    else if (this.tab == "similar") {
      this.togglePolls();
    }
  }

  async getPollData() {
    this.poll = await this.firebaseService
      .getPoll(this.pollKey)
      .pipe(first())
      .toPromise();

    this.pollData = this.poll.payload.data();

    if (!this.pollData) {
      return
    }

    const customPollTitles = ['SIUW', 'SIDPFP', 'PRICECHECK', 'FAAB']
    if (customPollTitles.includes(this.pollData.pollType || this.pollData.choicesAllowed && this.pollData.choicesAllowed > 1)) {
      this.title = this.pollData.title;
    } else {
      this.title = this.sportPollTypes["football"].find(type => type.code == this.pollData.pollType).name;
    }

    let finalString = "";
    this.pollData.choices.forEach((choice, index) => {
      const stringAddition = index === this.pollData.choices.length - 1 ? "" : " or ";
      const mappedPlayers = choice.players.map(player => player.displayName)
      const addAnd = mappedPlayers.length > 1;
      finalString += mappedPlayers.slice(0, -1).join(', ')+ (addAnd ? ' and ' : '') +mappedPlayers.slice(-1) + stringAddition;
    });

    this.players = finalString;
    this.splitPlayers = this.players.split(" or");
    this.tags = this.pollData.tags.map((tag) => tag.name).join(", ");
    const year = this.pollData.createdAt.toDate().getFullYear();
    const week = this.pollData.week === 0 || !this.pollData.week ? 1 : this.pollData.week;

    let seoTitle;
    if (this.pollData.pollType === "FAAB" || this.pollData.pollType === "PRICECHECK") {
      seoTitle = `${this.pollData.title} - Week ${week} | PollSports`;
    } else {
      seoTitle = `${this.players} | ${this.title} - Week ${week} | PollSports`;
    }

    this.seo.generateTags({
      title: seoTitle,
      description: `${this.players} week ${week} ${year}. Get instant fantasy football advice on trades and who to start. Advice from our community is scored for accuracy each week.`,
      image: 'https://firebasestorage.googleapis.com/v0/b/poll-sports.appspot.com/o/SNAPSHOT.jpg?alt=media&token=c478ef16-8fd8-4576-bcd8-066de1206d10',
      slug: `poll/football/${this.pollKey}`
    });

    this.loading = false;
  }

  showMsg() {
    this.msgs = [];
    this.msgs.push({severity:'success', summary:'', detail:''});
  }

  toggleComments() {
    this.trackingService.track('View Comments (Poll Show)', {uid: this.currentUser ? this.currentUser.uid : ''});
    this.showComments = true;
    this.showVotes = false;
    this.showPolls = false;
  }

  toggleVotes() {
    this.trackingService.track('View Votes (Poll Show)', {uid: this.currentUser ? this.currentUser.uid : ''});
    this.showVotes = true;
    this.showComments = false;
    this.showPolls = false;

    if (this.pollVotes.length <= 0) {
      this.getVotes()
    }
  }

  getVotes() {
    try {
      this.firebaseService
        .getVotes({poll: this.pollKey})
        .subscribe((fetchedPollVotes: any) => {
          this.pollVotes = [];
          fetchedPollVotes.map(async (vote: any) => {
            let voteDoc = vote.payload.doc.data();
            this.pollVotes.push({
              username: voteDoc.user,
              choiceKey: voteDoc.choice,
              choice: voteDoc.players,
              createdAt: voteDoc.createdAt,
              credibility: voteDoc.credibility,
              uid: voteDoc.uid,
              percentile: voteDoc.percentile,
              isInfluencer: voteDoc.isInfluencer
            });
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  getSimilarPolls() {
    let uniquePolls = {};
    if (this.poll) {
      const pollData = this.poll.payload.data();
      pollData.players.forEach(player => {
        this.firebaseService.getSimilarPolls(pollData.pollType, player).pipe(first()).subscribe(polls => {
          polls.forEach(poll => {
            let pollId = poll.payload.doc.id;
            if (!(pollId in uniquePolls) && pollId !== this.pollKey && this.similarPolls.length < 10) {
              uniquePolls[poll.payload.doc.id] = poll;
              this.similarPolls.push(poll);
            }
          });
        });
      });
    }
  }

  togglePolls() {
    this.trackingService.track('View Similar Polls', {uid: this.currentUser ? this.currentUser.uid : ''});
    this.showPolls = true;
    this.showVotes = false;
    this.showComments = false;

    if (this.similarPolls.length <= 0) {
      this.getSimilarPolls();
    }
  }

  promptLogin() {
    this.displayAccountModal = true;
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  showCreateModal() {
    this.displayCreateModal = true;
    if (!this.currentUser) {
      this.displayAccountModal = true;
    } else {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.points = user.payload.get('points')
        // Must have required points to post a question
        if (this.points < this.pointsRequired) {
          this.displayPointsModal = true;
        } else {
          this.pollForm.showCreate();
        }
      });
    }
  }

  showNotificationPrompt() {
    this.clear();
    this.messageService.add({
      key: 'enableNotifications',
      sticky: true,
      severity:'success',
      summary: 'Enable Notifications',
      detail: 'Get notified if someone replies to your comment. This only enables browser push notifications. We will never spam your email.'
    });
  }

  onConfirm() {
    this.messageService.clear('enableNotifications');
    const user = this.currentUser;
    this.msg.getPermission(user)
    this.msg.monitorRefresh(user)
    this.msg.receiveMessages()
  }

  onReject() {
    this.clear()
    this.firebaseService.updateUser(this.currentUser.uid, {notificationsDismissed: true})
  }

  clear() {
    this.messageService.clear('enableNotifications');
  }

  deletePoll(poll) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this poll?',
        accept: () => {
          this.firebaseService.deletePoll(poll);
          this.router.navigate([`feed/football`]);
        }
    });
  }

  makePickem() {
    document.getElementById('pickem-add').style.display = "none";
    document.getElementById('pickem-remove').style.display = "inline-block";
    this.firebaseService.addGlobalContestPoll(this.pollKey).then(() => {
      console.log("Poll added to contest", this.pollKey);
    });
  }

  removePickem() {
    document.getElementById('pickem-remove').style.display = "none";
    document.getElementById('pickem-add').style.display = "inline-block";
    this.firebaseService.removeGlobalContestPoll(this.pollKey).then(() => {
      console.log("Poll removed from contest", this.pollKey);
    });
  }

  displayPlayers() {
    return this.players.split("or");
  }

}

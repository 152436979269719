import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private messagingIsSupported = firebase.messaging.isSupported()
  private messageSource = new Subject()
  currentMessage = this.messageSource.asObservable() // message observable to show in Angular component

  constructor(private afs: AngularFirestore) {}

  // get permission to send messages
  getPermission(user) {
    if (this.messagingIsSupported) {
      firebase.messaging().requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        return firebase.messaging().getToken()
      })
      .then(token => {
        console.log(token)
        this.saveToken(user, token)
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
    }
  }

  // Listen for token refresh
  monitorRefresh(user) {
    if (this.messagingIsSupported) {
      firebase.messaging().onTokenRefresh(() => {
        firebase.messaging().getToken()
        .then(refreshedToken => {
          console.log('Token refreshed.');
          this.saveToken(user, refreshedToken)
        })
        .catch( err => console.log(err, 'Unable to retrieve new token') )
      });
    }
  }

  // save the permission token in firestore
  private saveToken(user, token): void {

      const currentTokens = user.fcmTokens || { }

      // If token does not exist in firestore, update db
      if (!currentTokens[token]) {
        const userRef = this.afs.collection('users').doc(user.uid)
        const tokens = { ...currentTokens, [token]: true }
        userRef.update({ fcmTokens: tokens })
      }
  }

  // used to show message when app is open
  receiveMessages() {
    if (this.messagingIsSupported) {
      firebase.messaging().onMessage(payload => {
        console.log('Message received. ', payload);
        this.messageSource.next(payload)
      });
    }

  }

}

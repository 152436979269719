import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavbarService } from '../services/navbar.service';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app-config';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-leagues',
  templateUrl: './leagues.component.html',
  styleUrls: ['./leagues.component.scss']
})
export class LeaguesComponent implements OnInit {
  polls:any = [];
  loading:boolean = true;
  preFilterPolls:any;
  p: number = 1;
  currentUser:any;
  currentUsername:string;
  currentEmail:string;
  credibility:number;
  accuracyTotal:any;
  currentWeek:any;
  accuracyWeeks:any;
  isPro:boolean = false;
  items:any;
  activeItem:any;
  pollOptions:any;
  selectedPollOption:any;
  correctVoteIds:any;
  incorrectVoteIds:any;
  totalVoteIds:any;
  showResults:boolean = false;
  users:any;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    private confirmationService: ConfirmationService,
    public nav: NavbarService,
    private http: HttpClient,
    private router: Router,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.nav.show();
    this.items = [
        {label: 'Vote', icon: 'fa fa-fw fa-bar-chart'},
        {label: 'Results', icon: 'fa fa-fw fa-calendar'},
    ];
    this.activeItem = this.items[0];

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        if (user.isAnonymous) {
          this.router.navigate(['/feed/football'])
        }
        this.currentUser = user;
        const userId = user.uid;
        this.firebaseService.getUser(userId).pipe(first()).subscribe((user:any) => {
          this.isPro = user.payload.get('isPro') ? user.payload.get('isPro') : false;
          if (!this.isPro) {
            this.router.navigate(['/feed/football'])
          }
          this.currentUsername = user.payload.get('username');
          this.currentEmail = user.payload.get('email');
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
          this.accuracyWeeks = user.payload.get('accuracy') ? user.payload.get('accuracy') : {};

          this.currentWeek = this._config.WEEK;
          this.getLeaguePolls();

        });

      } else {
        this.router.navigate(['/feed/football'])
        this.polls = [];
      }
      this.loading = false;
    });
  }

  updatePolls(event) {
    this.polls = event;
  }

  activateMenu(tab){
   if (tab.activeItem.label == "Results") {
     this.showResults = true;
     let queryWeek = `week${this.currentWeek-1}`;

     // ToDo: Use this to display results for each week
     // let weekList = Array.from({length: this.currentWeek}, (x,i) => i);
     // weekList.shift();
     // this.weeks = weekList.map(week => {
     //   return { label: `Week ${week}`, value: week }
     // });
     // this.selectedWeek = this.weeks[this.weeks.length-1].value;
     // this.selectedWeekNumber = (this.selectedWeek).toString();
     // ToDo: Does this need to be a string for anything?
     // this.currentWeek = this.currentWeek.toString()

     this.firebaseService.getPolls({
       league: true,
       week: this.currentWeek-1,
       type: "WDIS",
       limit: 25
     }).then((response: any) => {
       this.totalVoteIds = response.polls.map(poll => poll.id);


       this.firebaseService.getUsers({pro: true}).pipe(first()).subscribe((users:any) => {
         this.users = users.map((user) => {
           let userData = user.payload.doc.data();
           let lastWeek = (this.currentWeek-1).toString();
           let correctVoteIds = userData.accuracy[`week${lastWeek}`] ? userData.accuracy[`week${lastWeek}`].correctVoteIds : [];
           correctVoteIds = this.totalVoteIds ? this.totalVoteIds.filter(x => correctVoteIds.includes(x)) : [];
           return {
             username: userData.username,
             correct: correctVoteIds.length,
             accuracy: (correctVoteIds.length/this.totalVoteIds.length*100).toFixed(2)
           }
         });
         this.users = this.users.sort( this.compare.bind(this) );
       });


       // Only grab vote ids from that week
       this.correctVoteIds = this.accuracyWeeks[queryWeek] ? this.accuracyWeeks[queryWeek].correctVoteIds : [];
       this.correctVoteIds = this.totalVoteIds ? this.totalVoteIds.filter(x => this.correctVoteIds.includes(x)) : [];
       this.incorrectVoteIds = this.totalVoteIds ? this.totalVoteIds.filter(x => !this.correctVoteIds.includes(x)) : [];

       this.pollOptions = [
         {label: 'Correct ('+ this.correctVoteIds.length+')', value:0},
         {label: 'Incorrect ('+ this.incorrectVoteIds.length+')', value:1}
       ];
       this.selectedPollOption = this.pollOptions[0].value;
       this.changePollOption({value: 0});

     });

   } else {
     this.showResults = false;
     this.getLeaguePolls();
   }
  }

  getLeaguePolls() {
    this.firebaseService.getPolls({
      league: true,
      week: this.currentWeek,
      type: "WDIS",
      limit: 25
    }).then((response: any) => {
      this.polls = response.polls;
      this.preFilterPolls = response.polls;
    });
  }

  changePollOption(type) {
    let fetchIds;
    this.polls = []
    if (type.value === 0) {
      fetchIds = this.correctVoteIds
    } else if (type.value === 1) {
      fetchIds = this.incorrectVoteIds
    }
    this.firebaseService.getPollsById(fetchIds).then((polls:any) => {
      this.polls = polls;
    })
  }

  compare( a, b ) {
    if ( (a.accuracy) > (b.accuracy) ){
      return -1;
    }
    if ( (a.accuracy) < (b.accuracy) ){
      return 1;
    }
    return 0;
  }

}

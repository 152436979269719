import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { NavbarService } from '../services/navbar.service';
import { first } from 'rxjs/operators';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClient } from '@angular/common/http';
import { SeoService } from '../services/seo.service';
import { AppConfig } from '../../app-config';
import { environment } from '../../environments/environment';
import sportTypes from '../../assets/types/sport-types.json';
import * as firebase from 'firebase';

@Component({
  selector: 'app-vote-history-personal',
  templateUrl: './vote-history-personal.component.html',
  styleUrls: ['./vote-history-personal.component.scss']
})
export class VoteHistoryPersonalComponent implements OnInit {
  pollIds:any = [];
  polls:any = [];
  preFilterPolls:any;
  loading:boolean = true;
  displayAccountModal:boolean = false;
  sportTypes:any = sportTypes.types;
  p: number = 1;
  currentUser:any
  currentUsername:any;
  credibility:number;
  pollCount:number = 0;

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private seo: SeoService,
    public nav: NavbarService,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Vote history',
      description: `History of all the fantasy football polls you have previously voted on.`,
      image: 'https://pollsports.com/assets/logo-blue.svg',
      slug: `history`
    });
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.pollCount = user.payload.get('polls') ? user.payload.get('polls') : 0;
        });
        let currentWeek:any = this._config.WEEK;
        this.firebaseService.getVoteHistory(parseInt(currentWeek)).then((results:any) => {
          this.polls = results.polls;
          this.preFilterPolls = this.polls;
          this.loading = false;
        });
      } else {
        this.polls = [];
        this.pollIds = [];
        this.loading = false;
      }

    });
  }

  showLogin() {
    this.displayAccountModal = true;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  updatePolls(event) {
    this.polls = event;
  }

  deletePoll(poll) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this poll?',
        accept: () => {
          this.firebaseService.deletePoll(poll);
        }
    });
  }

}

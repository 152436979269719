import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfig } from '../../app-config';
import { ActivatedRoute } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { Location } from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';
import { MessagingService } from '../services/messaging.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { TooltipModule } from 'primeng/tooltip';

import * as firebase from 'firebase';

import pollTypes from '../../assets/types/poll-types.json';
import sportTypes from '../../assets/types/sport-types.json';
import tags from '../../assets/types/tags.json';


@Component({
  selector: 'app-poll-form',
  templateUrl: './poll-form.component.html',
  styleUrls: ['./poll-form.component.scss']
})
export class PollFormComponent implements OnInit {
  form:any = {label: "Create", title: "Create Poll"}
  visible:boolean = false;
  selectedTags:any[];
  showCustomTags:boolean = true;
  showLeagueSelect:boolean = false;
  choices:any;
  selectedPollType:any;
  selectedPollKey:any;
  selectedLeague:any;
  filteredPlayersMultiple: any[];
  pollTypes:any;
  waiverOptions:any;
  leagues:any;
  sportPollTypes:any = pollTypes;
  sportTypes:any = sportTypes.types;
  tags:any = tags;
  scoringTypes:any;
  activePlayers:any;
  sportId:number = 1;
  uid:string;
  submitted:boolean = false;
  showDetails:boolean = false;
  allowAnonymousVotes:boolean = true;
  helperText:string;
  optionMaxError:boolean = false;
  notificationsSupported:boolean = false;
  allowNotifications:boolean = false;
  notificationsDismissed:boolean;
  username:string;
  currentEmail:string;
  msgs:any[] = [];
  hidden:boolean = false;
  recipient:any;
  choicesAllowed:any[] = [];
  choicesAllowedSelection:any = {name: "Pick 1", code: "1"};
  duplicatePoll:any;
  duplicatePollId:any;
  displayDuplicatePoll:boolean = false;
  currentUser:any;
  credibility:any;
  accuracyTotal:any;
  duplicatePollDetails:any;

  @Output()
  closeEvent = new EventEmitter<string>();

  @Output()
  validateEvent = new EventEmitter<any>();

  @Input()
  checkoutPoll:boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    private trackingService: TrackingService,
    public route: ActivatedRoute,
    private location: Location,
    private afAuth: AngularFireAuth,
    private _config: AppConfig,
    public msg: MessagingService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() {
    this.waiverOptions = [
      {"name": "#1 Waiver", "code": "W1"},
      {"name": "#2 Waiver", "code": "W2"},
      {"name": "#3 Waiver", "code": "W3"},
      {"name": "#4 Waiver", "code": "W4"},
      {"name": "#5 Waiver", "code": "W5"},
      {"name": "#6 Waiver", "code": "W6"},
      {"name": "#7 Waiver", "code": "W7"},
      {"name": "#8 Waiver", "code": "W8"},
      {"name": "#9 Waiver", "code": "W9"},
      {"name": "#10 Waiver", "code": "W10"},
      {"name": "#11 Waiver", "code": "W11"},
      {"name": "#12 Waiver", "code": "W12"},
      {"name": "#13 Waiver", "code": "W13"},
      {"name": "#14 Waiver", "code": "W14"}
    ]
    if (firebase.messaging.isSupported()) {
      this.notificationsSupported = true;
      firebase.messaging().getToken().then(token => {
        this.allowNotifications = token ? true : false;
      });
    }
    this.afAuth.authState.subscribe(user => {
      this.currentUser = user;

      if (this.currentUser) {
        this.uid = this.currentUser.uid;
        this.firebaseService.getUser(this.uid).pipe(first()).subscribe((user:any) => {
          this.notificationsDismissed = user.payload.get('notificationsDismissed');
          this.username = user.payload.get('username');
          this.currentEmail = user.payload.get('email');
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
        });
        this.firebaseService.getLeagues(this.uid).pipe(first()).subscribe((leagues:any) => {
          this.leagues = leagues.map(league => league.payload.doc.data() );
          if (this.leagues.length > 0) {
            this.showCustomTags = false;
            this.showLeagueSelect = true;
            this.selectedLeague = this.leagues[0];
          }
        });
      }
      const urlPath = this.location.path();
      const splitPath = urlPath.split("/");
      const pathLength = splitPath.length;
      // ToDo: Need to differentiate between sports when creating poll from My Polls or Vote History view
      let sport = pathLength == 4 ? splitPath[pathLength-2] : splitPath[pathLength-1];
      if (sport === "football" || sport === "baseball") {
        this.sportId = this.sportTypes
        .find(type => type.name == sport).code;
        this.pollTypes = this.sportPollTypes[sport];
      } else {
        sport = "football";
        this.sportId = 1;
        this.pollTypes = this.sportPollTypes["football"];
      }
      this.scoringTypes = this.tags[sport]
      this.activePlayers = this._config.PLAYERS[sport];
      this.selectedPollType = this.pollTypes[0];

      this.choices = [[],[]];
      this.selectedTags = [];
    });
  }

  createPoll(recipient="") {
    if (recipient) {
      this.recipient = recipient;
    }
    let recipientIds;
    let title = "";
    this.submitted = true;
    this.clearEmptyChoices();
    let tags;
    if (!this.showDetails) {
      this.helperText = null;
    }
    if (this.selectedLeague && this.showLeagueSelect) {
      tags = this.selectedLeague.tags
    } else {
      tags = this.selectedTags
    }
    if (this.recipient) {
      recipientIds = this.recipient.team && this.recipient.team.length > 0 ? [...this.recipient.team, this.recipient.id] : [this.recipient.id]
    }
    const choicesAllowed = parseInt(this.choicesAllowedSelection.code);
    let pollType = this.selectedPollType.code;
    // Override poll type for WDIS Multiple
    if (this.choicesAllowedSelection.code !== "1") {
      title = `Who Do I Start? (Pick ${choicesAllowed})`;
    }
    if (pollType === 'SIUW') {
      this.choices[0] = [this.choices[0]]
      title = `Should I Use ${this.choices[0][0].name} on ${this.choices[1][0].fname} ${this.choices[1][0].lname}?`;
    }
    if (pollType === 'SIDPFP') {
      title = `Should I Drop ${this.choices[0][0].fname} ${this.choices[0][0].lname} for ${this.choices[1][0].fname} ${this.choices[1][0].lname}?`;
    }
    if (pollType === "PRICECHECK") {
      // attach draft pick poll choices here
      title = `Price Check on ${this.choices[0][0].displayName}?`;
      this.choices[1] = [{ playerId: "20002021", active: "1", fname: "2020 Early & more", lname: "Early 1st & more", displayName: "Early 1st & more" }];
      this.choices[2] = [{ playerId: "2002021", active: "1", fname: "2020 Early 1", lname: "Early 1. Draft Pick", displayName: "Early 1st" }];
      this.choices[3] = [{ playerId: "2002022", active: "1", fname: "2020 Mid 1", lname: "Mid 1. Draft Pick", displayName: "Mid 1st" }];
      this.choices[4] = [{ playerId: "2002023", active: "1", fname: "2020 Late 1", lname: "Late 1. Draft Pick", displayName: "Late 1st" }];
      this.choices[5] = [{ playerId: "2002101", active: "1", fname: "2020 Early 2", lname: "Early 2. Draft Pick", displayName: "Early 2nd" }];
      this.choices[6] = [{ playerId: "2002102", active: "1", fname: "2020 Mid 2", lname: "Mid 2. Draft Pick", displayName: "Mid 2nd" }];
      this.choices[7] = [{ playerId: "2002103", active: "1", fname: "2020 Late 2", lname: "Late 2. Draft Pick", displayName: "Late 2nd or less" }];
    }
    if (pollType === "FAAB") {
      title = `What % of FAAB Should I Spend on ${this.choices[0][0].displayName}?`;
      this.choices[1] = [{ playerId: "2002104", active: "1", fname: "0% - 5%", lname: "0% - 5%", displayName: "0 - 5%" }];
      this.choices[2] = [{ playerId: "2002105", active: "1", fname: "6% - 10%", lname: "6% - 10%", displayName: "6 - 10%" }];
      this.choices[3] = [{ playerId: "2002106", active: "1", fname: "11% - 20%", lname: "11% - 25%", displayName: "11 - 25%" }];
      this.choices[4] = [{ playerId: "2002107", active: "1", fname: "26% - 50%", lname: "26% - 50%", displayName: "26 - 50%" }];
      this.choices[5] = [{ playerId: "2002108", active: "1", fname: "51% - 75%", lname: "51% - 75%", displayName: "51 - 75%" }];
      this.choices[6] = [{ playerId: "2002109", active: "1", fname: "76% - 100%", lname: "76% - 100%", displayName: "76 - 100%" }];
    }
    const leagueTypes = ["DYNASTY", "KEEPER", "REDRAFT"];
    // Poll has not been tagged with league type
    if (!tags.some(tag => leagueTypes.includes(tag.code))) {
      // If choices includes a draft pick we assume it's a dynasty poll
      if (this.choices.filter(choice => choice.some(player => player.fname && parseInt(player.fname[0]) > 0)).length > 0) {
        tags.unshift({name: "Dynasty", code: "DYNASTY"})
      } else {
        tags.unshift({name: "Redraft", code: "REDRAFT"})
      }
    }

    // Used to create an indentifying string for duplicate poll querying.
    const sortNestedArray = function(arr) {
      return arr.sort((a,b) => a[0] - b[0]);
    }
    const sortArray = function(arr) {
      return arr.sort((a,b) => a - b);
    }

    const mappedChoices = this.choices.map(choice => choice.map(player => player.playerId));
    const sortedMappedChoices = mappedChoices.map((choice) => sortArray(choice));
    const sortedNestedMappedChoices = sortNestedArray(sortedMappedChoices);
    const choicesQuery = JSON.stringify(sortedNestedMappedChoices);
    // End.

    const poll = {
      title: title,
      pollType: pollType,
      tags: tags,
      choices: this.choices,
      sport: this.sportId,
      uid: this.uid,
      helperText: this.helperText,
      allowAnonymousVotes: this.allowAnonymousVotes,
      recipientIds: this.recipient ? recipientIds : [],
      choicesAllowed: choicesAllowed,
      choicesQuery: choicesQuery
    };

    // Use this to check for duplicate polls
    let players = this.choices.map(choice => choice[0].displayName)
    players = [].concat(...players);

    // skip duplicate poll check if fan page poll.
    if (this.checkoutPoll) {
      this.createPollDocument(poll);
    } else {
      this.firebaseService.getDuplicatePolls({pollType: pollType, players: players, title: title, tags: tags, choicesQuery: choicesQuery}).then(async (response:any) => {
        // Poll already exists
        if (response.polls.length > 0) {
          this.duplicatePollId = response.polls[0].id;

          this.duplicatePoll = await this.firebaseService
          .getPoll(this.duplicatePollId)
          .pipe(first())
          .toPromise();

          this.duplicatePollDetails = poll;
          this.displayDuplicatePoll = true;
        } else {
          this.createPollDocument(poll);
        }
      });
      //////////
    }


  }

  clearEmptyChoices() {
    this.choices.forEach((choice, index) => {
      if (this.choices[index].length == 0) {
        this.choices.splice(index, 1);
      }
    });
  }

  formSubmit() {
    this.createPoll()
  }

  addPollChoice() {
    if (this.choices.length < 5) {
      this.choices.push([]);
    }
    return false;
  }

  onRemoveItem(index) {
    if (this.choices.length > 2 && this.choices[index].length == 0) {
      this.choices.splice(index, 1);
    }
    this.updateChoicesAllowed();
  }

  removeChoice(index) {
    this.choices.splice(index, 1);
    this.updateChoicesAllowed();
  }

  filterPlayer(query, players: any[]):any[] {
    let filtered : any[] = [];
    for(let i = 0; i < players.length; i++) {
      let player = players[i];
      if(player.displayName.toLowerCase().indexOf(query.toLowerCase()) == 0 || player.lname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(player);
      }
    }
    return filtered;
  }

  filterPlayerMultiple(event) {
    let query = event.query;
    this.filteredPlayersMultiple = this.filterPlayer(query, this.activePlayers);
  }

  close() {
    this.hidden = true;
    this.visible = false;
    this.displayDuplicatePoll = false;
    this.closeEvent.emit();
  }

  itemSelected(index) {
    if (this.choices[index] && this.choices[index].length > 5 ||
        this.selectedPollType.code === 'PRICECHECK' && this.choices[index].length > 1 ||
        this.selectedPollType.code === 'FAAB' && this.choices[index].length > 1 ||
        this.selectedPollType.code === 'SIDPFP' && this.choices[index].length > 1 ||
        this.selectedPollType.code === 'SIUW' && this.choices[index].length > 1) {
      this.choices[index].pop();
      this.optionMaxError = true;
    }
    // Hack to enable Create btn on form.
    if (this.selectedPollType.code === "PRICECHECK" || this.selectedPollType.code === "FAAB") {
      this.choices[1] = [{}];
    }
    this.updateChoicesAllowed();
  }

  onKeyUp() {
    this.optionMaxError = false;
  }

  updateChoicesAllowed() {
    if (this.choices.length > 2) {
      this.choicesAllowed = [
        {label:'Pick 1', value:{name: 'Pick 1', code: '1'}},
        {label:'Pick 2', value:{name: 'Pick 2', code: '2'}}
      ];
    }
    if (this.choices.length > 3) {
      this.choicesAllowed = [
        {label:'Pick 1', value:{name: 'Pick 1', code: '1'}},
        {label:'Pick 2', value:{name: 'Pick 2', code: '2'}},
        {label:'Pick 3', value:{name: 'Pick 3', code: '3'}}
      ];
    }
    if (this.choices.length < 3) {
      this.choicesAllowed = [];
    }
  }

  showCreate(recipient="") {
    if (recipient) {
      this.recipient = recipient;
    } else {
      this.recipient = false;
    }
    if (firebase.messaging.isSupported()) {
      this.notificationsSupported = true;
      firebase.messaging().getToken().then(token => {
        this.allowNotifications = token ? true : false;
      });
    }
    this.firebaseService.getUser(this.uid).pipe(first()).subscribe((user:any) => {
      this.notificationsDismissed = user.payload.get('notificationsDismissed');
    });
    this.firebaseService.getLeagues(this.uid).pipe(first()).subscribe((leagues:any) => {
      this.leagues = leagues.map(league => league.payload.doc.data() );
      if (this.leagues.length > 0) {
        this.showCustomTags = false;
        this.showLeagueSelect = true;
        this.selectedLeague = this.leagues[0];
      }
    });
    const urlPath = this.location.path();
    const splitPath = urlPath.split("/");
    const pathLength = splitPath.length;
    let sport = pathLength == 4 ? splitPath[pathLength-2] : splitPath[pathLength-1];
    if (sport === "football" || sport === "baseball") {
      this.sportId = this.sportTypes
      .find(type => type.name == sport).code;
      this.pollTypes = this.sportPollTypes[sport];
    } else {
      sport = "football"
      this.sportId = 1;
      this.pollTypes = this.sportPollTypes["football"];
    }
    this.scoringTypes = this.tags[sport]
    this.activePlayers = this._config.PLAYERS[sport];
    this.selectedPollType = this.pollTypes[0];

    this.visible = true;
    this.choices = [[],[]];
    this.selectedTags = [];
  }

  showNotificationPrompt() {
    this.clear();
    this.messageService.add({
      key: 'createPollNotification',
      sticky: true,
      severity:'success',
      summary: 'Enable Notifications',
      detail: 'Get notified if someone comments on your poll. This only enables browser push notifications. We will never spam your email.'
    });
  }

  onConfirm() {
    this.messageService.clear('createPollNotification');
    const user = this.currentUser;
    this.msg.getPermission(user)
    this.msg.monitorRefresh(user)
    this.msg.receiveMessages()
    this.close();
  }

  onReject() {
    const user = this.currentUser;
    this.clear()
    this.close();
    this.firebaseService.updateUser(user.uid, {notificationsDismissed: true})
  }

  clear() {
    this.messageService.clear('createPollNotification');
  }

  toggleCustomTags() {
    this.showCustomTags = !this.showCustomTags;
    this.showLeagueSelect = !this.showLeagueSelect;
  }

  goToProfile() {
    this.router.navigate(['/user', this.username.toLowerCase()]);
    this.close();
  }

  pollTypeChanged() {
    if (this.selectedPollType.code === "SIUW") {
      this.choices[0] = {"name": "#1 Waiver", "code": "W1"};
    }
    this.choices = [[],[]];
  }

  viewDuplicatePoll() {
    this.router.navigate(['/poll/football', this.duplicatePollId]);
    this.close();
  }

  createPollDocument(poll) {
    this.firebaseService.createPoll(poll).then((createdPoll:any) => {
      if (!this.notificationsDismissed && this.notificationsSupported && !this.allowNotifications) {
        this.hidden = true;
        this.showNotificationPrompt();
      } else {
        this.close();
      }
      this.submitted = false;
      this.duplicatePollDetails = {};
      this.trackingService.track('Poll Created', {
        pollType: createdPoll.pollType,
        sentToAnalyst: createdPoll.recipientIds && createdPoll.recipientIds.length > 0,
        tags: createdPoll.tags.map(tag => tag.code),
        uid: createdPoll.uid
      });
      if (!window.location.pathname.includes('/send')) {
        this.router.navigate(['/polls']);
      }
    }).catch(err => {
      console.log("Error creating poll", err);
    });
  }

  formIsDisabled() {
    return this.choices && this.choices[0].length == 0 || this.choices && this.choices[1].length == 0 || this.submitted
  }

  checkIfValid() {
    const isDisabled = this.formIsDisabled();
    this.validateEvent.emit(isDisabled)
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NgxPaginationModule } from 'ngx-pagination';
import { SeoService } from '../services/seo.service';
import { NavbarService } from '../services/navbar.service';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

import sportTypes from '../../assets/types/sport-types.json';

@Component({
  selector: 'app-poll-history',
  templateUrl: './poll-history.component.html',
  styleUrls: ['./poll-history.component.scss']
})
export class PollHistoryComponent implements OnInit {
  polls:any = [];
  loading:boolean = true;
  displayAccountModal:boolean = false;
  preFilterPolls:any;
  sportTypes:any = sportTypes.types;
  p: number = 1;
  msgs:any[] = [];
  currentUser:any;
  currentUsername:string;
  currentEmail:string;
  credibility:number;
  accuracyTotal:any;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private seo: SeoService,
    public nav: NavbarService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Poll history',
      description: `View the results of all the fantasy football polls you have previously created.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `polls`
    });
    this.msgs = [];
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty("showSuccess")) {
        this.msgs.push({severity:'success', summary:"Your poll has been sent.", detail:''});
      }
    });

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.currentUser = user;
        const userId = user.uid;
        this.firebaseService.getUser(userId).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username');
          this.currentEmail = user.payload.get('email');
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
        });
        this.firebaseService.pollHistorySubscription(userId).subscribe((state) => {
          if (state[state.length-1] && (state[state.length-1].type == "added" || state[state.length-1].type == "removed")) {
            this.firebaseService.getPolls({uid: userId}).then((response: any) => {
              this.polls = response.polls;
              this.preFilterPolls = response.polls;
            });
          }
        });
      } else {
        this.polls = [];
      }
      this.loading = false;
    });
  }

  showLogin() {
    this.displayAccountModal = true;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  updatePolls(event) {
    this.polls = event;
  }

  deletePoll(poll) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this poll?',
        accept: () => {
          this.firebaseService.deletePoll(poll);
        }
    });
  }


}

import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '../../environments/environment';
import { SeoService } from '../services/seo.service';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-polls-index',
  templateUrl: './polls-index.component.html',
  styleUrls: ['./polls-index.component.scss']
})
export class PollsIndexComponent implements OnInit {

  polls:any;
  loading:boolean = true;
  domain:string = environment.domain;

  constructor(
    private firebaseService:FirebaseService,
    private seo: SeoService,
    public nav: NavbarService
  ) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Polls Index',
      description: `List of the most recent 2,500 polls created on Poll Sports.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `polls-index`
    });
    this.firebaseService.getPolls({limit: 2500}).then((response:any) => {
      this.polls = response.polls
      this.loading = false;
    });
  }

}

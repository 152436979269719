import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../environments/environment';
import { TrackingService } from '../services/tracking.service';

import * as Stripe from 'stripe';

declare var StripeCheckout:any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  constructor(
    private functions: AngularFireFunctions,
    private trackingService: TrackingService
  ) { }

  @Input() amount;
  @Input() description;
  @Input() uid;
  @Input() email;
  @Output()
  checkoutCompleteEvent = new EventEmitter<string>();

  handler:any;

  confirmation: any;
  loading:boolean = false;

  ngOnInit() {
  }

  async checkout(e) {
    this.handler = StripeCheckout.configure({
      key: environment.stripeKey,
      image: 'https://firebasestorage.googleapis.com/v0/b/poll-sports.appspot.com/o/stripe.jpg?alt=media&token=56dc1c5e-ae20-4e9f-b98c-c1afe3b49709',
      // image: '/your-avatar.png',
      locale: 'auto',
      source: async (source) => {
        this.loading = true;
        // const user = await this.auth.getUser();
        const fun = this.functions.httpsCallable('stripeCreateCharge');
        this.confirmation = await fun({ source: source.id, uid: this.uid, amount: this.amount }).toPromise();
        this.trackingService.track('Purchase Successful', {
          amount: this.amount,
          item: 'Poll Boost',
          uid: this.uid,
          email: this.email
        });
        this.checkoutCompleteEvent.emit();
        this.loading = false;

      }
    });
    // const user = await this.auth.getUser();
    this.handler.open({
      name: 'Poll Boost',
      // description: this.description,
      description: 'Boost your poll to the top of the feed',
      amount: this.amount,
      email: this.email,
    });
    e.preventDefault();
  }

  // Close on navigate
  @HostListener('window:popstate')
  onPopstate() {
    if (this.handler) {
      this.handler.close();
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Input, Output, EventEmitter } from '@angular/core';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';

// import custom validator to validate that password and confirm password fields match
import { MustMatch } from '../_helpers/must-match.validator';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  userForm: FormGroup;
  loginForm: FormGroup;
  passReset: boolean = false;
  loginAttemptCount:number = 0;

  @Input()
  showComponent:boolean = false;

  @Input()
  newUser: boolean = false; // to toggle login or signup form

  @Output()
  hideComponentEvent = new EventEmitter<string>();

  constructor(private fb: FormBuilder, private auth: AuthService, private db: AngularFirestore) { }

  ngOnInit(): void {
    this.buildForm();
    this.buildLoginForm();
  }

  toggleForm(): void {
    this.newUser = !this.newUser;
  }

  signup(): void {
    let menu = document.getElementById("menu");
    if (menu) {
      menu.style.display = "";
    }
    const formValue = this.userForm.value;
    this.auth.emailSignUp(formValue.email, formValue.password, formValue.username).then((user) => {
      this.showComponent = false;
      location.reload();
    }).catch(err => {
      if (err.code == 1) {
        this.formErrors.username = "This username is already taken."
      }
      if (err.code == "auth/email-already-in-use") {
        this.formErrors.email = "This email is already in use."
        this.loginAttemptCount++;
      }
    });
  }

  login(): void {
    let menu = document.getElementById("menu");
    if (menu) {
      menu.style.display = "";
    }
    const formValue = this.loginForm.value;
    this.auth.emailLogin(formValue.email, formValue.password).then((user) => {
      this.showComponent = false;
      this.loginAttemptCount = 0;
    })
    .catch((err) => {
      this.loginAttemptCount++;
      console.log("error logging in", err);
      if (err.code == "auth/user-not-found") {
        this.formErrors.email = "There is no user with this email address."
      }
      if (err.code == "auth/invalid-email") {
        this.formErrors.email = "The email address is badly formatted."
      }
      if (err.code == "auth/wrong-password") {
        this.formErrors.password = "The password is invalid"
      }
    });
  }

  hideComponent() {
    this.hideComponentEvent.emit();
  }

  resetPassword() {
    const formValue = this.loginForm.value;
    this.auth.resetPassword(formValue.email)
      .then(() => this.passReset = true)
  }

  buildForm(): void {
    this.userForm = this.fb.group({
      'username': ['', [
         Validators.required,
         Validators.pattern(/^\S*$/),
         Validators.maxLength(30)
       ]
      ],
      'email': ['', [
         Validators.required,
         Validators.email
       ]
      ],
      'password': ['', [
         Validators.minLength(6),
         Validators.maxLength(35)
        ]
      ],
      'confirmPassword': ['', Validators.required]
   }, {
     validator: MustMatch('password', 'confirmPassword')
   });

   this.userForm.valueChanges.subscribe(data => this.onValueChanged(data));
   this.onValueChanged(); // reset validation messages
  }

  buildLoginForm(): void {
    this.loginForm = this.fb.group({
      'email': ['', [
         Validators.required,
         Validators.email
       ]
      ],
      'password': ['', [
         Validators.minLength(6),
         Validators.maxLength(35)
        ]
      ],
   });

   this.loginForm.valueChanges.subscribe(data => this.onValueChanged(data));
   this.onLoginValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
   if (!this.userForm) { return; }
   const form = this.userForm;
   for (const field in this.formErrors) {
     // clear previous error message (if any)
     this.formErrors[field] = '';
     const control = form.get(field);
     if (control && control.dirty && !control.valid) {
       const messages = this.validationMessages[field];
       for (const key in control.errors) {
         this.formErrors[field] += messages[key] + ' ';
       }
     }
   }
  }

  checkUsername(event) {
    let username = this.userForm.value.username.toLowerCase();
    if (username) {
      this.db.collection('usernames').doc(username).valueChanges().pipe(first()).subscribe((foundUsername:any) => {
        if (foundUsername) {
          this.formErrors.username = `${username} has already been taken.`
          this.formSuccess.username = null;
        } else {
          this.formSuccess.username = `${username} is available.`
        }
      });
    }
  }

  onLoginValueChanged(data?: any) {
   if (!this.loginForm) { return; }
   const form = this.loginForm;
   for (const field in this.formErrors) {
     // clear previous error message (if any)
     this.formErrors[field] = '';
     const control = form.get(field);
     if (control && control.dirty && !control.valid) {
       const messages = this.validationMessages[field];
       for (const key in control.errors) {
         this.formErrors[field] += messages[key] + ' ';
       }
     }
   }
  }

  formErrors = {
   'username': '',
   'email': '',
   'password': ''
  };

  formSuccess = {
    'username': ''
  }

  validationMessages = {
   'username': {
     'required':      'Username is required.',
     'pattern':       'Username cannot contain spaces.',
     'maxlength':     'Username cannot be more than 30 characters long'
   },
   'email': {
     'required':      'Email is required.',
     'email':         'Email must be a valid email'
   },
   'password': {
     'required':      'Password is required.',
     'pattern':       'Password must be include at one letter and one number.',
     'minlength':     'Password must be at least 4 characters long.',
     'maxlength':     'Password cannot be more than 40 characters long.',
   },
   'passwordConfirm': {
     'required':      'Confirm password is required.'
   }
  };

}

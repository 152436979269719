import { Component, OnInit, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DOCUMENT } from '@angular/common';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ConfirmationService } from 'primeng/api';

import { first } from 'rxjs/operators';
import * as firebase from 'firebase';

@Component({
  selector: 'app-comments-container',
  templateUrl: './comments-container.component.html',
  styleUrls: ['./comments-container.component.scss']
})
export class CommentsContainerComponent implements OnInit {
  @ViewChild('commentArea') commentArea: ElementRef;

  @Input()
  embeddedCommentsActive:boolean = true;

  @Input()
  pollKey:string;

  @Input()
  pollCreatorUid:string;

  @Input()
  totalCommentsAwarded:number;

  @Input()
  notificationsDismissed:boolean;

  @Output()
  notificationPrompt = new EventEmitter<string>();

  @Output()
  closeCommentsView = new EventEmitter<string>();

  @Output()
  incrementTotalComments = new EventEmitter<string>();

  @Input()
  sport:string;

  commentText:string;
  displayAccountModal:boolean = false;
  comments:any;
  username:string;
  currentUser:any;
  commentIds:string[] = [];
  notificationsSupported:boolean = false;
  allowNotifications:boolean = false;
  loading:boolean = true;
  recipientUsername:string = '';
  recipientUid:string = '';
  commentDetails:any[] = [];

  constructor(
    private firebaseService: FirebaseService,
    private trackingService: TrackingService,
    private afAuth: AngularFireAuth,
    private confirmationService: ConfirmationService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.firebaseService.getComments({poll: this.pollKey}).subscribe((comments:any) => {
      if (firebase.messaging.isSupported()) {
        this.notificationsSupported = true;
        firebase.messaging().getToken().then(token => {
          this.allowNotifications = token ? true : false;
        });
      }
      this.commentIds = comments.map((comment) => comment.payload.doc.id)
      this.loading = false;
    });
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.username = user.payload.get('username');
        });
      }
    });
  }

  submit() {
    if (!this.currentUser || this.currentUser.isAnonymous) {
      this.displayAccountModal = true;
      return;
    }
    this.createComment();
  }

  closeComments() {
    this.closeCommentsView.emit();
  }

  createComment() {
    const comment = {
      text: this.commentText,
      poll: this.pollKey,
      user: this.username
    };
    this.firebaseService.createComment(comment).then((createdComment) => {
      this.trackingService.track('Comment Created', {
        senderId: this.username,
        senderUid: this.currentUser.uid,
        recipientId: this.pollCreatorUid,
        poll: this.pollKey
      });
      this.incrementTotalComments.emit();

      // Send Reply notification if a reply username has been set.
      if (this.recipientUsername) {
        this.sendReplyNotification();
      }

      this.sendPollCreatorNotification();

      // Message remaining people following the poll
      const alreadyNotifiedUsers = [this.pollCreatorUid, this.recipientUid, this.currentUser.uid];
      const remainingUsersToBeNotified = this.commentDetails.map(comment => comment.uid).filter((v, i, a) => a.indexOf(v) === i && !alreadyNotifiedUsers.includes(v));
      remainingUsersToBeNotified.forEach((uid) => {
        this.sendPollFollowerNotification(uid);
      });

      if (!this.notificationsDismissed && this.notificationsSupported && !this.allowNotifications) {
        this.triggerNotificationPrompt();
      }
      var el = this.document.getElementById("comment-window");
      el.scrollTop = el.scrollHeight - el.clientHeight;
    }).catch(err => console.log(err));
    this.commentText = "";
  }

  hideCreateModal() {
    this.displayAccountModal = false;
  }

  triggerNotificationPrompt() {
    this.notificationPrompt.emit();
  }

  deleteComment(commentId) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this comment?',
        accept: () => {
          this.firebaseService.deleteComment(commentId);
          const comment = document.getElementById(commentId)
          if (comment) comment.style.display = "none";
        }
    });
  }

  renderReply(event) {
    this.recipientUsername = event.recipientUsername;
    this.recipientUid = event.recipientUid;
    this.commentText = `@${this.recipientUsername} `;
    this.commentArea.nativeElement.focus();
  }

  sendReplyNotification() {
    this.trackingService.track('Reply Created', {
      recipientId: this.recipientUid,
      senderId: this.username,
      senderUid: this.currentUser.uid,
      poll: this.pollKey,
    });
    // Only notify if you're not commenting on your own poll or own comment
    if (this.recipientUid !== this.currentUser.uid && this.pollCreatorUid !== this.recipientUid) {
      this.firebaseService.createMessage({
        recipientId: this.recipientUid,
        senderId: this.username,
        senderUid: this.currentUser.uid,
        type: 2,
        poll: this.pollKey,
        sport: this.sport
      });
    }
  }

  sendPollCreatorNotification() {
    if (this.pollCreatorUid !== this.currentUser.uid) {
      this.firebaseService.createMessage({
        recipientId: this.pollCreatorUid,
        senderId: this.username,
        senderUid: this.currentUser.uid,
        type: 1,
        poll: this.pollKey,
        sport: this.sport
      });
    }
  }

  sendPollFollowerNotification(uid) {
    if (this.currentUser.uid !== uid) {
      this.firebaseService.createMessage({
        recipientId: uid,
        senderId: this.username,
        senderUid: this.currentUser.uid,
        type: 5,
        poll: this.pollKey,
        sport: this.sport
      });
    }
  }

  // Output Event Emitter used to get all the comment data for notifications
  addCommentDetail(comment) {
    this.commentDetails.push(comment)
  }

}

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './environments/environment';
import schedule from "./assets/data/schedule-nfl-2020.json";

@Injectable()
export class AppConfig {
  public PLAYERS:any = {};
  public WEEK:number;
  public ACCURACY_RANGE:any;
  public SCHEDULE:any;
  localSchedule = schedule.Schedule;

  constructor(private http: HttpClient) {}
  public load() {
    let promise = new Promise((resolve, reject) => {
      this.http.get(environment.cloudfunctionsUrl+"/football").subscribe((players:any) => {
        this.PLAYERS.football = players.Players;
        this.http.get(environment.cloudfunctionsUrl+"/schedule").subscribe((response:any) => {
          this.WEEK = parseInt(response.currentWeek);
          this.ACCURACY_RANGE = response.stats.accuracyRange;
          this.SCHEDULE = response.Schedule;
          resolve(true)
        }, err => {
          console.log("Error retrieving week from server. Using local copy");
          this.WEEK = 1;
          this.SCHEDULE = this.localSchedule;
          reject(false)
        });
      }, error => {
        console.log("Error retrieving football players from server. Attempting to use Firebase static copy");

        this.http.get("https://firebasestorage.googleapis.com/v0/b/poll-sports.appspot.com/o/players-nfl.json?alt=media&token=dd1c7efc-1a89-4ed5-9372-ea31ecb37fe8").subscribe((response:any) => {
          this.WEEK = 1;
          this.SCHEDULE = this.localSchedule;
          this.PLAYERS.football = response.Players;
          resolve(true)
        }, err => {
          console.log("Error retrieving week from server. Using local copy");
          this.WEEK = 1;
          this.SCHEDULE = this.localSchedule;
          reject(false)
        });
      });
    })
    return promise;
  }
}

import { Component, OnInit } from '@angular/core';
// import { FormControl, Validators } from '@angular/forms';
// import { FormsModule } from '@angular/forms';
// import { HttpClient, HttpParams } from '@angular/common/http';

// interface MailChimpResponse {
//   result: string;
//   msg: string;
// }

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  // email:string = "";
  // submitted = false;
  // mailChimpEndpoint = 'https://pollsports.us20.list-manage.com/subscribe/post-json?u=9a10d01d53cc47349a4615fc4&amp;id=e386189b3f&';
  // error = '';

  constructor() { }

  // reactive form components
  // emailControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);

  ngOnInit() {
  }

  // subscribe() {
  //   this.error = '';
  //   const params = new HttpParams()
  //     .set('EMAIL', this.email)
  //     .set('REFERRER', 'site_footer')
  //     .set('b_9a10d01d53cc47349a4615fc4_e386189b3f', ''); // hidden input name
  //
  //   const mailChimpUrl = this.mailChimpEndpoint + params.toString();
  //
  //   this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
  //     if (response.result && response.result !== 'error') {
  //       this.submitted = true;
  //       const btn:any = document.getElementById('subscribe-btn')
  //       btn.disabled = true;
  //     }
  //     else {
  //       if (response.msg.includes("is already subscribed")) {
  //         this.error = this.email+" is already subscribed."
  //       } else {
  //         this.error = response.msg
  //       }
  //     }
  //   }, error => {
  //     console.error(error);
  //     this.error = 'Sorry, an error occurred.';
  //   });
  // }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';

import { NavbarService } from '../services/navbar.service';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { SeoService } from '../services/seo.service';

import sportTypes from '../../assets/types/sport-types.json';

import * as firebase from 'firebase';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  displayAccountModal:boolean = false;
  polls:any;
  preFilterPolls:any;
  msgs:any[] = [];
  sportId:number = 1;
  sportTypes:any = sportTypes.types;
  points:number;
  filterQuery:string;
  selectedPollType:number;
  firstLoad:boolean = true;
  currentUser:any;
  currentUsername:string;
  currentEmail:string;
  hasSeenSkipModal:boolean;
  credibility:number;
  accuracyTotal:any;
  showViewMoreBtn:boolean = false;
  previousLimit:number = 30;
  finishedQuery:boolean = false;
  hideAfterVote:boolean = false;
  isInfluencer:boolean = false;
  feedOptions:any;
  selectedFeed:any;
  numberOfVotes:number = 0;

  displayCreateModal:boolean = false;
  displayPointsModal:boolean = false;
  pointsRequired:number = environment.points.required;
  pointsEarned:number = environment.points.earned;

  constructor(
    private firebaseService:FirebaseService,
    private auth: AuthService,
    public afAuth: AngularFireAuth,
    private seo: SeoService,
    public nav: NavbarService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.nav.show();
    this.feedOptions = [
      {label: 'Inbox', value:0},
      {label: 'Public', value:1}
    ]
    this.seo.generateTags({
      title: 'Poll Sports | Real-time feed',
      description: `Fantasy football real-time feed of polls. Create a poll with your custom league settings and get votes from thousands of fantasy players.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `feed/football`
    });
    this.afAuth.authState.subscribe((user) => {
      // Fetch polls created by user or by someone else
      let dashQuery:any = {sport: this.sportId}
      dashQuery.limit = 30;
      dashQuery.getActive = true;
      this.currentUser = user;
      if (this.currentUser) {
        if (this.currentUser.isAnonymous) {
          this.msgs = [];
          // this.msgs.push({severity:'warn', summary:"You're browsing anonymously. ", detail:'Your votes will not be scored for accuracy until you login.'});
        }
        if (!this.currentUser.isAnonymous) {
          dashQuery.limit = 30;
          this.previousLimit = 30;
        }
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username');
          this.currentEmail = user.payload.get('email');
          this.hasSeenSkipModal = user.payload.get('hasSeenSkipModal') ? user.payload.get('hasSeenSkipModal') : false;
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
          this.isInfluencer = user.payload.get('isInfluencer') ? user.payload.get('isInfluencer') : false;
          if (this.isInfluencer) {
            dashQuery.recipientId = this.currentUser.uid;
            this.selectedFeed = this.feedOptions[0].value;
          } else {
            this.selectedFeed = this.feedOptions[1].value;
          }
          // Hide voted polls by default
          if (user.payload.get('settings') && user.payload.get('settings').hasOwnProperty("hideAfterVote")) {
            this.hideAfterVote = user.payload.get('settings').hideAfterVote
          } else {
            this.hideAfterVote = false;
          }

          this.updateDashboard(dashQuery)
        });
      }
    });
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  logout() {
    this.auth.logout();
  }

  promptLogin() {
    this.displayAccountModal = true;
  }

  showMsg() {
    this.msgs = [];
    this.msgs.push({severity:'success', summary:'', detail:''});
  }

  hide() {
    this.msgs = [];
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  updatePolls(event) {
    this.polls = event;
  }

  showCreateModal() {
    this.displayCreateModal = true;
    if (!this.currentUser) {
      this.displayAccountModal = true;
    } else {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.points = user.payload.get('points')
        // Must have required points to post a question
        if (this.points < this.pointsRequired) {
          this.displayPointsModal = true;
        } else {
          this.pollForm.showCreate();
        }
      });
    }
  }

  updateDashboard(dashQuery) {
    dashQuery.boosted = true;
    this.firebaseService.getPolls(dashQuery).then((boostedResponse:any) => {
      dashQuery.boosted = false;
      let combinedPolls;
      let visibleBoostedPolls = [];
      let visiblePolls = [];


      this.firebaseService.getPolls(dashQuery).then((response:any) => {

        combinedPolls = [...boostedResponse.polls, ...response.polls];

        if (this.currentUser) {
          this.polls = this.polls || [];
          (async () => {
            for (const poll of combinedPolls) {
              let pollData = poll.data();
              let usersWhoHaveVoted = pollData.usersWhoHaveVoted ? pollData.usersWhoHaveVoted : [];
              let hasVoted = usersWhoHaveVoted.includes(this.currentUser.uid);
              let isPollCreator = pollData.uid === this.currentUser.uid;

              if (!hasVoted && !pollData.expired) {
                if (pollData.boosted) {
                  visibleBoostedPolls.push(poll);
                } else if (!isPollCreator) {
                  visiblePolls.push(poll);
                }
              }
            }

            if (visiblePolls.length > 0) {

              if (visibleBoostedPolls.length > 0) {
                var unique = {};
                visibleBoostedPolls = visibleBoostedPolls.filter(function(poll) {
                  if (unique[poll.id]) {
                    return false;
                  }
                  unique[poll.id] = true;
                  return true;
                });
              }

              // Alternate b/w boosted and regular polls.
              this.polls = [visibleBoostedPolls, visiblePolls]
              .reduce((r, a) => (a.forEach((a, i) => (r[i] = r[i] || []).push(a)), r), [])
              .reduce((a, b) => a.concat(b));

            }

            this.preFilterPolls = this.polls;
            if (this.polls.length == 0) {
              this.loadMore();
            }

            // Update dashboard to remove newly voted polls
            this.firebaseService.getVotes({uid: this.currentUser.uid, limit: 1}).subscribe(votes => {
              if (!this.firstLoad && votes.length > 0 && this.hideAfterVote) {
                let votedPollId = votes[0] ? votes[0].payload.doc.get("poll") : '';
                let pollType = votes[0] ? votes[0].payload.doc.get("pollType") : '';
                let choicesAllowed = votes[0] ? votes[0].payload.doc.get("choicesAllowed") : 1;
                let timeoutDuration = choicesAllowed > 1 ? 20000 : 2500;
                setTimeout(() => {
                  this.polls = this.polls.filter(poll => poll.id !== votedPollId);
                  this.preFilterPolls = this.polls;
                  if (this.polls.length == 0) {
                    this.loadMore();
                  }
                }, timeoutDuration);
              } else {
                if (!this.firstLoad && !this.hideAfterVote && this.numberOfVotes === this.polls.length-1) {
                  this.numberOfVotes = 0;
                  this.loadMore();
                }
              }
              this.firstLoad = false;
            });
          })();
        } else {
          this.polls = combinedPolls;
          this.preFilterPolls = this.polls;
        }
      });
    });
  }

  loadMore() {
    if (this.finishedQuery) return;
    this.previousLimit = this.previousLimit*2;
    if (this.previousLimit <= 480 && !this.finishedQuery) {
      if (this.selectedFeed === 0) {
        this.updateDashboard({sport: this.sportId, limit: this.previousLimit, recipientId: this.currentUser.uid});
      } else {
        this.updateDashboard({sport: this.sportId, limit: this.previousLimit});
      }
    } else {
      this.finishedQuery = true;
    }
  }

  updatePollHide() {
    if (!this.currentUser.isAnonymous) {
      this.firebaseService.updateUser(this.currentUser.uid, { settings: {hideAfterVote: this.hideAfterVote} })
    }
  }

  changeFeed(type) {
    this.selectedFeed = type.value;
    if (this.selectedFeed === 0) {
      this.updateDashboard({sport: this.sportId, limit: 30, recipientId: this.currentUser.uid, getActive: true});
      this.previousLimit = 30;
    } else {
      this.updateDashboard({sport: this.sportId, limit: 30, getActive: true});
      this.previousLimit = 30;
    }
  }

  boostedPoll() {
    this.updateDashboard({sport: this.sportId, limit: 30, getActive: true});
  }

  decrementVote() {
    this.numberOfVotes--
  }

  incrementVote() {
    this.numberOfVotes++
  }

  hidePoll(pollKey) {
    this.polls = this.polls.filter(poll => poll.id !== pollKey);
    this.preFilterPolls = this.polls;
    this.incrementVote();
    if (this.polls.length == 0) {
      this.loadMore();
    }
  }

  updateHasSeenSkipModal() {
    this.hasSeenSkipModal = true;
  }

}

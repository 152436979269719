import { Component, OnInit } from '@angular/core';
import { FirebaseService } from "../services/firebase.service";
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from "rxjs/operators";

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
  votes:number;
  users:number;
  polls:number;
  topUsers:any;
  topUsersVotes:any;
  topUsersPolls:any;

  constructor(private firebaseService: FirebaseService, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.afAuth.authState.subscribe((user) => {
      if (!user.isAnonymous) {
        this.firebaseService.getStatsPolls({}).then((response:any) => {
          this.polls = response.polls.length;
        });

        this.firebaseService.getVotes({}).pipe(first()).subscribe(votes => {
          this.votes = votes.length;
        });

        this.firebaseService.getUsers({}).pipe(first()).subscribe((users:any) => {
          this.users = users.length;
        });

        this.firebaseService.getUsers({orderBy: "credibility", limit: 26}).pipe(first()).subscribe((users:any) => {
          this.topUsers = users.map((user) => {
            return { ...user.payload.doc.data(), uid: user.payload.doc.id }
          });
        });

        this.firebaseService.getUsers({orderBy: "votes", limit: 26}).pipe(first()).subscribe((users:any) => {
          this.topUsersVotes = users.map((user) => {
            return { ...user.payload.doc.data(), uid: user.payload.doc.id }
          });
        });

        this.firebaseService.getUsers({orderBy: "polls", limit: 26}).pipe(first()).subscribe((users:any) => {
          this.topUsersPolls = users.map((user) => {
            return { ...user.payload.doc.data(), uid: user.payload.doc.id }
          });
        });
      }
    });
  }

}

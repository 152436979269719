import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UpvoteService } from '../services/upvote.service';

@Component({
  selector: 'app-upvote-button',
  templateUrl: './upvote-button.component.html',
  styleUrls: ['./upvote-button.component.scss']
})
export class UpvoteButtonComponent implements OnInit, OnDestroy {

  @Input() userId;
  @Input() username;
  @Input() itemId;
  @Input() commentUid;
  @Input() commentUsername;
  @Input() pollKey;
  @Input() sport;

  voteCount: number = 0;
  userVote: number = 0;

  subscription;

  constructor(private upvoteService: UpvoteService) { }

  ngOnInit() {
    this.subscription = this.upvoteService.getItemVotes(this.itemId)
      .subscribe(upvotes => {
        if (this.userId) this.userVote = upvotes ? upvotes[this.userId] : {};
        const upvoteValues = upvotes ? Object.values(upvotes) : [];
        this.voteCount = upvoteValues.reduce((a, b) => a + b, 0);
      });
  }

  upvote() {
    if (this.userId == this.commentUid) return;
    let vote = this.userVote == 1 ? 0 : 1
    let userVote = {
      vote,
      ...this.getUserVoteDetails()
    }
    this.upvoteService.updateUserVote(userVote);
  }

  downvote() {
    if (this.userId == this.commentUid) return;
    let vote = this.userVote == -1 ? 0 : -1
    let userVote = {
      vote,
      ...this.getUserVoteDetails()
    }
    this.upvoteService.updateUserVote(userVote);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getUserVoteDetails() {
    return {
      itemId: this.itemId,
      userId: this.userId,
      recipientId: this.commentUid,
      senderUsername: this.username,
      pollKey: this.pollKey,
      sport: this.sport
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { FirebaseService } from "../services/firebase.service";
import { AngularFireAuth } from '@angular/fire/auth';
import { NavbarService } from '../services/navbar.service';
import { ActivatedRoute } from '@angular/router';
import { first } from "rxjs/operators";

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.scss']
})
export class EmbedComponent implements OnInit {
  poll:any;
  polls:any = [];
  currentUser:any;
  currentUsername:any;
  currentEmail:any;
  pollId:string;
  loading:boolean = true;
  hasClickedNext:boolean = false;
  lastIndex:number;
  reset: any[] = [{}];
  originalPoll:any;
  showPrevious:boolean = false;
  domain:string = environment.domain;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public nav: NavbarService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.nav.hide();
    document.body.parentElement.style.overflow = "hidden";
    this.activatedRoute.queryParams.subscribe(params => {
        this.pollId = params['id'];
        // Error handling if no ID is given or ID is invalid
        this.firebaseService.getPoll(this.pollId).pipe(first()).subscribe((poll) => {
          this.originalPoll = poll;
          this.poll = poll;
          this.reset[0] = this.poll;
          this.loading = false;
        });
    });
    this.afAuth.authState.pipe(first()).subscribe((user) => {
      this.currentUser = user;
      if (user) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username');
          this.currentEmail = user.payload.get('email');
        });
      }
    });
  }

  next(skipTimeout) {
    if (skipTimeout) {
      this.displayNextPoll();
    } else {
      setTimeout(() => {
        this.displayNextPoll();
      }, 3000);
    }
  }

  previous() {
    if (this.lastIndex === 1) {
      this.lastIndex = 0
      this.poll = this.polls[this.lastIndex];
      this.reset[0] = this.poll;
      this.showPrevious = false;
    } else {
      this.lastIndex = this.lastIndex-1
      this.poll = this.polls[this.lastIndex];
      this.reset[0] = this.poll;
    }
  }

  displayNextPoll() {
    this.loading = true;
    if (!this.hasClickedNext) {
      this.firebaseService.getPolls({limit: 15, getActive: true}).then((response:any) => {
        this.polls[0] = this.poll
        this.polls = [...this.polls, ...response.polls];
        this.lastIndex = 1
        this.poll = this.polls[this.lastIndex]
        this.reset[0] = this.poll;
        this.hasClickedNext = true;
        this.showPrevious = true;
        this.loading = false;
      });
    } else {
      this.lastIndex = this.lastIndex+1
      this.poll = this.polls[this.lastIndex]
      this.reset[0] = this.poll;
      this.showPrevious = true;
      this.loading = false;
    }
  }

  goToFeed() {
    window.open(`${this.domain}/feed/football`);
  }

  goHome() {
    window.open(this.domain);
  }

}

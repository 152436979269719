import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-points-dialog',
  templateUrl: './points-dialog.component.html',
  styleUrls: ['./points-dialog.component.scss']
})
export class PointsDialogComponent implements OnInit {
  @Input()
  displayPointsModal:boolean;

  @Input()
  type:string;

  @Output()
  hidePointsModalEvent = new EventEmitter<string>();

  points:number;
  pointsEarned:number = environment.points.earned;
  pointsRequired:number = environment.points.required;
  header:string;

  constructor(
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    if (this.type == "balance") {
      this.header = "You need more coins"
    } else if (this.type == "wiki") {
      this.header = "Coins"
    }
    this.afAuth.authState.pipe(first()).subscribe(user => {
      if (user) {
        this.firebaseService.getUser(user.uid).pipe(first()).subscribe((userDoc:any) => {
          this.points = userDoc.payload.get('points');
        });
      } else {
        this.points = 0;
      }
    });
  }

  maybePluralize (count, noun, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
  }

  hidePointsModal() {
    this.hidePointsModalEvent.emit();
  }

}

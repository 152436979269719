import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NavbarService } from '../services/navbar.service';
import { ProgressBarModule } from 'primeng/progressbar';
import { FirebaseService } from '../services/firebase.service';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { first } from "rxjs/operators";

@Component({
  selector: 'app-fan-page',
  templateUrl: './fan-page.component.html',
  styleUrls: ['./fan-page.component.scss']
})
export class FanPageComponent implements OnInit {

  inventory:Number = 0;
  price:number = 0;
  p: number = 1;
  priceDisplay:any = "It's free!";
  polls:any;
  fanPageOwner:any;
  createdBtnActive:boolean = true;
  displayCreateModal:boolean = false;
  displayAccountModal:boolean = false;
  currentUser:any;
  slug:string;
  isFanPageOwner:boolean = false;
  isSoldOut:boolean = false;
  currentUsername:any;
  currentEmail:any;
  credibility:any;
  accuracyTotal:any;

  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  constructor(
    private route: ActivatedRoute,
    public nav: NavbarService,
    private firebaseService: FirebaseService,
    private router: Router,
    public afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    this.setUser();
    this.nav.show();
    this.initPolls();
  }

  initPolls() {
    this.route.params.subscribe(params => {
      this.slug = params.id.replace(/ +/g, "");
      this.firebaseService.getUserBySlug(this.slug)
        .pipe(first()).subscribe((response: any) => {
          const user = response[0];
          this.fanPageOwner = user.payload.doc.data();
          this.fanPageOwner.id = user.payload.doc.id;

          if (this.fanPageOwner.id === this.afAuth.auth.currentUser.uid) {
            this.isFanPageOwner = true;
          }

          if (this.fanPageOwner.sendInventory) {
            this.inventory = (this.fanPageOwner.sendInventory.received*100)/this.fanPageOwner.sendInventory.allowed;
            this.price = this.fanPageOwner.sendInventory.price;
            this.priceDisplay = this.price <= 0 ? "It's free!" : `$${this.fanPageOwner.sendInventory.price/100}`;
            this.isSoldOut = (this.fanPageOwner.sendInventory.allowed - this.fanPageOwner.sendInventory.received) <= 0;
          }

          if (this.fanPageOwner.lastLogin) {
            let d = new Date(0)
            d.setUTCSeconds(this.fanPageOwner.lastLogin.seconds);
            this.fanPageOwner.lastLogin = d;
          }
          this.getPolls({ uid: this.fanPageOwner.id });
        });
    });
  }

  getPolls(query) {
    this.firebaseService.getPolls(query).then((response: any) => {
      this.polls = response.polls;
    });
  }

  getCreated() {
    this.createdBtnActive = true;
    this.getPolls({ uid: this.fanPageOwner.id });
  }

  getInbox() {
    this.createdBtnActive = false;
    this.getPolls({ recipientId: this.fanPageOwner.id });
  }

  sendPoll() {
    if (this.currentUser.isAnonymous) {
      this.displayAccountModal = true;
    } else {
      this.router.navigate([`/send/${this.slug}/checkout`])
    }
  }

  setUser() {
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.firebaseService
          .getUser(this.currentUser.uid)
          .pipe(first())
          .subscribe((user:any) => {
            this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
            this.currentEmail = user.payload.get('email') ? user.payload.get('email') : '';
            this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
            this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
        });
      }
    });
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  goToEdit() {
    this.router.navigate([`/send/${this.slug}/edit`]);
  }

}

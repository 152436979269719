import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-blurb',
  templateUrl: './info-blurb.component.html',
  styleUrls: ['./info-blurb.component.scss']
})
export class InfoBlurbComponent implements OnInit {

  displayAccountModal:boolean = false;
  showFirst:boolean = false;
  showMiddle:boolean = false;
  showLast:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  collapse(item) {
    if (item === "first") {
      this.showFirst = false;
      this.showMiddle = false;
      this.showLast = false;
    } else if (item === "middle") {
      this.showMiddle = false;
      this.showLast = false;
      this.showFirst = false;
    } else if (item === "last") {
      this.showLast = false;
      this.showFirst = false;
      this.showMiddle = false;
    }
  }

  toggle(item) {
    if (item === "first") {
      this.showFirst = !this.showFirst;
      this.showMiddle = false;
      this.showLast = false;
    } else if (item === "middle") {
      this.showMiddle = !this.showMiddle;
      this.showLast = false;
      this.showFirst = false;
    } else if (item === "last") {
      this.showLast = !this.showLast;
      this.showFirst = false;
      this.showMiddle = false;
    }
  }

  openAccountModal() {
    this.displayAccountModal = true;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

}

import { Component, OnInit, HostListener, ViewChild, EventEmitter, Output } from '@angular/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { AuthService } from '../services/auth.service';
import sportTypes from '../../assets/types/sport-types.json'
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  items: MenuItem[];
  activeItem: MenuItem;
  points:number;
  user:any = {email: '', points: 0, credibility: 0, uid: ''};
  username:string;
  displayAccountModal:boolean = false;
  sportTypes:any = sportTypes.types;
  badgeSrc:string;
  displayPointsModal:boolean = false;
  currentUser:any;

  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  @Output()
  closeMenuEvent = new EventEmitter<string>();

  @Output()
  displaySignInEvent = new EventEmitter<string>();

  @Output()
  displaySignUpEvent = new EventEmitter<string>();

  @Output()
  displayPointsModalEvent = new EventEmitter<string>();


  constructor(
    private router: Router,
    private location: Location,
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.initMobileMenu();
    // ToDo: Trigger menu update when converting anon account to registered account
    // Currently requires refresh for this to show up.
    this.afAuth.authState.subscribe((user:any) => {
      this.currentUser = user;
      if (user) {
        if (user.isAnonymous) {

        } else {
          this.user.uid = user.uid;
          this.firebaseService.getUser(user.uid).pipe(first()).subscribe((user:any) => {
            const userDetails = user.payload.data();
            if (userDetails) {
              this.user.email = userDetails.hasOwnProperty('email') ? userDetails.email : '';
              this.user.points =  userDetails.hasOwnProperty('points') ? userDetails.points : 0;
              this.user.credibility = userDetails.hasOwnProperty('credibility') ? userDetails.credibility : 0;
              this.user.isPro = userDetails.hasOwnProperty('isPro') ? userDetails.isPro : false;
              this.username = userDetails.hasOwnProperty('username') ? userDetails.username : '';;
              this.setBadgeSrc();
            }
          });
        }
      } else {
        this.username = null;
      }
    });
  }

  toggleOption(e, sport) {
    this.router.navigateByUrl('/feed/'+sport);
    if (e.target) {
      const titles = document.getElementsByClassName('title');
      [].forEach.call(titles, (el) => {
        el.parentNode.classList.remove('active');
      });
      e.target.parentNode.parentNode.classList.add('active');
      this.closeMenu();
    }
  }

  goToFeed() {
    const urlPath = this.location.path();
    const splitPath = urlPath.split("/");
    const pathLength = splitPath.length;
    let sport = pathLength == 4 ? splitPath[pathLength-2] : splitPath[pathLength-1];
    if (sport !== 'football' && sport !== 'baseball') {
      sport = 'football'
    }
    this.router.navigate([`/feed/${sport}`]);
  }

  initMobileMenu() {
    const mappedMenuItems = this.sportTypes.map(type => {
      return {
        label: type.name,
        items: [
          {
            label: 'Feed',
            command: (event) => {
              this.router.navigateByUrl('"/feed/"+sport.name');
            }},
          {
            label: 'My Polls',
            command: (event) => {
              this.router.navigateByUrl('"/polls/"+sport.name');
            }
          },
          {
            label: 'Search',
            command: (event) => {
              this.router.navigateByUrl('/search/');
            }
          }
        ]
      }
    });
    this.items = [
      {
        label: 'Sports', icon: 'fa fa-fw fa-check',
        items: mappedMenuItems
      }
    ];
  }

  displaySignIn() {
    this.displaySignInEvent.emit();
  }

  displaySignUp() {
    this.displaySignUpEvent.emit();
  }

  logout() {
    this.auth.logout();
    this.closeMenu();
  }

  setBadgeSrc() {
    if (this.user) {
      const credibility = this.user.credibility;
      if (credibility < 1000) {
        this.badgeSrc = "/assets/badges/no-text/badge-01.svg"
      } else if (credibility >= 1000 && credibility < 2500) {
        this.badgeSrc = "/assets/badges/no-text/badge-02.svg"
      } else if (credibility >= 2500 && credibility < 5000) {
        this.badgeSrc = "/assets/badges/no-text/badge-03.svg"
      } else if (credibility >= 5000 && credibility < 10000) {
        this.badgeSrc = "/assets/badges/no-text/badge-04.svg"
      } else if (credibility >= 10000 && credibility < 25000) {
        this.badgeSrc = "/assets/badges/no-text/badge-05.svg"
      } else if (credibility >= 25000 && credibility < 50000) {
        this.badgeSrc = "/assets/badges/no-text/badge-06.svg"
      } else if (credibility >= 50000) {
        this.badgeSrc = "/assets/badges/no-text/badge-07.svg"
      }
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  closeMenu() {
    this.closeMenuEvent.emit();
  }

  showCoinsWiki() {
    this.displayPointsModalEvent.emit();
  }

}

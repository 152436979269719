import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { UpvoteButtonComponent } from '../upvote-button/upvote-button.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { first } from 'rxjs/operators';
import * as firebase from 'firebase';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {
  displayCommentForm:boolean = false;
  displayEditCommentForm:boolean = false;
  displayAccountModal:boolean = false;

  // @Input()
  // comment:any
  comment:any;
  replyText:string;
  pollKey:string;
  commentUid:string;
  commentUsername:string;
  hasAward:boolean = false;

  @Input()
  notificationsDismissed:boolean;

  @Input()
  isEmbeddedComment:boolean = true;

  @Input()
  sport:string

  @Input()
  commentId:string

  @Input()
  currentUser:any

  @Input()
  username:any

  @Input()
  pollCreatorUid:any

  @Input()
  totalCommentsAwarded:number

  @Output()
  commentDetails = new EventEmitter<any>();

  @Output()
  notificationPrompt = new EventEmitter<string>();

  @Output()
  deleteComment = new EventEmitter<string>();

  @Output()
  renderReply = new EventEmitter<any>();

  text:string;
  date:any;
  notificationsSupported:boolean = false;
  allowNotifications:boolean = false;

  constructor(
    private firebaseService: FirebaseService,
    private trackingService: TrackingService
  ) { }

  ngOnInit() {
    this.totalCommentsAwarded = this.totalCommentsAwarded ? this.totalCommentsAwarded : 0;
    if (this.commentId) {
      this.firebaseService.getComment(this.commentId).pipe(first()).subscribe((comment:any) => {
        if (firebase.messaging.isSupported()) {
          this.notificationsSupported = true;
          firebase.messaging().getToken().then(token => {
            this.allowNotifications = token ? true : false;
          });
        }
        if (comment && comment.payload.exists) {
          comment.payload.ref.get().then((commentRef) => {
            this.comment = commentRef.data();
            this.text = this.comment.text;
            this.pollKey = this.comment.poll;
            this.commentUid = this.comment.uid;
            this.commentUsername = this.comment.user;
            this.hasAward = this.comment.awarded;
            if (this.comment.createdAt) {
              let d = new Date(0)
              d.setUTCSeconds(this.comment.createdAt.seconds);
              this.date = d;
            }
            this.commentDetails.emit(this.comment);
          });
        }
      });
    }
    // ToDo, fetch comments only once and pass them through
    // } else {
    //   console.log("PARENT COMMENT", this.comment);
    //   let comment = this.comment.payload.doc.data();
    //   this.text = comment.text;
    //   this.username = comment.user;
    //   if (comment.createdAt) {
    //     let d = new Date(0)
    //     d.setUTCSeconds(comment.createdAt.seconds);
    //     this.date = d;
    //   }
    // }
  }

  reply(commentId) {
    if (!this.currentUser || this.currentUser.isAnonymous) {
      this.displayAccountModal = true;
      return;
    }
    this.renderReply.emit({recipientUsername: this.commentUsername, recipientUid: this.commentUid})
  }

  delete(commentId) {
    this.deleteComment.emit(commentId);
  }

  edit() {
    this.replyText = this.text;
    this.displayEditCommentForm = true;

  }

  updateComment() {
    this.firebaseService.updateComment(this.commentId, {text: this.replyText}).then(() => {
      this.text = this.replyText;
    })
    this.displayEditCommentForm = false;
  }

  awardComment() {
    this.firebaseService.awardComment(this.pollKey, this.commentId).then(() => {
      this.hasAward = true;
      this.firebaseService.getUser(this.pollCreatorUid).pipe(first()).subscribe((pollCreator) => {
        pollCreator.payload.ref.get().then((userData:any) => {
          ++this.totalCommentsAwarded;
          let pollCreatorUsername = userData.data().username;
          this.firebaseService.createMessage({
            recipientId: this.commentUid,
            senderId: pollCreatorUsername,
            senderUid: this.currentUser.uid,
            type: 3,
            poll: this.pollKey,
            sport: this.sport
          });
          this.trackingService.track('Awarded Comment', {
            senderId: pollCreatorUsername,
            senderUid: this.currentUser.uid,
            recipientId: this.commentUid,
            poll: this.pollKey,
            comment: this.text
          });
        });
      });
    });

  }

  hideCreateModal() {
    this.displayAccountModal = false;
  }

}

import { Component, OnInit, Input, EventEmitter, Output, ViewChild, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { environment } from '../../environments/environment';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { first } from 'rxjs/operators';
import pollTypes from '../../assets/types/poll-types.json';
import { ListboxModule } from 'primeng/listbox';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  // Hack to close filter window when clicking outside
  // @HostListener('document:click', ['$event']) onGlobalClick($event) {
  //   const target = event.target as HTMLTextAreaElement;
  //   const tagFilterClasses = [
  //     "ui-chkbox-icon",
  //     "filter-by-tags-placeholder-text-mobile",
  //     "ui-chkbox-box",
  //     "ui-listbox-item",
  //     "filter-by-tags-placeholder-text",
  //     "pi-chevron-down"
  //   ];
  //   const tagFilterClicked = Array.from(target.classList).some(className => tagFilterClasses.includes(className));
  //   if (tagFilterClicked) {
  //     this.showTagFilter = true;
  //   } else {
  //     this.showTagFilter = false;
  //     this.showTypeFilter = false;
  //   }
  // }

  @Input()
  preFilterPolls:any;

  @Input()
  page:any;

  @Output()
  resultsEvent = new EventEmitter<any>();

  @Output()
  filterEvent = new EventEmitter<string>();

  sortOptions:any;
  filterSportPollTypes:any = pollTypes;
  selectedSortType:any;
  filterQuery:any;
  polls:any;
  points:number;
  displayAccountModal:boolean = false;
  displayCreateModal:boolean = false;
  displayPointsModal:boolean = false;
  pointsRequired:number = environment.points.required;
  pointsEarned:number = environment.points.earned;
  hideFilters:boolean = false;
  pollTagOptions: any[];
  pollTypeOptions:any[];
  selectedPollTags: any = [];
  selectedPollTypes: any = [];
  showTagFilter:boolean = false;
  showTypeFilter:boolean = false;

  constructor(
    public afAuth: AngularFireAuth,
    private firebaseService: FirebaseService,
    private location: Location,
    public route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.pollTagOptions = [
        {name: "Dynasty", code: "DYNASTY"},
        {name: "Keeper", code: "KEEPER"},
        {name: "Redraft", code: "REDRAFT"},
        {name: "Standard", code: "STD"},
        {name: "0.5 PPR", code: "0.5PPR"},
        {name: "PPR", code: "PPR"},
        {name: "4pt passing", code: "4PTPASS"},
        {name: "5pt passing", code: "5PTPASS"},
        {name: "6pt passing", code: "6PTPASS"},
        {name: "Superflex/2QB", code: "SUPERFLEX/2QB"},
        {name: "2TE/TE Premium", code: "2TE/TEPREMIUM"},
        {name: "PPC", code: "PPC"},
        {name: "Rebuild", code: "REBUILD"},
        {name: "Win now", code: "WINNOW"},
        {name: "Completed Trade", code: "COMPLETED"}
    ];
    const urlPath = this.location.path();
    if (urlPath.includes("/feed")) {
      this.hideFilters = true
    }
    const splitPath = urlPath.split("/");
    let sport = splitPath[splitPath.length-1];
    // ToDo make sport dynamic again.
    sport = "football";
    this.pollTypeOptions = [...this.filterSportPollTypes[sport]];
    this.sortOptions = [
      {"name": "New", "code": "N"},
      {"name": "Controversial", "code": "C"},
      {"name": "Most Votes", "code": "MV"},
      {"name": "Least Votes", "code": "LV"},
      {"name": "Most Comments", "code": "MC"},
      {"name": "Least Comments", "code": "LC"}
    ];
    this.route.queryParams.subscribe(params => {
        const paramTags = params['tags'] && params['tags'].split(",");
        const paramTypes = params['types'] && params['types'].split(",");
        const sortBy = params['sortBy'];
        if (paramTags || paramTypes || sortBy) {
          if (paramTags) {
            this.pollTagOptions.forEach((tag) => {
              if (paramTags.includes(tag.code)) {
                this.selectedPollTags.push(tag);
              }
            });
          }
          if (paramTypes) {
            this.pollTypeOptions.forEach((type) => {
              if (paramTypes.includes(type.code)) {
                this.selectedPollTypes.push(type);
              }
            });
          }
          if (sortBy) {
            this.sortOptions.forEach((option) => {
              if (option.code === sortBy) {
                this.selectedSortType = option;
              }
            })
          }
          this.filterByTags();
        }
    });

  }

  filterByTags() {
    this.updateLocationState();
    this.polls = this.queryByTags();
    if (this.filterQuery) {
      this.filterPolls();
      return;
    }
    this.sortPolls();
  }

  filterPolls() {
    this.polls = this.queryPolls(this.filterQuery);
    this.sortPolls();
  }

  loadMore() {
    this.filterEvent.emit();
  }

  queryByTags() {
    const leagueTypes = ["DYNASTY", "REDRAFT", "KEEPER"];
    const selectedTags = this.selectedPollTags && this.selectedPollTags.length > 0 ? this.selectedPollTags.map(type => type.code) : [];
    const poppedLeagueTags = selectedTags.filter(tag => leagueTypes.includes(tag));
    const filteredSelectedTags = selectedTags.filter(tag => !leagueTypes.includes(tag));

    // Mapping to help reduce the visible filter options
    if (filteredSelectedTags.includes("SUPERFLEX/2QB")) {
      filteredSelectedTags.push("SUPERFLEX");
      filteredSelectedTags.push("2QB");
    }
    if (filteredSelectedTags.includes("2TE/TEPREMIUM")) {
      filteredSelectedTags.push("2TE");
      filteredSelectedTags.push("TEPREMIUM");
    }
    if (filteredSelectedTags.includes("PPC")) {
      filteredSelectedTags.push("0.10PPC");
      filteredSelectedTags.push("0.25PPC");
      filteredSelectedTags.push("0.5PPC");
    }

    let results = [];

    // Reset back to all
    // if (selectedTags.length <= 0) {
    //   let results = this.preFilterPolls;
    //   this.resultsEvent.emit(results);
    //   return;
    // }

    // Step 1, filter out globally based on dynasty, keeper, or redraft
    results = this.preFilterPolls.filter(poll => {
      const pollTags = poll.data().tags;
      const pollTagCodes = pollTags.map(tag => tag.code);
      // ToDo: I can clean this up and just use the else statement once all polls are tagged as Redraft
      if (poppedLeagueTags.includes("REDRAFT") && !poppedLeagueTags.includes("DYNASTY") && !poppedLeagueTags.includes("KEEPER")) {
        if (!pollTags.some(tag => tag.code === "DYNASTY" || tag.code === "KEEPER")) {
          return poll;
        }
      } else if (poppedLeagueTags.includes("REDRAFT") && poppedLeagueTags.includes("DYNASTY") && !poppedLeagueTags.includes("KEEPER")) {
        if (!pollTags.some(tag => tag.code === "KEEPER")) {
          return poll;
        }
      } else if (poppedLeagueTags.includes("REDRAFT") && poppedLeagueTags.includes("KEEPER") && !poppedLeagueTags.includes("DYNASTY")) {
        if (!pollTags.some(tag => tag.code === "DYNASTY")) {
          return poll;
        }
      } else if (poppedLeagueTags.includes("REDRAFT") && poppedLeagueTags.includes("KEEPER") && poppedLeagueTags.includes("DYNASTY")) {
          return poll;
      } else if (poppedLeagueTags.length <= 0) {
        return poll;
      }
      else {
        if (pollTags.some(tag => poppedLeagueTags.includes(tag.code))) {
          return poll;
        }
      }
    });

    // Step 2 filter out based on tags
    if (filteredSelectedTags.length > 0) {

      results = results.filter(poll => {
        const pollTags = poll.data().tags;
        const pollTagCodes = pollTags.map(tag => tag.code);

        // Returns polls that are tagged with ALL selected options
        // if (selectedTags.every(selectedTag => pollTagCodes.includes(selectedTag))) {
        //   return poll;
        // }

        // Returns polls that are tagged with ANY of the selected options (minus league types dynasty, redraft, keeper).
        if (pollTags.some(tag => filteredSelectedTags.includes(tag.code))) {
          return poll;
        }

        // Returns polls that are tagged with ANY of the selected options (league types included)
        // pollTags.some(tag => selectedTags.includes(tag.code))

      });
    }

    const selectedPollTypes = this.selectedPollTypes && this.selectedPollTypes.length > 0 ? this.selectedPollTypes.map(type => type.code) : [];

    if (selectedPollTypes.length <= 0) {
      this.resultsEvent.emit(results);
      return;
    }

    results = results.filter(poll => {
      const pollType = poll.data().pollType;
      if (selectedPollTypes.includes(pollType)) {
        return poll;
      }
    });

    this.resultsEvent.emit(results);
    return results;
  }

  queryPolls(query) {
    if (!query) return this.queryByTags();

    // Used to set default polls if no tags have been selected yet.
    let polls = this.queryByTags();
    if (!polls) {
      polls = this.polls && this.polls.length > 0 ? this.polls : this.preFilterPolls;
    }

    let results = polls.filter(poll => {
      let choices;
      if (poll.hasOwnProperty('payload')) {
        let pollDoc = poll.payload.hasOwnProperty('doc') ? poll.payload.doc : poll.payload;
        choices = pollDoc.get('choices');
      } else {
        choices = poll.data().choices;
      }
      if (choices) {
        let names = choices.map(choice => choice.players.map(player => player.displayName));
        names = names.join().toLowerCase();
        if (names.includes(query.toLowerCase())) {
          return poll;
        }
      }
    });
    this.resultsEvent.emit(results);
    return results;
  }

  sortPolls() {
    if (!this.selectedSortType) return;
    this.updateLocationState();

    let results;
    const polls = this.polls && this.polls.length > 0 ? this.polls : this.preFilterPolls;

    if (this.selectedSortType.code === "MV") {
      results = polls.sort(this.sortByMostVotes);
    } else if (this.selectedSortType.code === "LV") {
      results = polls.sort(this.sortByLeastVotes);
    } else if (this.selectedSortType.code === "MC") {
      results = polls.sort(this.sortByMostComments);
    } else if (this.selectedSortType.code === "LC") {
      results = polls.sort(this.sortByLeastComments);
    } else if (this.selectedSortType.code === "N") {
      results = polls.sort(this.sortByNew);
    } else if (this.selectedSortType.code === "C") {
      results = polls.sort(this.sortByControversial);
    }
    this.resultsEvent.emit(results);

  }

  sortByLeastVotes(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      pollA = aPollDoc.get('choices') ? aPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollB = bPollDoc.get('choices') ? bPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
    } else {
      pollA = a.data().choices ? a.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollB = b.data().choices ? b.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByMostVotes(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      pollA = aPollDoc.get('choices') ? aPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollB = bPollDoc.get('choices') ? bPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
    } else {
      pollA = a.data().choices ? a.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollB = b.data().choices ? b.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  sortByControversial(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      const aVotes = aPollDoc.get('choices') ? aPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      const bVotes = bPollDoc.get('choices') ? bPollDoc.get('choices').map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollA = aVotes === 0 ? 0.5 : Math.abs(Math.abs(aPollDoc.get('choices')[0].votes/aVotes)-0.5);
      pollB = bVotes === 0 ? 0.5 : Math.abs(Math.abs(bPollDoc.get('choices')[0].votes/bVotes)-0.5);
    } else {
      const aVotes = a.data().choices ? a.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      const bVotes = b.data().choices ? b.data().choices.map(choice => choice.votes).reduce((partial_sum, a) => partial_sum + a,0) : 0;
      pollA = aVotes === 0 ? 0.5 : Math.abs(Math.abs(a.data().choices[0].votes/aVotes)-0.5);
      pollB = bVotes === 0 ? 0.5 : Math.abs(Math.abs(b.data().choices[0].votes/bVotes)-0.5);
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByMostComments(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      pollA = aPollDoc.get('commentTotal')
      pollB = bPollDoc.get('commentTotal')
    } else {
      pollA = a.data().commentTotal;
      pollB = b.data().commentTotal;
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  sortByLeastComments(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      pollA = aPollDoc.get('commentTotal')
      pollB = bPollDoc.get('commentTotal')
    } else {
      pollA = a.data().commentTotal;
      pollB = b.data().commentTotal;
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison;
  }

  sortByNew(a, b) {
    let pollA;
    let pollB;

    if (a.hasOwnProperty('payload')) {
      let aPollDoc = a.payload.hasOwnProperty('doc') ? a.payload.doc : a.payload;
      let bPollDoc = b.payload.hasOwnProperty('doc') ? b.payload.doc : b.payload;
      pollA = aPollDoc.get('createdAt')
      pollB = bPollDoc.get('createdAt')
    } else {
      pollA = a.data().createdAt;
      pollB = b.data().createdAt;
    }

    let comparison = 0;
    if (pollA > pollB) {
      comparison = 1;
    } else if (pollA < pollB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  queryByMostVotes() {
    let results = this.preFilterPolls.sort(poll => {
      let choices;
      if (poll.hasOwnProperty('payload')) {
        let pollDoc = poll.payload.hasOwnProperty('doc') ? poll.payload.doc : poll.payload;
        choices = pollDoc.get('choices');
      } else {
        choices = poll.data().choices;
      }
      if (choices) {
        let votes = choices.map(choice => choice.votes)
      }
    });
  }

  showCreateModal() {
    this.displayCreateModal = true;
    const currentUser = this.afAuth.auth.currentUser
    if (!currentUser || currentUser.isAnonymous) {
      this.displayCreateModal = false;
      this.displayAccountModal = true;
    } else {
      this.firebaseService.getUser(this.afAuth.auth.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.points = user.payload.get('points')
        // Must have required points to post a question
        if (this.points < this.pointsRequired) {
          this.displayCreateModal = false;
          this.displayPointsModal = true;
        } else {
          this.pollForm.showCreate();
        }
      });
    }
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  promptLogin() {
    this.displayAccountModal = true;
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  toggleTags() {
    this.showTagFilter = !this.showTagFilter;
  }

  toggleType() {
    this.showTypeFilter = !this.showTypeFilter;
  }

  updateLocationState() {
    this.page = this.page ? this.page : 1;
    const tags = this.selectedPollTags.map(tag => tag.code);
    const types = this.selectedPollTypes.map(type => type.code);
    let state = `/search?page=${this.page}`;
    tags && tags.length > 0 ? state += `&tags=${tags.join(',')}` : '';
    types && types.length > 0 ? state += `&types=${types.join(',')}` : '';
    this.selectedSortType ? state += `&sortBy=${this.selectedSortType.code}` : '';
    this.location.replaceState(state);
  }

}

import { Component, OnInit } from '@angular/core';
import { FirebaseService } from "../services/firebase.service";
import { NavbarService } from '../services/navbar.service';
import { TableModule } from 'primeng/table';
import { AppConfig } from '../../app-config';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import * as firebase from 'firebase';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {
  users:any;
  loading:boolean = true;
  currentWeek:any;
  currentYear:any = environment.currentYear;
  accuracyVotesThreshold:any = environment.accuracyVotesThreshold;
  leaderboardOptions:any;
  selectedLeaderboard:any;
  weeks:any;
  selectedWeek:any;
  accuracyLeaderboardVisible:boolean = false;

  constructor(private http: HttpClient, private firebaseService: FirebaseService, public nav: NavbarService, private _config: AppConfig) { }

  ngOnInit() {
    this.nav.show();
    this.currentWeek = this._config.WEEK;
    let weekList = Array.from({length: this.currentWeek}, (x,i) => i);
    weekList.shift()
    this.weeks = weekList.map(week => {
      return { label: `Week ${week}`, value: week }
    });
    this.weeks.unshift({label: 'Total Season', value: 0});
    this.leaderboardOptions = [
      {label: 'Credibility', value:0},
      {label: 'Accuracy', value:1}
    ]
    this.selectedLeaderboard = this.leaderboardOptions[0].value;
    this.showCredibilityLeaderboard()
    this.loading = false;
  }

  compare( a, b ) {
    if ( (a.get("accuracy")[this.currentYear][`week${this.currentWeek}`].accuracy) > (b.get("accuracy")[this.currentYear][`week${this.currentWeek}`].accuracy) ){
      return -1;
    }
    if ( (a.get("accuracy")[this.currentYear][`week${this.currentWeek}`].accuracy) < (b.get("accuracy")[this.currentYear][`week${this.currentWeek}`].accuracy) ){
      return 1;
    }
    return 0;
  }

  correctDividedByTotal(user) {
    if (this.currentWeek === "0") {
      if (user) {
        return  `${user.totalVotes}`
      }
    } else {
      if (user && user.accuracy.hasOwnProperty(this.currentYear) && user.accuracy[this.currentYear]["week"+this.currentWeek]) {
        return `${user.accuracy[this.currentYear]["week"+this.currentWeek].totalVotes}`
      }
    }
  }

  accuracy(user) {
    if (this.currentWeek === "0") {
      if (user) {
        return `${(user.accuracyTotal*100).toFixed(2)}%`
      }
    } else {
      if (user && user.accuracy.hasOwnProperty(this.currentYear) && user.accuracy[this.currentYear]["week"+this.currentWeek] && user.accuracy[this.currentYear]["week"+this.currentWeek].hasOwnProperty("accuracy")) {
        return `${(user.accuracy[this.currentYear]["week"+this.currentWeek].accuracy*100).toFixed(2)}%`
      }
    }
  }

  seasonLongAccuracy(user) {
    if (user && user.accuracyTotal) {
      return `${(user.accuracyTotal*100).toFixed(2)}%`
    }
    return '';
  }

  seasonLongCorrectDividedByTotal(user) {
    let total = 0;
    if (this.weeks && user) {
      const season = this.weeks.forEach((week) => {
        let value = week.value;
        total += user.accuracy.hasOwnProperty(this.currentYear) && user.accuracy[this.currentYear]["week"+value] ? user.accuracy[this.currentYear]["week"+value].totalVotes : 0;
      });
      return `${total}`
    }
    return '';
  }

  changeLeaderboard(type) {
    this.selectedLeaderboard = type.value;
    if (this.selectedLeaderboard === 0) {
      this.showCredibilityLeaderboard();
      this.accuracyLeaderboardVisible = false
    } else {
      this.showAccuracyLeaderboard()
      this.accuracyLeaderboardVisible = true;
    }
  }

  showAccuracyLeaderboard() {
    if (this.currentWeek) {
      this.selectedWeek = this.weeks[this.weeks.length-1].value;
      this.currentWeek = (this.currentWeek-1).toString();
      this.loading = true;
      this.renderAccuracyLeaderboard();
      this.loading = false;
    } else {
      this.users = []
    }
  }

  renderAccuracyLeaderboard() {
    this.loading = true;
    const db = firebase.firestore();
    // Show accuracy leaderboard just for selected week
    let query = db.collection('users').where(`accuracyWeeks.${this.currentYear}.week${this.currentWeek}`, "==", true);
    query.get({source: 'server'}).then(async (documentSnapshots) => {
      let sorted = documentSnapshots.docs.sort( this.compare.bind(this) );
      this.users = sorted.map((user) => {
        let userData = user.data()
        if (userData.createdAt) {
          let d = new Date(0)
          d.setUTCSeconds(userData.createdAt.seconds);
          userData.createdAt = d;
        }
        return { ...userData, uid: user.id }
      });
      // only display users who voted on more than 10 polls this week.
      this.users = this.users.filter((user) => user['accuracy'][this.currentYear]['week'+this.currentWeek].totalVotes >= this.accuracyVotesThreshold)
      this.loading = false;
    });
  }

  showCredibilityLeaderboard() {
    this.loading = true;
    const db = firebase.firestore();
    let query = db.collection('users').orderBy('credibility', 'desc').limit(100);
    query.get({source: 'server'}).then(async (documentSnapshots) => {
      this.users = documentSnapshots.docs.map((user) => {
        let userData = user.data();
        if (userData.createdAt) {
          let d = new Date(0)
          d.setUTCSeconds(userData.createdAt.seconds);
          userData.createdAt = d;
        }
        return { ...userData, uid: user.id }
      });
    });
    this.loading = false;
  }

  changeWeek() {
    this.currentWeek = this.selectedWeek.toString();
    if (this.currentWeek === "0") {
      this.renderSeasonAccuracyLeaderboard()
    } else {
      this.renderAccuracyLeaderboard();
    }
  }

  renderSeasonAccuracyLeaderboard() {
    this.loading = true;
    const db = firebase.firestore();
    let query = db.collection('users').orderBy('accuracyTotal', 'desc');
    query.get({source: 'server'}).then(async (documentSnapshots) => {
      this.users = documentSnapshots.docs.map((user) => {
        let userData = user.data()
        if (userData.createdAt) {
          let d = new Date(0)
          d.setUTCSeconds(userData.createdAt.seconds);
          userData.createdAt = d;
        }
        return { ...userData, uid: user.id }
      });
      // filter out users who have not voted on 10*totalWeeks passed
      this.users = this.users.filter((user) => {
        let weekCount = this.weeks.length-1;
        let totalVotes = this.weeks.map((week) => {
          if (week.value !== 0 && user.accuracy.hasOwnProperty(this.currentYear) && user.accuracy[this.currentYear].hasOwnProperty('week'+week.value)) {
            return user['accuracy'][this.currentYear]['week'+week.value].totalVotes;
          } else {
            return 0;
          }
        });

        let count = totalVotes.reduce((a, b) => a + b, 0);
        if (count >= weekCount*this.accuracyVotesThreshold) {
          user.totalVotes = count;
          return user;
        }
      });
      this.users = this.users.slice(0,100);
      this.loading = false;
    });
  }

  numberWithCommas(x) {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}

import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from '../services/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { NavbarService } from '../services/navbar.service';
import { SidebarModule } from 'primeng/sidebar';
import { Router, NavigationStart } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { AppConfig } from '../../app-config';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as firebase from 'firebase';

declare var FS:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  displayUserDetails:boolean = false;
  user:any = {email: '', uid: '', points: 0, credibility: 0};
  username:string;
  sport:string = "";
  path:string = "";
  displayAccountModal:boolean = false;
  displayPointsModal:boolean = false;
  description:string;
  notificationList:any = [];
  msgs:any[] = [];
  badgeSrc:string;
  currentUser:any;
  newUser:boolean = false;
  currentYear:any = environment.currentYear;
  accuracyVotesThreshold:any = environment.accuracyVotesThreshold;

  constructor(
    private messageService: MessageService,
    public afAuth: AngularFireAuth,
    private auth: AuthService,
    private firebaseService: FirebaseService,
    private trackingService: TrackingService,
    private router: Router,
    public nav: NavbarService,
    private _config: AppConfig,
  ) { }

  ngOnInit() {

    let pathArray;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        pathArray = event.url.split("/");
        if (!pathArray[1] && this.afAuth.auth.currentUser) {
          let menu = document.getElementById("menu");
          if (menu) {
            menu.style.display = "";
          }
          this.router.navigate(['/feed/football']);
        }
        if (pathArray.length >= 2) {
          this.path = pathArray[1].toUpperCase();
          // this.sport = pathArray[2].toUpperCase();
        }
        if (pathArray[1] == "poll") {
          this.path = "DETAILS";
        }
        if (pathArray[1].includes("search")) {
          this.path = "SEARCH";
        }
        if (pathArray[1] && pathArray[1][0] == "?") {
          this.path = "";
        }

      }
    })

    this.afAuth.authState.subscribe((user:any) => {
      this.currentUser = user;
      if (user) {
        // Don't show banner if you're on the homepage
        if (!pathArray[1] && user.isAnonymous) {
          return;
        }
        if (user.isAnonymous) {
          // this.showMsg();
        } else {
          this.hide();
          if (!pathArray[1]) {
            this.router.navigate(['/feed/football']);
          }
          let menu = document.getElementById("menu")
          if (menu) {
            menu.style.display = "";
          }

          this.firebaseService.lastLoginTimestamp(this.currentUser.uid);
          this.firebaseService.hasVisitedToday().then((hasVisitedToday) => {
            if (!hasVisitedToday) {
              this.firebaseService.createVisit();
              // Sync stats for credibility score;
              // ToDo: At some point in the future move this to login function instead.
              (async () => {
                const db = firebase.firestore();
                const uid = user.uid;

                const commentsSnapshot = await db.collection('comments')
                .where('uid', '==', uid)
                .get({source: 'server'});
                const totalComments = commentsSnapshot.docs.length;

                const votesSnapshot = await db.collection('votes')
                .where('uid', '==', uid)
                .get({source: 'server'});
                const totalVotes = votesSnapshot.docs.length;

                const visitsSnapshot = await db.collection('visits')
                .where('uid', '==', uid)
                .get({source: 'server'});
                const totalVisits = visitsSnapshot.docs.length;

                const pollsSnapshot = await db.collection('polls')
                .where('uid', '==', uid)
                .get({source: 'server'});
                const totalPolls = pollsSnapshot.docs.length;

                const userDoc = db.doc(`users/${uid}`);
                userDoc.update({
                  comments: totalComments,
                  votes: totalVotes,
                  visits: totalVisits,
                  polls: totalPolls
                });

              })();
            }
          });
          const uid = user.uid
          this.firebaseService.getUser(uid).pipe(first()).subscribe((user:any) => {
            if (user && user.payload.exists) {
              const userDetails = user.payload.data();
              this.user.uid = uid;
              this.user.email = userDetails.hasOwnProperty('email') ? userDetails.email : '';
              this.user.points = userDetails.hasOwnProperty('points') ? userDetails.points : 0;
              this.user.credibility = userDetails.hasOwnProperty('credibility') ? userDetails.credibility : 0;
              this.username = userDetails.hasOwnProperty('username') ? userDetails.username : '';

              // Determine if accuracy flair should be awarded to the user.
              let totalVotes = 0;
              let canHaveAccuracyFlair;
              const accuracy = userDetails.hasOwnProperty('accuracy') ? userDetails.accuracy : {};
              if (accuracy.hasOwnProperty(this.currentYear)) {
                const accuracyWeeks = Object.values(accuracy[this.currentYear]);
                accuracyWeeks.forEach((accuracyWeek:any) => {
                  if (accuracyWeek.hasOwnProperty("totalVotes")) {
                    totalVotes+=accuracyWeek.totalVotes;
                  }
                });
              }
              let currentWeek = this._config.WEEK;
              // ToDo: Re-add this when the 2020 season is over.
              // currentWeek = currentWeek === 1 || currentWeek === undefined ? 17 : currentWeek;
              if (totalVotes >= currentWeek*this.accuracyVotesThreshold) {
                canHaveAccuracyFlair = true;
              } else {
                canHaveAccuracyFlair = false;
              }
              this.firebaseService.updateUser(this.user.uid, {canHaveAccuracyFlair: canHaveAccuracyFlair})
              // End

              this.setBadgeSrc();
              // ToDo: fix permissions errors with this function on logout.
              this.showNotificationCount()

              // Fullstory tracking
              if (!user.isAnonymous) {
                FS.identify(user.uid, {
                  displayName: this.username,
                  email: this.user.email,
                });
                this.trackingService.identify(this.user.email);
              }
            }
          });
        }
      } else {
        this.username = null;
        this.auth.signInAnonymously();
      }
    });
  }

  showMsg() {
    this.msgs = [];
    this.msgs.push({severity:'warn', summary:"You're browsing anonymously. ", detail:'Your votes will not be scored for accuracy until you login.'});
  }

  hide() {
    this.msgs = [];
  }


  dismissNotification(event) {
    this.firebaseService.dismissNotification({notificationId: event.message.id, uid: this.user.uid}).then(() => {
      this.notificationList = this.notificationList.filter((notification) => notification.id !== event.message.id);
    });
  }

  showNotifications() {
    this.notificationList.forEach(notification => this.messageService.add(notification));
    this.notificationList = [];
  }

  logout() {
    this.auth.logout();
    this.displayUserDetails = false;
  }

  toggleUserDetails() {
    this.displayUserDetails = true;
  }

  displaySignIn() {
    this.displayAccountModal = true;
    this.newUser = false;
  }

  displaySignUp() {
    this.displayAccountModal = true;
    this.newUser = true;
  }

  hideMobileMenu() {
    this.displayUserDetails = false;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  goHome() {
    this.router.navigateByUrl('/feed/football');
  }

  navigateToPoll(event, message) {
    this.dismissNotification({message: message});
    this.messageService.clear();
    // this.showNotificationCount();
    let splitPath = window.location.pathname.split("/");
    let pollId = splitPath ? splitPath[splitPath.length-1] : "";
    if (message.poll !== pollId) {
      this.router.navigate(['/poll', message.sport, message.poll]);
    }
  }

  showNotificationCount() {
    if (this.currentUser) {
      const uid = this.currentUser.uid
      this.firebaseService.getNotifications().subscribe(notifications => {
        this.firebaseService.getUserNotifications(uid).subscribe(userNotifications => {
          [...notifications, ...userNotifications].forEach(notification => {
            const item = notification.payload.doc
            const message = item.get("message");
            const poll = item.get("poll");
            const sport = item.get("sport");
            this.firebaseService.getNotificationUser(`${item.id}_${uid}`).pipe(first()).subscribe(notification_user => {
              if (!notification_user.payload.exists) {
                const createdAt = item.get("createdAt");
                let newNotification = {
                  severity: "success",
                  detail: message,
                  life: 300000,
                  id: item.id,
                  poll: poll,
                  sport: sport,
                  createdAt: createdAt ? new Date(0).setUTCSeconds(createdAt.seconds) : ''
                }
                var notificationIndex = this.notificationList.findIndex(i => i.id==item.id);
                if (notificationIndex === -1) {
                  this.notificationList.push(newNotification)
                }
              }
            });
          })
        })
      });
    }
  }

  setBadgeSrc() {
    if (this.user) {
      const credibility = this.user.credibility;
      if (credibility < 1000) {
        this.badgeSrc = "/assets/badges/no-text/badge-01.svg"
      } else if (credibility >= 1000 && credibility < 2500) {
        this.badgeSrc = "/assets/badges/no-text/badge-02.svg"
      } else if (credibility >= 2500 && credibility < 5000) {
        this.badgeSrc = "/assets/badges/no-text/badge-03.svg"
      } else if (credibility >= 5000 && credibility < 10000) {
        this.badgeSrc = "/assets/badges/no-text/badge-04.svg"
      } else if (credibility >= 10000 && credibility < 25000) {
        this.badgeSrc = "/assets/badges/no-text/badge-05.svg"
      } else if (credibility >= 25000 && credibility < 50000) {
        this.badgeSrc = "/assets/badges/no-text/badge-06.svg"
      } else if (credibility >= 50000) {
        this.badgeSrc = "/assets/badges/no-text/badge-07.svg"
      }
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  displayPointsModalEvent() {
    this.displayPointsModal = true;
  }

  showCoinsWiki() {
    this.displayPointsModalEvent();
    this.trackingService.track('Open Coins Modal', {
      uid: this.user.uid,
      email: this.user.email,
      username: this.username,
      points: this.user.points,
      credibility: this.user.credibility
    });
  }

  trackHeapProfileEvent() {
    this.trackingService.track('Visit Profile', {
      uid: this.user.uid,
      email: this.user.email,
      username: this.username,
      points: this.user.points,
      credibility: this.user.credibility
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { NavbarService } from '../services/navbar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AppConfig } from '../../app-config';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import * as firebase from 'firebase';

@Component({
  selector: 'app-accuracy',
  templateUrl: './accuracy.component.html',
  styleUrls: ['./accuracy.component.scss']
})
export class AccuracyComponent implements OnInit {
  currentUser:any;
  currentEmail:string;
  currentUsername:string;
  loading:boolean = true;
  selectedWeek:any;
  weeks:any;
  totalVoteIds:any;
  accuracy:any;
  polls:any;
  p:any;
  currentWeek:any;
  selectedWeekNumber:any;
  currentYear:any = environment.currentYear;

  constructor(
    private firebaseService: FirebaseService,
    public nav: NavbarService,
    public afAuth: AngularFireAuth,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.nav.show();
    this.afAuth.authState.subscribe((user) => {
      setTimeout(() => {
        this.currentWeek = this._config.WEEK
        let weekList = Array.from({length: this.currentWeek}, (x,i) => i);
        weekList.shift()
        this.weeks = weekList.map(week => {
          return { label: `Week ${week}`, value: week }
        });
        this.selectedWeek = this.weeks[this.weeks.length-1] ? this.weeks[this.weeks.length-1].value : 1;
        this.selectedWeekNumber = (this.selectedWeek).toString()
        this.currentWeek = this.currentWeek.toString()
        this.currentUser = user;
        this.getPolls();
      }, 500)
    });
  }

  changeWeek() {
    this.selectedWeekNumber = this.selectedWeek.toString();
    this.getPolls();
  }

  getPolls() {
    if (this.currentUser) {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
        this.currentEmail = user.payload.get('email') ? user.payload.get('email') : '';
        let accuracy = user.payload.get('accuracy') ? user.payload.get('accuracy') : {};
        this.accuracy = accuracy.hasOwnProperty(this.currentYear) && accuracy[this.currentYear]['week'+this.selectedWeekNumber] ? accuracy[this.currentYear]['week'+this.selectedWeekNumber]['accuracy'] : 0;
        this.totalVoteIds = accuracy.hasOwnProperty(this.currentYear) && accuracy[this.currentYear]['week'+this.selectedWeekNumber] ? accuracy[this.currentYear]['week'+this.selectedWeekNumber]['totalVoteIds'] : [];
        if (this.totalVoteIds && this.totalVoteIds.length > 0) {
          this.firebaseService.getPollsById(this.totalVoteIds).then((polls:any) => {
            this.polls = polls;
            this.loading = false;
          });
        } else {
          this.polls = [];
          this.loading = false;
        }
      });
    } else {
      this.polls = [];
      this.loading = false;
    }
  }

}

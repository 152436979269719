import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { TableModule } from 'primeng/table';
import { AppConfig } from '../../app-config';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  polls:any;
  loading:boolean;
  cols:any = [];
  singleIds:any = [];

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.getSinglePolls();
  }

  getSinglePolls() {
    this.loading = true;
    this.singleIds = [];
    this.cols = [
        { field: 'createdAtFormatted', header: 'Poll Date', class: 'small' },
        { field: 'expired', header: 'Expired', class: 'small' },
        { field: 'player1', header: 'P1', class: '' },
        { field: 'player1Id', header: 'P1 ID', class: 'small' },
        { field: 'player1Pos', header: 'P1 Pos', class: 'small' },
        { field: 'player1Votes', header: 'P1 Votes', class: 'small' },
        { field: 'player2', header: 'P2', class: '' },
        { field: 'player2Id', header: 'P2 ID', class: 'small' },
        { field: 'player2Pos', header: 'P2 Pos', class: 'small' },
        { field: 'player2Votes', header: 'P2 Votes', class: 'small' },
        { field: 'winner', header: 'Winner', class: '' },
        { field: 'url', header: 'URL', class: '' },
        { field: 'dynasty', header: 'Dynasty', class: 'small' },
        { field: 'keeper', header: 'Keeper', class: 'small' },
        { field: 'redraft', header: 'Redraft', class: 'small' },
        { field: 'standard', header: 'Standard', class: 'small' },
        { field: 'halfppr', header: '0.5 PPR', class: 'small' },
        { field: 'ppr', header: 'PPR', class: 'small' },
        { field: 'pass4', header: '4pt Pass', class: 'small' },
        { field: 'pass5', header: '5pt Pass', class: 'small' },
        { field: 'pass6', header: '6pt Pass', class: 'small' },
        { field: 'superflex', header: 'Superflex/2QB', class: 'small' },
        { field: 'tepremium', header: 'TE Premium/2TE', class: 'small' },
        { field: 'ppc', header: 'PPC', class: 'small' }
    ];
    this.afAuth.authState.subscribe((user) => {
      const allowedUsers = ["42taQP7b3ffiKKPG0W0nvfUtP183", "Wez0HtM8lsbImfrgufKfTaUb4AI3", "TuK223ZDk9XtTfAJWW1eL85KHjg1", "qGT5UdUxoKPj0FddR8QQB3F1zzf1", "qvbhuztCgTTV69EfT1q0O9j3hTJ3"];
      if (allowedUsers.includes(user.uid)) {
        this.firebaseService.getPolls({type: 'WWTT', thisSeason: true}).then((response:any) => {
          this.polls = response.polls.filter((poll) => {
        const pollData = poll.data();
        if (pollData.choices.length === 2) {
          if (pollData.choices[0].players.length === 1 && pollData.choices[1].players.length === 1) {
            return poll;
          }
        }
          }).map((poll) => {
            const pollData = poll.data();

            let d = new Date(0)
            d.setUTCSeconds(pollData.createdAt.seconds);
            const createdAtFormatted = d;

            const player1 = pollData.choices[0].players[0].displayName;
            const player1Id = pollData.choices[0].players[0].playerId;
            const player1Pos = pollData.choices[0].players[0].position;
            const player1Votes = pollData.choices[0].votes;
            const player2 = pollData.choices[1].players[0].displayName;
            const player2Id = pollData.choices[1].players[0].playerId;
            const player2Pos = pollData.choices[1].players[0].position;
            const player2Votes = pollData.choices[1].votes;
            const expired = pollData.expired;
            const url = `https://pollsports.com/poll/football/${poll.id}`
            let winner = player1Votes > player2Votes ? player1 : player2;
            winner = player1Votes === player2Votes ? "Tie" : winner;

            let dynasty, keeper, redraft, standard, halfppr, ppr, pass4, pass5, pass6, superflex, tepremium, ppc;
            dynasty = keeper = redraft = standard = halfppr = ppr = pass4 = pass5 = pass6 = superflex = tepremium = ppc = 0;


            pollData.tags.forEach((tag) => {
              if (tag.code === "DYNASTY") {
                dynasty = 1;
              }
              if (tag.code === "KEEPER") {
                keeper = 1;
              }
              if (tag.code === "REDRAFT") {
                redraft = 1;
              }
              if (tag.code === "STANDARD") {
                standard = 1;
              }
              if (tag.code === "0.5PPR") {
                halfppr = 1;
              }
              if (tag.code === "PPR") {
                ppr = 1;
              }
              if (tag.code === "4PTPASS") {
                pass4 = 1;
              }
              if (tag.code === "5PTPASS") {
                pass5 = 1;
              }
              if (tag.code === "6PTPASS") {
                pass6 = 1;
              }
              if (tag.code === "2QB" || tag.code === "SUPERFLEX") {
                superflex = 1;
              }
              if (tag.code === "2TE" || tag.code === "TEPREMIUM") {
                tepremium = 1;
              }
              if (tag.code === "0.10PPC" || tag.code === "0.25PPC" || tag.code === "0.50PPC") {
                ppc = 1;
              }
            });

            this.singleIds.push(poll.id);
            return {...pollData, id: poll.id, player1, player1Id, player1Pos, player1Votes, player2, player2Id, player2Pos, player2Votes, winner, createdAtFormatted, expired, url, dynasty, keeper, redraft, standard, halfppr, ppr, pass4, pass5, pass6, superflex, tepremium, ppc };
          });
        this.loading = false;
      });
      } else {

      }
    });
  }

  getDoublePolls() {
    this.loading = true;
    this.cols = [
        { field: 'createdAtFormatted', header: 'Poll Date', class: 'small' },
        { field: 'expired', header: 'Expired', class: 'small' },

        { field: 'op1Player1', header: 'Option 1 P1', class: '' },
        { field: 'op1Player1Id', header: 'Option 1 P1 ID', class: 'small' },
        { field: 'op1Player1Pos', header: 'Option 1 P1 Pos', class: 'small' },
        { field: 'op1Player2', header: 'Option 1 P2', class: '' },
        { field: 'op1Player2Id', header: 'Option 1 P2 ID', class: 'small' },
        { field: 'op1Player2Pos', header: 'Option 1 P2 Pos', class: 'small' },
        { field: 'op1Votes', header: 'Option 1 Votes', class: 'small' },

        { field: 'op2Player1', header: 'Option 2 P1', class: '' },
        { field: 'op2Player1Id', header: 'Option 2 P1 ID', class: 'small' },
        { field: 'op2Player1Pos', header: 'Option 2 P1 Pos', class: 'small' },
        { field: 'op2Player2', header: 'Option 2 P2', class: '' },
        { field: 'op2Player2Id', header: 'Option 2 P2 ID', class: 'small' },
        { field: 'op2Player2Pos', header: 'Option 2 P2 Pos', class: 'small' },
        { field: 'op2Votes', header: 'Option 2 Votes', class: 'small' },

        { field: 'winner', header: 'Winner', class: '' },
        { field: 'url', header: 'URL', class: '' },

        { field: 'dynasty', header: 'Dynasty', class: 'small' },
        { field: 'keeper', header: 'Keeper', class: 'small' },
        { field: 'redraft', header: 'Redraft', class: 'small' },
        { field: 'standard', header: 'Standard', class: 'small' },
        { field: 'halfppr', header: '0.5 PPR', class: 'small' },
        { field: 'ppr', header: 'PPR', class: 'small' },
        { field: 'pass4', header: '4pt Pass', class: 'small' },
        { field: 'pass5', header: '5pt Pass', class: 'small' },
        { field: 'pass6', header: '6pt Pass', class: 'small' },
        { field: 'superflex', header: 'Superflex/2QB', class: 'small' },
        { field: 'tepremium', header: 'TE Premium/2TE', class: 'small' },
        { field: 'ppc', header: 'PPC', class: 'small' }
    ];

    this.afAuth.authState.subscribe((user) => {
      const allowedUsers = ["Wez0HtM8lsbImfrgufKfTaUb4AI3", "TuK223ZDk9XtTfAJWW1eL85KHjg1", "qGT5UdUxoKPj0FddR8QQB3F1zzf1"];
      if (allowedUsers.includes(user.uid)) {
        this.firebaseService.getPolls({type: 'WWTT', thisSeason: true}).then((response:any) => {
          this.polls = response.polls.filter((poll) => {
            const pollData = poll.data();
            if (pollData.choices.length === 2 && !this.singleIds.includes(poll.id)) {
              if (pollData.choices[0].players.length <= 2 && pollData.choices[1].players.length <= 2) {
                return poll;
              }
            }
          })
          .map((poll) => {
            const pollData = poll.data();

            let d = new Date(0)
            d.setUTCSeconds(pollData.createdAt.seconds);
            const createdAtFormatted = d;

            const op1Player1 = pollData.choices[0].players[0].displayName;
            const op1Player1Id = pollData.choices[0].players[0].playerId;
            const op1Player1Pos = pollData.choices[0].players[0].position;
            const op1Player2 = pollData.choices[0].players[1] && pollData.choices[0].players[1].displayName;
            const op1Player2Id = pollData.choices[0].players[1] && pollData.choices[0].players[1].playerId;
            const op1Player2Pos = pollData.choices[0].players[1] && pollData.choices[0].players[1].position;
            const op1Players = pollData.choices[0].players.map(player => player.displayName).join(" ");
            const op1Votes = pollData.choices[0].votes;

            const op2Player1 = pollData.choices[1].players[0].displayName;
            const op2Player1Id = pollData.choices[1].players[0].playerId;
            const op2Player1Pos = pollData.choices[1].players[0].position;
            const op2Player2 = pollData.choices[1].players[1] && pollData.choices[1].players[1].displayName;
            const op2Player2Id = pollData.choices[1].players[1] && pollData.choices[1].players[1].playerId;
            const op2Player2Pos = pollData.choices[1].players[1] && pollData.choices[1].players[1].position;
            const op2Players = pollData.choices[1].players.map(player => player.displayName).join(" ");
            const op2Votes = pollData.choices[1].votes;

            const expired = pollData.expired;
            const url = `https://pollsports.com/poll/football/${poll.id}`
            let winner = op1Votes > op2Votes ? op1Players : op2Players;
            winner = op1Votes === op2Votes ? "Tie" : winner;

            let dynasty, keeper, redraft, standard, halfppr, ppr, pass4, pass5, pass6, superflex, tepremium, ppc;
            dynasty = keeper = redraft = standard = halfppr = ppr = pass4 = pass5 = pass6 = superflex = tepremium = ppc = 0;


            pollData.tags.forEach((tag) => {
              if (tag.code === "DYNASTY") {
                dynasty = 1;
              }
              if (tag.code === "KEEPER") {
                keeper = 1;
              }
              if (tag.code === "REDRAFT") {
                redraft = 1;
              }
              if (tag.code === "STANDARD") {
                standard = 1;
              }
              if (tag.code === "0.5PPR") {
                halfppr = 1;
              }
              if (tag.code === "PPR") {
                ppr = 1;
              }
              if (tag.code === "4PTPASS") {
                pass4 = 1;
              }
              if (tag.code === "5PTPASS") {
                pass5 = 1;
              }
              if (tag.code === "6PTPASS") {
                pass6 = 1;
              }
              if (tag.code === "2QB" || tag.code === "SUPERFLEX") {
                superflex = 1;
              }
              if (tag.code === "2TE" || tag.code === "TEPREMIUM") {
                tepremium = 1;
              }
              if (tag.code === "0.10PPC" || tag.code === "0.25PPC" || tag.code === "0.50PPC") {
                ppc = 1;
              }
            });

            return {...pollData, id: poll.id, op1Player1, op1Player1Id, op1Player1Pos, op1Player2, op1Player2Id, op1Player2Pos, op1Votes, op2Player1, op2Player1Id, op2Player1Pos, op2Player2, op2Player2Id, op2Player2Pos, op2Votes, winner, createdAtFormatted, expired, url, dynasty, keeper, redraft, standard, halfppr, ppr, pass4, pass5, pass6, superflex, tepremium, ppc };
          });


        this.loading = false;
      });
      } else {

      }
    });
  }

  getPlayerIds() {
    this.loading = true;
    this.cols = [
        { field: 'playerId', header: 'Player ID', class: 'small' },
        { field: 'displayName', header: 'Name' },
        { field: 'team', header: 'Team', class: 'small' },
        { field: 'position', header: 'Position', class: 'small' }
    ];

    this.afAuth.authState.subscribe((user) => {
      const allowedUsers = ["Wez0HtM8lsbImfrgufKfTaUb4AI3", "TuK223ZDk9XtTfAJWW1eL85KHjg1", "qGT5UdUxoKPj0FddR8QQB3F1zzf1"];
      if (allowedUsers.includes(user.uid)) {
        this.polls = this._config.PLAYERS.football;
      }
      this.loading = false;
    });
  }

  // Leaving this here incase it needs to be an Excel export in the future
  // exportExcel() {
  //   import("xlsx").then(xlsx => {
  //       const worksheet = xlsx.utils.json_to_sheet(this.polls);
  //       const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
  //       const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
  //       this.saveAsExcelFile(excelBuffer, "pollSportsTradeData");
  //   });
  // }
  //
  // saveAsExcelFile(buffer: any, fileName: string): void {
  //   import("file-saver").then(FileSaver => {
  //       let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  //       let EXCEL_EXTENSION = '.xlsx';
  //       const data: Blob = new Blob([buffer], {
  //           type: EXCEL_TYPE
  //       });
  //       FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  //   });
  // }

}

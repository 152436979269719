import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { ActivatedRoute } from '@angular/router';
import { MessagingService } from '../services/messaging.service';
import { NavbarService } from '../services/navbar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { SeoService } from '../services/seo.service';
import { InputTextModule } from 'primeng/inputtext';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConfirmationService } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';
import { Router } from '@angular/router';
import { first } from "rxjs/operators";
import { PollFormComponent } from '../poll-form/poll-form.component';
import { AppConfig } from '../../app-config';
import * as firebase from 'firebase';
import tags from '../../assets/types/tags.json';
declare var heap:any;


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  value:any = 0;
  user:any;
  lastLogin:any;
  createdAt:any;
  levelUp:number;
  levelUpBadge:string;
  notificationsSupported:boolean = false;
  allowNotifications:boolean = false;
  currentUser:any;
  currentUsername:string;
  currentEmail:string;
  credibility:number;
  accuracyTotal:any;
  profileUid:string;
  levelUpBadgeSrc:string;
  tags:any = tags;
  leagues:any = [[]];
  ownsProfile:boolean = false;
  leaguesSaved:boolean = false;
  scoringTypes:any;
  awardedComments:any;
  polls:any = [];
  isInfluencer:boolean = false;
  displayCreateModal:boolean = false;
  displayAccountModal:boolean = false;
  points:number;
  displayPointsModal:boolean = false;
  pointsRequired:number = environment.points.required;
  pointsEarned:number = environment.points.earned;
  feedOptions:any;
  selectedFeed:any;
  weeks:any;
  avatar:string;
  currentYear:any = environment.currentYear;
  accuracyVotesThreshold:any = environment.accuracyVotesThreshold;
  correctTotal:any = "0/0";
  awards:any;
  userPercentile:any;
  currentBadge:any;
  canHaveAccuracyFlair:boolean = false;
  expandGoldAccuracy:boolean = false;
  expandSilverAccuracy:boolean = false;
  expandBronzeAccuracy:boolean = false;
  expandPercentileAccuracy:boolean = false;
  expandCommentAwards:boolean = false;
  expandMostCommentsAwards:boolean = false;
  expandMostVotesAwards:boolean = false;
  hasCommentsAwards:boolean = false;
  hasMostCommentsAwards:boolean = false;
  hasMostVotesAwards:boolean = false;
  showCredibilityTooltip:boolean = false;
  currentWeek:any;

  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  constructor(
    private firebaseService: FirebaseService,
    private route: ActivatedRoute,
    public msg: MessagingService,
    private afAuth: AngularFireAuth,
    private seo: SeoService,
    private confirmationService: ConfirmationService,
    public nav: NavbarService,
    private http: HttpClient,
    private router: Router,
    private _config: AppConfig
  ) {}

  ngOnInit() {
    this.nav.show();
    this.currentWeek = this._config.WEEK;
    let weekList = Array.from({length: this.currentWeek}, (x,i) => i);
    weekList.shift()
    this.weeks = weekList.map(week => {
      return { label: `Week ${week}`, value: (week).toString() }
    });
    this.scoringTypes = this.tags["football"]
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
          this.currentEmail = user.payload.get('email') ? user.payload.get('email') : '';
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
          heap.addUserProperties({
            'Credibility': this.credibility,
            'Accuracy': this.accuracyTotal,
            'username': this.currentUsername
          });
        });
      }
      this.firebaseService.getLeagues(this.currentUser.uid).pipe(first()).subscribe(leagues => {
        if (leagues.length === 0) {
          this.leagues = [[]];
        } else {
          this.leagues = leagues.map(league => {
            let mappedLeague = { id: league.payload.doc.id, ...league.payload.doc.data() }
            return mappedLeague;
          });
        }

      });
    });
    if (firebase.messaging.isSupported()) {
      this.notificationsSupported = true;
      firebase.messaging().getToken().then(token => {
        this.allowNotifications = token ? true : false;
      });
    }
    this.route.params.subscribe(params => {
      this.profileUid = params.id.replace(/ +/g, "");
      this.firebaseService.getUserBySlug(this.profileUid).pipe(first()).subscribe(response => {
        let user = response[0];
        if (!user) { return; }
        let uid = user.payload.doc.id
        this.user = user.payload.doc.data();

        this.awards = {
          percentile: [],
          accuracy: {
            gold: [],
            silver: [],
            bronze: []
          },
          votes: {
            gold: [],
            silver: [],
            bronze: []
          },
          comments: {
            gold: [],
            silver: [],
            bronze: []
          },
          commentAwards: {
            gold: [],
            silver: [],
            bronze: []
          }
        }

        const years = ["2020", "2019"];
        const weeks = Array.from({length: 16}, (x,i) => i+1).reverse();

        const accuracyTotal = this.user.accuracyTotal;
        const accuracy_range = this._config.ACCURACY_RANGE ? this._config.ACCURACY_RANGE : []
        let percentile = accuracyTotal && accuracy_range.length ? 1-(this.percentRank(accuracy_range, accuracyTotal)) : 100;
        // Make sure that people with 0 accuracy aren't treated as top 1%
        percentile = accuracyTotal === 0 || percentile === 0 ? 100 : percentile;
        if (percentile >= 0 && percentile <= .01) {
          this.userPercentile = "1%"
        } else if (percentile > .01 && percentile <= .05) {
          this.userPercentile = "5%"
        } else if (percentile > .05 && percentile <= .10) {
          this.userPercentile = "10%"
        } else if (percentile > .10 && percentile <= .15) {
          this.userPercentile = "15%"
        } else if (percentile > .15 && percentile <= .20) {
          this.userPercentile = "20%"
        } else if (percentile > .20 && percentile <= .25) {
          this.userPercentile = "25%"
        }


        years.forEach((year) => {
          weeks.forEach((week) => {
            const yearData = this.user.accuracy[year];
            if (yearData) {
              const weekData = this.user.accuracy[year]["week"+week];
              // Saves percentiles
              if (weekData) {
                const percentile = weekData.percentile // returns decimal ex .01
                if (weekData.totalVotes >= this.accuracyVotesThreshold && (percentile > 0 && percentile <= .01)) {
                  this.awards.percentile.push({year: year, week: week, percentile: "1%", value: percentile})
                } else if (percentile > .01 && percentile <= .05) {
                  this.awards.percentile.push({year: year, week: week, percentile: "5%", value: percentile})
                } else if (percentile > .05 && percentile <= .10) {
                  this.awards.percentile.push({year: year, week: week, percentile: "10%", value: percentile})
                } else if (percentile > .10 && percentile <= .15) {
                  this.awards.percentile.push({year: year, week: week, percentile: "15%", value: percentile})
                } else if (percentile > .15 && percentile <= .20) {
                  this.awards.percentile.push({year: year, week: week, percentile: "20%", value: percentile})
                } else if (percentile > .20 && percentile <= .25) {
                  this.awards.percentile.push({year: year, week: week, percentile: "25%", value: percentile})
                }

                const trophies = weekData.trophies // returns object of all trophies
                if (trophies) {
                  // Save accuracy trophies
                  if (trophies.accuracy === "gold") {
                    this.awards.accuracy.gold.push({year: year, week: week, accuracy: weekData.accuracy})
                  } else if (trophies.accuracy === "silver") {
                    this.awards.accuracy.silver.push({year: year, week: week, accuracy: weekData.accuracy})
                  } else if (trophies.accuracy === "bronze") {
                    this.awards.accuracy.bronze.push({year: year, week: week, accuracy: weekData.accuracy})
                  }

                  // Save contest trophies
                  if (trophies.contests) {
                    for (const trophy in trophies.contests) {
                      const contest = trophies.contests[trophy]
                      if (contest.trophy === "gold") {
                        this.awards.accuracy.gold.push({year: year, week: week, accuracy: contest.accuracy, groupName: contest.groupName})
                      }
                      if (contest.trophy === "silver") {
                        this.awards.accuracy.silver.push({year: year, week: week, accuracy: contest.accuracy, groupName: contest.groupName})
                      }
                      if (contest.trophy === "bronze") {
                        this.awards.accuracy.bronze.push({year: year, week: week, accuracy: contest.accuracy, groupName: contest.groupName})
                      }
                    }
                  }

                  // Save votes trophies
                  const votesTrophy = trophies.votes
                  if (votesTrophy === "gold") {
                    this.awards.votes.gold.push({ year: year, week: week, votes: weekData.totalTrophyVotes })
                  } else if (votesTrophy === "silver") {
                    this.awards.votes.silver.push({ year: year, week: week, votes: weekData.totalTrophyVotes })
                  } else if (votesTrophy === "bronze") {
                    this.awards.votes.bronze.push({ year: year, week: week, votes: weekData.totalTrophyVotes })
                  }

                  // Save comment trophies
                  const commentsTrophy = trophies.comments
                  if (commentsTrophy === "gold") {
                    this.awards.comments.gold.push({ year: year, week: week, comments: weekData.comments })
                  } else if (commentsTrophy === "silver") {
                    this.awards.comments.silver.push({ year: year, week: week, comments: weekData.comments })
                  } else if (commentsTrophy === "bronze") {
                    this.awards.comments.bronze.push({ year: year, week: week, comments: weekData.comments })
                  }

                  // Save comment award trophies
                  const commentAwardTrophies = trophies.commentAwards
                  if (commentAwardTrophies === "gold") {
                    this.awards.commentAwards.gold.push({ year: year, week: week, commentAwards: weekData.commentAwards })
                  } else if (commentAwardTrophies === "silver") {
                    this.awards.commentAwards.silver.push({ year: year, week: week, commentAwards: weekData.commentAwards })
                  } else if (commentAwardTrophies === "bronze") {
                    this.awards.commentAwards.bronze.push({ year: year, week: week, commentAwards: weekData.commentAwards })
                  }
                }
              }
            }
          });
        });

        this.hasCommentsAwards = this.awards.commentAwards.gold.length > 0 || this.awards.commentAwards.silver.length > 0 || this.awards.commentAwards.bronze.length > 0;
        this.hasMostCommentsAwards = this.awards.comments.gold.length > 0 || this.awards.comments.silver.length > 0 || this.awards.comments.bronze.length > 0;
        this.hasMostVotesAwards = this.awards.votes.gold.length > 0 || this.awards.votes.silver.length > 0 || this.awards.votes.bronze.length > 0;
        this.user.id = uid;
        this.canHaveAccuracyFlair = this.user.totalAccuracyVotes >= (this.currentWeek-1)*this.accuracyVotesThreshold;
        this.ownsProfile = this.currentUser && this.user.id === this.currentUser.uid ? true : false;
        if (this.user.isInfluencer) {
          this.isInfluencer = true;
          if (!this.ownsProfile) {
            this.router.navigate(['/send/'+this.user.username]);
          }
        }
        this.badgeSrc();
        this.seasonLongCorrectDividedByTotal();
        if (this.user.isInfluencer) {
          this.firebaseService.dashboardSubscription({recipientId: this.user.id}).subscribe((state) => {
            // Only load new polls
            if (state[state.length-1] && state[state.length-1].type == "added") {
              this.getPolls({recipientId: this.user.id})
            }
          });
        }
        this.feedOptions = [
          {label: "Created Polls", value:0},
          {label: "Inbox", value:1}
        ]
        this.selectedFeed = this.feedOptions[0].value;
        // This line also getsPolls
        this.changeFeed(this.feedOptions[0]);
        // Keeping this here incase later on we want to show comments in the profile.
        // this.firebaseService.getComments({uid: this.profileUid, awarded: true}).subscribe((comments:any) => {
        //   this.awardedComments = comments.map(comment => {
        //     let commentData = comment.payload.doc.data();
        //     let d = new Date(0)
        //     d.setUTCSeconds(commentData.createdAt.seconds);
        //     commentData.createdAt = d;
        //     return commentData
        //   });
        // });
        this.seo.generateTags({
          title: `Poll Sports | ${this.user.username}'s profile page`,
          description: `${this.user.username}'s history of polls, votes, comments, and awards.`,
          image: 'https://pollsports.com/assets/logo-blue-prod.png',
          slug: `user/${this.user.username}`
        });
        if (this.user.lastLogin) {
          let d = new Date(0)
          d.setUTCSeconds(this.user.lastLogin.seconds);
          this.lastLogin = d;
        }
        if (this.user.createdAt) {
          let d = new Date(0)
          d.setUTCSeconds(this.user.createdAt.seconds);
          this.createdAt = d;
        }
        // Backwards compatible for users who have not yet logged in since credibility update.
        if (!this.user.credibility) {
          (async () => {
            const db = firebase.firestore();
            const uid = this.profileUid

            const commentsSnapshot = await db.collection('comments')
            .where('uid', '==', uid)
            .get({source: 'server'});
            this.user.comments = commentsSnapshot.docs.length;

            const votesSnapshot = await db.collection('votes')
            .where('uid', '==', uid)
            .get({source: 'server'});
            this.user.votes = votesSnapshot.docs.length;

            const visitsSnapshot = await db.collection('visits')
            .where('uid', '==', uid)
            .get({source: 'server'});
            this.user.visits = visitsSnapshot.docs.length;

            const pollsSnapshot = await db.collection('polls')
            .where('uid', '==', uid)
            .get({source: 'server'});
            this.user.polls = pollsSnapshot.docs.length;

            this.user.awards = 0;
            this.user.upvotes = 0;
            this.user.credibility = (this.user.visits*10)+(this.user.votes)+(this.user.comments*5);
          })();
        }
        this.value = Math.round((this.user.credibility/this.levelUp)*100);
      });
    });
  }

  badgeSrc() {
    if (this.user) {
      const credibility = this.user.credibility;
      if (!credibility || credibility < 1000) {
        this.currentBadge = "Rookie"
        this.levelUp = 1000;
        this.levelUpBadge = "Sophomore"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-02-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-01.svg"
      } else if (credibility >= 1000 && credibility < 2500) {
        this.currentBadge = "Sophomore"
        this.levelUp = 2500;
        this.levelUpBadge = "Captain"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-03-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-02.svg"
      } else if (credibility >= 2500 && credibility < 5000) {
        this.currentBadge = "Captain"
        this.levelUp = 5000;
        this.levelUpBadge = "Veteran"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-04-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-03.svg"
      } else if (credibility >= 5000 && credibility < 10000) {
        this.currentBadge = "Veteran"
        this.levelUp = 10000;
        this.levelUpBadge = "All Pro"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-05-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-04.svg"
      } else if (credibility >= 10000 && credibility < 20000) {
        this.currentBadge = "All Pro"
        this.levelUp = 25000;
        this.levelUpBadge = "Hall of Fame"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-06-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-05.svg"
      } else if (credibility >= 20000 && credibility < 50000) {
        this.currentBadge = "Hall of Fame"
        this.levelUp = 50000;
        this.levelUpBadge = "Legend"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-07-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-06.svg"
      } else if (credibility >= 50000 && credibility < 100000) {
        this.currentBadge = "Legend"
        this.levelUp = 100000;
        this.levelUpBadge = "Conqueror"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-08-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-07.svg"
      } else if (credibility >= 100000 && credibility < 200000) {
        this.currentBadge = "Conqueror"
        this.levelUp = 200000;
        this.levelUpBadge = "Archon"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-09-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-08.svg"
      } else if (credibility >= 200000 && credibility < 400000) {
        this.currentBadge = "Archon"
        this.levelUp = 400000;
        this.levelUpBadge = "Mythical"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-10-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-09.svg"
      } else if (credibility >= 400000 && credibility < 800000) {
        this.currentBadge = "Mythical"
        this.levelUp = 800000;
        this.levelUpBadge = "Wizard"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-11-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-10.svg"
      } else if (credibility >= 800000) {
        this.currentBadge = "Wizard"
        this.levelUp = 1600000;
        this.levelUpBadge = "TBD"
        this.levelUpBadgeSrc = "/assets/badges/with-text/v2/gold/badge-11-gold.svg"
        this.avatar = "/assets/badges/with-text/v2/badge-11.svg"
      }
    }
    if (this.user.avatar) {
      this.avatar = this.user.avatar
    }
  }

  numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return 0
    }
  }

  remaining() {
    return this.numberWithCommas(this.levelUp-this.user.credibility)
  }

  enableNotifications() {
    this.msg.getPermission(this.currentUser)
    this.msg.monitorRefresh(this.currentUser)
    this.msg.receiveMessages()
  }

  removeLeague(index) {
    this.leagues.splice(index, 1);
  }


  addLeague() {
    this.leagues.push([]);
  }

  save() {
    this.confirmationService.confirm({
        message: 'Would you like to save your leagues?',
        accept: () => {
          this.leagues.forEach((league, index) => {
            this.firebaseService.createLeague({id: league.id, name: league.name, tags: league.tags})
          });
          this.leaguesSaved = true;
        }
    });
  }

  deleteLeague(id) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this league?',
        accept: () => {
          this.firebaseService.deleteLeague(id);
        }
    });
  }

  hideCreatePollForm() {
    this.displayCreateModal = false;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  hidePointsModal() {
    this.displayPointsModal = false;
  }

  sendPoll() {
    this.displayCreateModal = true;
    if (!this.currentUser || this.currentUser.isAnonymous) {
      this.displayAccountModal = true;
      this.displayCreateModal = false;
    } else {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
        this.points = user.payload.get('points')
        // Must have required points to post a question
        if (this.points < this.pointsRequired) {
          this.displayPointsModal = true;
          this.displayCreateModal = false;
        } else {
          this.pollForm.showCreate(this.user);
          this.changeFeed({value: 1})
        }
      });
    }
  }

  getPolls(query) {
    this.firebaseService.getPolls(query).then((response: any) => {
      this.polls = response.polls;
    });
  }

  changeFeed(type) {
    // ToDo: Add loading spinner
    this.selectedFeed = type.value;
    if (this.selectedFeed === 0) {
      this.getPolls({uid: this.user.id})
    } else {
      this.getPolls({recipientId: this.user.id, getActive: true})
    }
  }

  seasonLongCorrectDividedByTotal() {
    let correct = 0;
    let total = 0;
    if (this.weeks && this.user) {
      const season = this.weeks.forEach((week) => {
        let value = week.value;
        correct += this.user.accuracy.hasOwnProperty(this.currentYear) && this.user.accuracy[this.currentYear]["week"+value] ? this.user.accuracy[this.currentYear]["week"+value].correct : 0;
        total += this.user.accuracy.hasOwnProperty(this.currentYear) && this.user.accuracy[this.currentYear]["week"+value] ? this.user.accuracy[this.currentYear]["week"+value].totalVotes : 0;
      });
      this.correctTotal = `${correct}/${total}`
    }
  }

  goToFanPage() {
    this.router.navigate(['/send/'+this.user.username]);
  }

  percentRank(array, n) {
    array = array.sort(function(a, b){return b-a});
      var L = 0;
      var S = 0;
      var N = array.length

      for (var i = 0; i < array.length; i++) {
          if (array[i] < n) {
              L += 1
          } else if (array[i] === n) {
              S += 1
          } else {

          }
      }

      var pct = (L + (0.5 * S)) / N

      return pct
  }

  togglePercentileAccuracy() {
    if (this.awards.percentile.length <= 0) { return; };
    this.expandPercentileAccuracy = !this.expandPercentileAccuracy;
  }

  toggleGoldAccuracy() {
    if (this.awards.accuracy.gold.length <= 0) { return; };
    this.expandGoldAccuracy = !this.expandGoldAccuracy;
  }

  toggleSilverAccuracy() {
    if (this.awards.accuracy.silver.length <= 0) { return; };
    this.expandSilverAccuracy = !this.expandSilverAccuracy;
  }

  toggleBronzeAccuracy() {
    if (this.awards.accuracy.bronze.length <= 0) { return; };
    this.expandBronzeAccuracy = !this.expandBronzeAccuracy;
  }

  toggleCommentAwards() {
    if (!this.hasCommentsAwards) { return; };
    this.expandCommentAwards = !this.expandCommentAwards;
  }

  toggleMostCommentsAwards() {
    if (!this.hasMostCommentsAwards) { return; };
    this.expandMostCommentsAwards = !this.expandMostCommentsAwards;
  }

  toggleMostVotesAwards() {
    if (!this.hasMostVotesAwards) { return; };
    this.expandMostVotesAwards = !this.expandMostVotesAwards;
  }

  toggleCredibilityTooltip() {
    this.showCredibilityTooltip = !this.showCredibilityTooltip;
  }
}

import { Component, OnInit, Input, Output, EventEmitter, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import * as Chart from 'chart.js';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';
import { first } from 'rxjs/operators';
import { CardModule } from 'primeng/card';
import { AngularFirestore } from '@angular/fire/firestore';
import { TooltipModule } from 'primeng/tooltip';
import { AppConfig } from '../../app-config';
import { PlayerDetailsComponent } from '../player-details/player-details.component';
import { ConfirmationService } from 'primeng/api';

import pollTypes from '../../assets/types/poll-types.json';
import sportTypes from '../../assets/types/sport-types.json';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit {
  loading:boolean = true;
  displayShareModal:boolean = false;
  displayPlayerDetails:boolean = false;
  displayBoostModal:boolean = false;
  chart:any;
  poll:any;
  votes:[] = [];
  title:string = "";
  isDrawn:boolean = false;
  votedChoices:any = [];
  hasVoted:boolean = false;
  sportPollTypes:any = pollTypes;
  sportTypes:any = sportTypes.types;
  origin:string = this.document.location.origin;
  commentTotal:number;
  createdAt:any;
  sportName:any = "football";
  voteCount:number = 0;
  y_clicked = null; //holds y-axis .getValueForPixel() when chart is clicked
  timeoutID = null; //handle to setTimeout
  numOfBars = 5; //how many horizontal bars to chart
  selectedPlayers:any;
  domain:string = environment.domain;
  expired:boolean = false;
  playerIds:any = {};
  // scrolling:boolean = false;
  showComments:boolean = false;
  hasManyLabels:boolean = false;
  labels:any = [];
  canHaveAccuracyFlair:boolean = false;
  isInfluencer:any;

  @ViewChild('canvas') canvas: ElementRef;

  @ViewChild('checkboxContainer') checkboxContainer: ElementRef;

  @Input()
  liveUpdating:boolean = false;

  @Input()
  renderPoll:boolean = false;

  @Input()
  hasSeenSkipModal:boolean;

  @Input()
  showSkip:boolean = false;

  @Input()
  disableButtons:boolean = false;

  @Input()
  currentUser:any;

  @Input()
  hideResults:boolean = true;

  @Input()
  notificationsDismissed:any;

  @Input()
  currentUsername:string;

  @Input()
  currentEmail:string;

  @Input()
  credibility:number;

  @Input()
  accuracyTotal:any;

  @Input()
  pollDocument:any;

  @Input()
  embedded:boolean = false;

  @Output()
  deleteEvent = new EventEmitter<string>();

  @Output()
  loginEvent = new EventEmitter<string>();

  @Output()
  shareEvent = new EventEmitter<string>();

  @Output()
  voteComplete = new EventEmitter<string>();

  @Output()
  boostEvent = new EventEmitter<string>();

  @Output()
  incrementVoteEvent = new EventEmitter<string>();

  @Output()
  decrementVoteEvent = new EventEmitter<string>();

  @Output()
  hidePollEvent = new EventEmitter<string>();

  @Output()
  updateHasSeenSkipModal = new EventEmitter<string>();

  constructor(
    private firebaseService: FirebaseService,
    private trackingService: TrackingService,
    private router: Router,
    public db: AngularFirestore,
    private location: Location,
    private _config: AppConfig,
    private confirmationService: ConfirmationService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    let pollDoc:any;
    if (this.pollDocument.hasOwnProperty('payload')) {
      pollDoc = this.pollDocument.payload.hasOwnProperty('doc') ? this.pollDocument.payload.doc : this.pollDocument.payload;
    } else {
      pollDoc = this.pollDocument;
    }
    const pollData:any = pollDoc.data();
    // Hack to prevent menu from breaking when you vote on a poll that is later deleted
    if (pollData) {
      this.commentTotal = pollData.commentTotal
      const allowAnonymousVotes = pollData.allowAnonymousVotes !== undefined ? pollData.allowAnonymousVotes : true;
      this.poll = {
        id: pollDoc.id,
        title: pollData.title,
        helperText: pollData.helperText,
        pollType: pollData.pollType,
        tags: pollData.tags,
        user: pollData.user,
        players: pollData.players,
        uid: pollData.uid,
        choices: pollData.choices,
        sport: pollData.sport,
        matchups: pollData.matchups ? pollData.matchups : [],
        expires: pollData.expires,
        totalCommentsAwarded: pollData.totalCommentsAwarded,
        createdAt: pollData.createdAt,
        allowAnonymousVotes: allowAnonymousVotes,
        week: pollData.week ? pollData.week : this._config.WEEK,
        expired: pollData.expired ? pollData.expired : false,
        boosted: pollData.boosted ? pollData.boosted : false,
        choicesAllowed: pollData.choicesAllowed,
        scoredChoices: pollData.scoredChoices ? pollData.scoredChoices : [],
        usersWhoHaveVoted: pollData.usersWhoHaveVoted ? pollData.usersWhoHaveVoted : [],
        isAccuracyEligible: pollData.isAccuracyEligible ? pollData.isAccuracyEligible : false
      };

      this.setPollExpiration();
      // Prevent poll expansion by hiding tags when there are more than 9
      this.setPollTagsViewMore();
      if (this.sportPollTypes[this.sportName].find(type => type.code == this.poll.pollType)) {
        this.title = this.sportPollTypes[this.sportName].find(type => type.code == this.poll.pollType).name;
      }

      if (this.supportsNewTitleRendering()) {
        const pollTypesWithFormattedTitles = ['SIDPFP', 'SIUW', 'PRICECHECK', 'FAAB']
        if (pollTypesWithFormattedTitles.includes(this.poll.pollType) || this.poll.choicesAllowed && this.poll.choicesAllowed > 1) {
          this.title = this.poll.title;
        }
      } else {
        this.setLegacyPollTitle()
      }

      if (this.poll.pollType === 'PRICECHECK') {
        // Remove player from price check, it's only there for search page.
        if (this.poll.choices.length > 7) { this.poll.choices.shift(); }
      }

      if (this.poll.pollType === 'FAAB') {
        // Remove player from FAAB, it's only there for search page.
        if (this.poll.choices.length > 6) { this.poll.choices.shift(); }
      }
    }
    // Hack to prevent menu from breaking when you vote on a poll that is later deleted
    if (!this.poll) {
      return;
    }

    if (this.liveUpdating) {
      this.initiateLivePoll();
    } else {
      this.initiateStaticPoll();
    }

    // Extra safe check for checkout b/c it requires email.
    if (!this.currentEmail) {
      this.firebaseService.getUserByUsername(this.poll.user).pipe(first()).subscribe((response:any) => {
        const { payload = {} } = response[0];
        this.currentEmail = payload.doc.get('email') || '';
      });
    }
  }

  disableChoices() {
    //Disable choices until vote is unchecked
    this.poll.choices.forEach(choice => {
      const choiceEl:any = this.checkboxContainer && this.checkboxContainer.nativeElement.querySelector('[id="'+choice.id+'"]');
      if (this.votedChoices.length > 0 && choiceEl) {
        if (this.poll.choicesAllowed && this.poll.choicesAllowed > 1) {
          if (this.poll.choicesAllowed === this.votedChoices.length && !this.votedChoices.includes(choice.id)) choiceEl.disabled = true;
        } else {
          if (!this.votedChoices.includes(choice.id)) choiceEl.disabled = true;
        }
      }
    });
  }

  drawPoll() {
    let fontSize;
    let data = this.poll.choices.map(choice => choice.votes);
    let leftPadding = 0;
    let lineHeight = 1;

    if (this.poll.pollType === "WDIS" && this.poll.expired) {
      leftPadding = 10;
    }

    if ((this.poll.choices[0].players.length >= 4 && this.poll.choices[1].players.length >= 4) && this.poll.pollType === "WWTT") {
      lineHeight = .9;
    }

    if (this.hasManyLabels && this.poll.choices.length > 3) {
      fontSize = 8;
    } else if (this.hasManyLabels) {
      fontSize = 10;
    } else {
      fontSize = 12;
    }

    this.voteCount = data.reduce((partial_sum, a) => partial_sum + a,0);

    // Hide results if you haven't voted yet or if it's not one of your polls
    const hasVoted = this.hasVoted || this.currentUser && this.poll.usersWhoHaveVoted.includes(this.currentUser.uid);
    if (!hasVoted && this.hideResults && (this.poll.user !== this.currentUsername)) {
      data = data.map(vote => 0);
    }

    // Old version of Set player IDs for displaying weekly matchup
    // Keeping for now incase matchups break for week 1. Delete if all good.
    // this.poll.choices.forEach((choice) => {
    //   if (choice.players) {
    //     let player = choice.players.map((player) => {
    //       if (player) {
    //         this.playerIds[player.displayName] = player.playerId;
    //       }
    //     });
    //     return player;
    //   }
    // });
    if (this.isDrawn) {
      this.chart.data.datasets[0].data = data;
      this.chart.data.datasets[0].label = this.labels;
      this.chart.update()
    }
    if (!this.isDrawn) {
      this.disableChoices();
      var canvas = this.canvas && this.canvas.nativeElement;
      if(canvas) {
        var ctx = canvas.getContext("2d");
        var red_gradient = ctx.createLinearGradient(0, 0, 170, 0);
        red_gradient.addColorStop(0, '#b4001c');
        red_gradient.addColorStop(1, '#EF1136');
        var blue_gradient = ctx.createLinearGradient(0, 0, 100, 0);
        blue_gradient.addColorStop(0, '#132034');
        blue_gradient.addColorStop(1, '#283c5c');
        this.chart = new Chart(ctx, {
          type: 'horizontalBar',
          data: {
            labels: this.labels,
            datasets: [{
              label: this.title,
              data: data,
              fill: false,
              backgroundColor: [
                red_gradient,
                blue_gradient,
                red_gradient,
                blue_gradient,
                red_gradient,
                blue_gradient,
                red_gradient
              ],
              borderColor: [
                "#EF1136",
                "#283C5C",
                "#EF1136",
                "#283C5C",
                "#EF1136",
                "#283C5C",
                "#EF1136",
              ],
              borderWidth: 1
            }]
          },
          options: {
            events: ["touchend", "click", "mouseout", "mousemove"],
            onHover: (event, chartElement) => {
                event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            },
            hover: {
              animationDuration: 0 // duration of animations when hovering an item
            },
            // use this for expired WDIS polls
            layout: {
                padding: {
                    left: leftPadding
                }
            },
            responsiveAnimationDuration: 0, // animation duration after a resize
            tooltips: {
              enabled: true,
              callbacks: {
                title: function() {},
                label: function(tooltipItem, data) {
                  //get the concerned dataset
                  var dataset = data.datasets[tooltipItem.datasetIndex];
                  //calculate the total of this data set
                  var total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                    return previousValue + currentValue;
                  });
                  //get the current items value
                  var currentValue = dataset.data[tooltipItem.index];
                  //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
                  var percentage = Math.floor(((currentValue/total) * 100)+0.5);

                  return percentage + "%";
                }
              }
            },
            title: {
              display: false,
              text: this.title,
              fontSize: 14,
              fontColor: '#666'
            },
            legend: {
              display: false
            },
            maintainAspectRatio: true,
            responsive: true,
            scales: {
              yAxes: [{
                ticks: {
                  fontSize: fontSize,
                  sampleSize: 10,
                  // Setting these to boost performance.
                  maxRotation: 50,
                  minRotation: 0,
                  // Use this for examples where txt is bunched together.
                  lineHeight: lineHeight
                }
              }],
              xAxes: [{
                ticks: {
                  beginAtZero: true,
                  precision: 0,
                  sampleSize: 10,
                  // Setting these to boost performance.
                  maxRotation: 50,
                  minRotation: 0
                },
              }]
            }
          }
        });
        this.isDrawn = true;
        this.loading = false;
      }
      this.renderPoll = true;
    }
  }

  maybePluralize (count, noun, suffix = 's') {
    return `${count} ${noun}${count !== 1 ? suffix : ''}`;
  }

  vote(choiceId) {
    if (this.currentUser) {
      this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe(async (user:any) => {
        this.currentUsername = await user.payload.get('username');
        this.credibility = await user.payload.get('credibility');
        this.notificationsDismissed = await user.payload.get('notificationsDismissed');
        this.canHaveAccuracyFlair = await user.payload.get('canHaveAccuracyFlair');
        this.accuracyTotal = this.canHaveAccuracyFlair ? await user.payload.get('accuracyTotal') : 0;
        this.isInfluencer = await user.payload.get('isInfluencer');
        this.completeVote(choiceId);
      });
    } else {
      this.completeVote(choiceId);
    }
  }

  completeVote(choiceId) {
    let totalVotedChoices;
    if (choiceId) {
      const choiceInput:any = this.checkboxContainer && this.checkboxContainer.nativeElement.querySelector('[id="'+choiceId+'"]');
      if (!this.currentUser) {
        this.loginEvent.emit();
        choiceInput.checked = false;
        return;
      }
      if (choiceInput) {
        const checked = choiceInput.checked;
        if (checked) {
          totalVotedChoices = this.votedChoices.length+1
        } else {
          totalVotedChoices = this.votedChoices.length-1
        }
        this.poll.choices.forEach(choice => {
          const choiceEl:any = this.checkboxContainer && this.checkboxContainer.nativeElement.querySelector('[id="'+choice.id+'"]');
          if (!checked) {
            choiceEl.disabled = false;
          }
          if (this.poll.choicesAllowed && this.poll.choicesAllowed > 1) {
            if (!choiceEl.checked && totalVotedChoices === this.poll.choicesAllowed) choiceEl.disabled = true;
          } else {
            if (choiceId !== choiceEl.id && checked) choiceEl.disabled = true;
          }
        });
        if (checked) {
          this.firebaseService.incrementChoice(choiceId, this.poll, this.currentUsername, this.credibility, this.accuracyTotal, this.isInfluencer).then((res:any) => {
            this.hasVoted = true;
            this.incrementVote(choiceId);
          }).catch(err => {
            console.log("You have already voted on this poll");
          });
        } else {
          this.hasVoted = false;
          this.firebaseService.decrementChoice(choiceId, this.poll).then((res:any) => {
            this.decrementVote(choiceId);
          });
        }
      }
    }
  }

  delete() {
    this.deleteEvent.emit(this.poll);
  }

  share() {
    this.displayShareModal = true;
  }

  show(tab) {
    if (this.disableButtons) {
      return;
    }
    if (tab == 'comments') {
      this.showComments = true;
      return;
    } else {
      if (!tab) {
        tab = 'comments';
      }
      const sportString = this.sportTypes.find(type => type.code == this.poll.sport).name
      if (this.embedded) {
        window.open(`${this.domain}/poll/${sportString}/${this.poll.id}?tab=${tab}`);
        return;
      }
      this.router.navigate([`poll/${sportString}`, this.poll.id], { queryParams: { tab: tab } });
    }
  }

  incrementVote(choiceId) {
    this.trackingService.track('Registered Vote', {
      uid: this.currentUser.uid,
      username: this.currentUsername,
      poll: this.poll.id
    });
    const selectedChoice = this.poll.choices.find((searchedChoice) => {
      return choiceId == searchedChoice.id
    });
    selectedChoice.votes = ++selectedChoice.votes;
    this.votedChoices.push(choiceId);

    // Used to update feed for users with toggle off
    if (this.poll.choicesAllowed == this.votedChoices.length) {
      this.incrementVoteEvent.emit();
    }
    // Used to update feed for users with toggle off

    this.drawPoll();
    if (this.embedded) {
      this.voteComplete.emit();
    }
  }

  decrementVote(choiceId) {
    const selectedChoice = this.poll.choices.find((searchedChoice) => {
      return choiceId == searchedChoice.id
    });
    selectedChoice.votes = --selectedChoice.votes;
    const voteIndex = this.votedChoices.indexOf(choiceId);
    if (voteIndex > -1) {
      this.votedChoices.splice(voteIndex, 1);
    }

    // Used to update feed for users with toggle off
    if (this.poll.choicesAllowed && this.poll.choicesAllowed > 1 && this.votedChoices.length <= 0) {
      this.decrementVoteEvent.emit();
    } else {
      if (this.poll.choicesAllowed === 1) {
        this.decrementVoteEvent.emit();
      }
    }
    // Used to update feed for users with toggle off

    this.drawPoll();
  }

  copy() {
    var input:any = document.getElementById("shareUrlInput");
    var isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);

    if (isiOSDevice) {

      var editable = input.contentEditable;
      var readOnly = input.readOnly;

      input.contentEditable = true;
      input.readOnly = false;

      var range = document.createRange();
      range.selectNodeContents(input);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      input.setSelectionRange(0, 999999);
      input.contentEditable = editable;
      input.readOnly = readOnly;

    } else {
       input.select();
    }

    this.trackingService.track('Copied poll share link', {
      uid: this.currentUser.uid,
      username: this.currentUsername,
      poll: this.poll.id
    });

    document.execCommand('copy');
    alert("Poll URL has been copied to clipboard.");
  }

  goToProfile() {
    let username = this.poll.user.toLowerCase();
    if (this.embedded) {
      window.open(`${this.domain}/user/${username}`);
      return;
    }
  }

  hidePlayerDetails() {
    this.displayPlayerDetails = false;
  }

  getStyle (el, property) {
		return el.currentStyle ?
			el.currentStyle[property] :
			document.defaultView.getComputedStyle(el, null).getPropertyValue(property);
	};

  viewDetails() {
    this.displayPlayerDetails = true;
  }

  boost() {
    this.displayBoostModal = true;
  }

  checkoutComplete() {
    this.firebaseService.updatePoll(this.poll.id, {boosted: true})
    this.boostEvent.emit();
    this.poll.boosted = true;
    this.displayBoostModal = false;
  }

  closeComments() {
    this.showComments = false;
  }

  // Better experience for embedded comments. Update counter immediately.
  incrementTotalComments() {
    this.commentTotal++;
  }

  skipPoll() {
    if (this.hasSeenSkipModal) {
      this.firebaseService.skipPoll(this.poll.id, this.currentUser.uid).then(() => {
        this.hidePollEvent.emit(this.poll.id);
      });
    } else {
      this.confirmationService.confirm({
        message: 'Are you sure you want to skip this poll? It will disappear from your feed.',
        accept: () => {
          this.firebaseService.skipPoll(this.poll.id, this.currentUser.uid).then(() => {
            this.hidePollEvent.emit(this.poll.id);
            this.updateHasSeenSkipModal.emit();
            this.firebaseService.updateUser(this.currentUser.uid, {hasSeenSkipModal: true});
          });
        }
      });
    }
  }

  isDynasty() {
    return this.poll.tags.some(tag => tag.name === 'Dynasty');
  }

  isKeeper() {
    return this.poll.tags.some(tag => tag.name === 'Keeper');
  }

  userCanDeletePoll() {
    let canDelete = false;
    if (this.currentUser && this.currentUser.uid == this.poll.uid && this.poll.createdAt) {
      // Now timestamp
      let d = new Date();
      // Poll createdAt timestamp
      let d2 = new Date(this.poll.createdAt.seconds*1000);
      // Add 10 minutes
      let pollSeconds = new Date(d2.getTime() + 10*60000);
      // Allow poll deletion if created less than 10m ago.
      if (d < pollSeconds) {
        canDelete = true;
        return canDelete;
      }
    }
    return canDelete;
  }

  supportsNewTitleRendering() {
    let dateOfTitleRenderingUpdate = new Date(new Date("04/28/20").setHours(0,0,0,0))
    let d2 = new Date(this.poll.createdAt.seconds*1000);
    let pollSeconds = new Date(d2.getTime());
    if (pollSeconds > dateOfTitleRenderingUpdate) {
      return true;
    } else {
      return false
    }
  }

  setPollExpiration() {
    if (this.poll.expired) {
      this.expired = true;
      this.hasVoted = true;
    } else {
      if (this.poll.expires && (Date.now() > new Date(this.poll.expires).getTime())) {
        this.expired = true;
        this.hasVoted = true;
        this.firebaseService.expirePoll(this.poll.id);
      }
    }
  }

  getGames() {
    return this._config.SCHEDULE.filter((games) => games.gameWeek == this.poll.week);
  }

  setPollTagsViewMore() {
    if (this.poll.tags.length > 9 && !this.disableButtons) {
      let visibleTags = this.poll.tags.slice(0,8)
      let hiddenTags = this.poll.tags.slice(8);
      this.poll.tags = visibleTags
      this.poll.tags.push({code: "MORE", name: "+"+hiddenTags.length})
    }
  }

  setLegacyPollTitle() {
    switch (this.poll.pollType) {
      case 'SIDPFP':
        this.title = `Should I Drop ${this.poll.choices[0].players[0].fname} ${this.poll.choices[0].players[0].lname} for ${this.poll.choices[1].players[0].fname} ${this.poll.choices[1].players[0].lname}?`;
        break;
      case 'SIUW':
        this.title = `Should I Use ${this.poll.choices[0].players[0].name} on ${this.poll.choices[1].players[0].fname} ${this.poll.choices[1].players[0].lname}?`;
        break;
      case 'WDISM':
        this.title = `Who Do I Start? (Pick ${this.poll.choicesAllowed})`;
        break;
      case 'PRICECHECK':
        this.title = this.poll.title;
        break;
      case 'FAAB':
        this.title = this.poll.title;
        break;
      default:
        break;
    }
  }

  shrinkTitle() {
    if (this.title.length > 45 || this.poll.pollType === "FAAB" || this.poll.pollType === "SIDPFP") {
      return true;
    } else {
      return false;
    }
  }

  createdAtFormatted() {
    if (this.poll.createdAt) {
      let d = new Date(0)
      d.setUTCSeconds(this.poll.createdAt.seconds);
      return d;
    }
  }

  initiateStaticPoll() {
    this.firebaseService.getPoll(this.poll.id).pipe(first()).subscribe((pollDoc:any) => {
      let serverChoices = {};
      pollDoc.payload.get("choices").forEach((choice: any) => {
        serverChoices[choice.id] = choice;
      });
      var cnt = 0;
      this.poll.choices.forEach((choice:any) => {
        const label = choice.players.map((player) => {
          // Set player IDs for displaying weekly matchup
          const playerDisplayName = player.displayName;
          this.playerIds[playerDisplayName] = player.playerId;

          return playerDisplayName;
        });
        if (label.length > 3) { this.hasManyLabels = true; }

        let scores = {};
        if (this.poll.scoredChoices.length > 0) {
          this.poll.scoredChoices.forEach((choice) => {
            scores[choice.player.displayName] = choice.score;
          });
          let score = scores[label];
          score = score ? score.toFixed(2) : score;
          this.labels.push(`${label}-${score}pts`)
        } else {
          this.labels.push(label);
        }

        const choiceEl = this.checkboxContainer && this.checkboxContainer.nativeElement.querySelector('[id="'+choice.id+'"]');
        if (choiceEl) {
          choiceEl.disabled = true;
        }

        if (this.currentUser) {
          const voteKey = `${this.poll.id}_${choice.id}_${this.currentUser.uid}`;
          this.db.collection('votes').doc(voteKey).valueChanges().pipe(first()).subscribe((vote:any) => {
            if (vote) {
              this.votedChoices.push(vote.choice);
              this.hasVoted = true;
            }
          });
        }

        let query:any = {choice: choice.id}
        query.limit = 1
        this.firebaseService.getVotes(query).pipe(first()).subscribe((votes: any) => {
          const selectedChoice = this.poll.choices.find((searchedChoice) => {
            return choice.id == searchedChoice.id
          });
          this.drawPoll();
          selectedChoice.votes = serverChoices[choice.id].votes;
          if (cnt>=this.poll.choices.length-1){
            this.drawPoll();
          }
          cnt++;
        });
      });
    });
  }

  initiateLivePoll() {
    var cnt = 0;
    this.poll.choices.forEach((choice:any) => {
      const label = choice.players.map((player) => {
        // Set player IDs for displaying weekly matchup
        const playerDisplayName = player.displayName;
        this.playerIds[playerDisplayName] = player.playerId;

        return playerDisplayName;
      });
      if (label.length > 3) { this.hasManyLabels = true; }

      let scores = {};
      if (this.poll.scoredChoices.length > 0) {
        this.poll.scoredChoices.forEach((choice) => {
          scores[choice.player.displayName] = choice.score;
        });
        let score = scores[label];
        score = score ? score.toFixed(2) : score;
        this.labels.push(`${label}-${score}pts`)
      } else {
        this.labels.push(label);
      }

      const choiceEl = this.checkboxContainer && this.checkboxContainer.nativeElement.querySelector('[id="'+choice.id+'"]');
      if (choiceEl) {
        choiceEl.disabled = true;
      }

      if (this.currentUser) {
        const voteKey = `${this.poll.id}_${choice.id}_${this.currentUser.uid}`;
        this.db.collection('votes').doc(voteKey).valueChanges().pipe(first()).subscribe((vote:any) => {
            if (vote) {
              this.votedChoices.push(vote.choice);
              this.hasVoted = true;
            }
        });
      }

      let query:any = {choice: choice.id}
      query.limit = 1
      this.firebaseService.getVotes(query).subscribe((votes: any) => {
        const selectedChoice = this.poll.choices.find((searchedChoice) => {
          return choice.id == searchedChoice.id
        });
        this.firebaseService.getPoll(this.poll.id).subscribe((pollDoc:any) => {
          let serverSelectedChoice = pollDoc.payload.get("choices").find((searchedChoice) => {
            return choice.id == searchedChoice.id
          });
          selectedChoice.votes = serverSelectedChoice.votes;
          this.drawPoll();
        });

        if (cnt>=this.poll.choices.length-1){
          this.drawPoll();
        }
        cnt++;
      });
      // Keeping this incase in the future I decide to turn off feed updating.
      // else {
      //   // ToDo: Use votes value stored on choice instead fetching votes collection.
      //   this.firebaseService.getVotes(query).pipe(first()).subscribe((votes: any) => {
      //     console.log("test");
      //     const selectedChoice = this.poll.choices.find((searchedChoice) => {
      //       return choice.id == searchedChoice.id
      //     });
      //     if (!fetchFromDocument || fetchFromDocument == undefined) {
      //       selectedChoice.votes = votes.length;
      //     }
      //     if (cnt>=this.poll.choices.length-1){
      //       this.drawPoll();
      //       console.log("draw me");
      //     }
      //     cnt++;
      //   });
      // }

    });
  }

}

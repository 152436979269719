import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../services/navbar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { SeoService } from '../services/seo.service';
import { AppConfig } from '../../app-config';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Pluralize from 'pluralize';

@Component({
  selector: 'app-group-index',
  templateUrl: './group-index.component.html',
  styleUrls: ['./group-index.component.scss']
})
export class GroupIndexComponent implements OnInit {
  groupName:string;
  groups:any = [];
  displayForm:boolean = false;
  currentUser:any = {};
  isLoggedIn:any;

  constructor(
    public nav: NavbarService,
    private firebaseService:FirebaseService,
    public afAuth: AngularFireAuth,
    private router: Router,
    private seo: SeoService,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.generateSeoTags();
    window.scrollTo(0, 0);
    this.nav.show();
    this.getGroups();
  }

  mergeGroups(fetchedGroups) {
    this.groups = [...this.groups, ...fetchedGroups]
    var unique = {};
    this.groups = this.groups.filter((group) => {
      if (unique[group.id]) { return false; }
      unique[group.id] = true;
      return true;
    });
  }

  getGroups() {
    this.firebaseService.getPublicGroups().then((publicGroups:any) => {
      const mappedPublicGroups = publicGroups.map(group => Object.assign({}, group.data(), { id: group.id }));
      this.mergeGroups(mappedPublicGroups);
    });

    if (this.currentUser.uid) {
      this.getUserGroups();
      this.getOrCreateGlobalContest();
    } else {
      this.afAuth.authState.subscribe((user) => {
        this.isLoggedIn = !user.isAnonymous;
        const uid = user.uid;
        this.firebaseService.getUser(uid).pipe(first()).subscribe((user:any) => {
          this.currentUser = Object.assign({}, user.payload.data(), { uid: uid });
          this.getUserGroups();
          this.getOrCreateGlobalContest();
        })
      });
    }
  }

  getUserGroups() {
    this.firebaseService.getUserGroups(this.currentUser.uid).then((userGroups:any) => {
      const mappedUserGroups = userGroups.map(group => Object.assign({}, group.data(), { id: group.id }));
      this.mergeGroups(mappedUserGroups);
    });
  }

  createGroup() {
    const group = {
      name: this.groupName,
      creatorUid: this.currentUser.uid,
      creatorUsername: this.currentUser.username
    }
    this.firebaseService.createGroup(group).then((createdGroup:any) => {
      this.closeForm();
      this.router.navigate(['/communities/'+createdGroup.groupId]);
    });
  }

  showForm() {
    this.displayForm = true;
  }

  closeForm() {
    this.displayForm = false;
  }

  // Creates global contest doc so that polls can be curated for all contests.
  getOrCreateGlobalContest() {
    const admins = ["TuK223ZDk9XtTfAJWW1eL85KHjg1", "Wez0HtM8lsbImfrgufKfTaUb4AI3"];
    if (admins.includes(this.currentUser.uid)) {
      this.firebaseService.getOrCreateGlobalContest().then(() => {
        console.log("Global contest is set.");
      });
    }
  }

  memberCountString(group) {
    if (group) {
      return `${group.memberCount} ${Pluralize('Member', group.memberCount)}`
    }
  }

  generateSeoTags() {
    this.seo.generateTags({
      title: `Poll Sports Fantasy Football Accuracy Contests`,
      description: `Compete in week ${this._config.WEEK} to be the most accurate by voting on start/sit polls submitted by real fantasy football players.`,
      image: 'https://firebasestorage.googleapis.com/v0/b/poll-sports.appspot.com/o/SNAPSHOT.jpg?alt=media&token=c478ef16-8fd8-4576-bcd8-066de1206d10',
      slug: `communities`
    });
  }

}

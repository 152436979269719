import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseService } from '../services/firebase.service';
import { TrackingService } from '../services/tracking.service';

@Injectable({
  providedIn: 'root'
})
export class UpvoteService {

  constructor(
    public db: AngularFirestore,
    private firebaseService: FirebaseService,
    private trackingService: TrackingService
  ) { }

  getItemVotes(itemId) {
    // Gets total votes
    const hasUpvote = this.db.collection('upvotes').doc(itemId);
    return this.db.collection('upvotes').doc(itemId).valueChanges();
  }

  updateUserVote(userVote) {
    // Creates or updates user's vote
    let data = {}
    data[userVote.userId] = userVote.vote
    const voteDetails = {
      userId: userVote.userId,
      recipientId: userVote.recipientId,
      senderUsername: userVote.senderUsername,
      pollKey: userVote.pollKey,
      sport: userVote.sport,
      commentId: userVote.itemId
    };
    this.db.collection('upvotes').doc(userVote.itemId).update(data).then(() => {
      this.completeUpvote(userVote, voteDetails);
    }).catch(err => {
      this.db.collection('upvotes').doc(userVote.itemId).set(data);
      this.completeUpvote(userVote, voteDetails);
    });
  }

  sendLikeMessage(voteDetails) {
    this.firebaseService.createMessage({
      recipientId: voteDetails.recipientId,
      senderId: voteDetails.senderUsername,
      senderUid: voteDetails.userId,
      type: 4,
      poll: voteDetails.pollKey,
      sport: voteDetails.sport,
      commentId: voteDetails.commentId
    });
  }

  trackLike(voteDetails) {
    this.trackingService.track('Like Comment', {
      senderId: voteDetails.senderUsername,
      senderUid: voteDetails.userId,
      recipientId: voteDetails.recipientId,
      poll: voteDetails.pollKey,
    });
  }

  completeUpvote(userVote, voteDetails) {
    if (userVote.vote === 1) {
      this.sendLikeMessage(voteDetails);
      this.trackLike(voteDetails);
      this.firebaseService.incrementCommentLike(voteDetails.commentId);
    } else {
      this.firebaseService.decrementCommentLike(voteDetails.commentId);
    }
  }
}

import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseService } from '../services/firebase.service';
import { PollFormComponent } from '../poll-form/poll-form.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NavbarService } from '../services/navbar.service';
import { first } from "rxjs/operators";

import { environment } from '../../environments/environment';
import * as Stripe from 'stripe';

declare var Stripe:any;

@Component({
  selector: 'app-send-poll-checkout',
  templateUrl: './send-poll-checkout.component.html',
  styleUrls: ['./send-poll-checkout.component.scss']
})
export class SendPollCheckoutComponent implements OnInit {

  @Input() amount: number = 0;
  @Input() description: string = "Send a poll";
  @ViewChild('cardElement') cardElement: ElementRef;

  @ViewChild(PollFormComponent)
  private pollForm: PollFormComponent;

  currentUser:any;
  stripe;
  card;
  cardErrors;
  confirmation
  loading:boolean;
  isFormDisabled:boolean = true;
  slug:string;
  fanPageOwner:any;
  isFree:boolean = false;

  constructor(
    private router: Router,
    private functions: AngularFireFunctions,
    private afAuth: AngularFireAuth,
    public nav: NavbarService,
    private route: ActivatedRoute,
    private firebaseService: FirebaseService
  ) { }

  ngOnInit() {
    this.nav.hide();

    this.route.params.subscribe(params => {
      this.slug = params.id.replace(/ +/g, "");
      this.firebaseService.getUserBySlug(this.slug)
        .pipe(first()).subscribe((response: any) => {
          const user = response[0];
          this.fanPageOwner = user.payload.doc.data();
          this.fanPageOwner.id = user.payload.doc.id;
          this.amount = this.fanPageOwner.sendInventory && this.fanPageOwner.sendInventory ? this.fanPageOwner.sendInventory.price : this.amount;
          this.isFree = this.amount > 0 ? false : true;

          this.afAuth.authState.subscribe(user => {
            this.currentUser = user;

            if (!this.isFree) {
              this.stripe = Stripe(environment.stripeKey);
              const elements = this.stripe.elements();

              this.card = elements.create('card');
              this.card.mount(this.cardElement.nativeElement);

              this.card.addEventListener('change', ({ error }) => {
                this.cardErrors = error && error.message;
              });
            }
          });

        });
    });

  }

  async handleForm(e) {
    e.preventDefault();
    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      this.cardErrors = error.message;
    } else {
      this.loading = true;
      const fun = this.functions.httpsCallable('stripeCreateCharge');
      this.confirmation = await fun({ source: source.id, uid: this.currentUser.uid, amount: this.amount }).toPromise();
      this.pollForm.createPoll(this.fanPageOwner);
      this.loading = false;
      this.router.navigate(['/polls'], { queryParams: { showSuccess: true }});
    }
  }

  isDisabled(event) {
    this.isFormDisabled = event;
  }

  sendFreePoll(e) {
    e.preventDefault();
    this.loading = true;
    this.pollForm.createPoll(this.fanPageOwner);
    this.loading = false;
    this.router.navigate(['/polls'], { queryParams: { showSuccess: true }})
  }

}

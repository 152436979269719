import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { first } from "rxjs/operators";

@Component({
  selector: 'app-edit-fan-page',
  templateUrl: './edit-fan-page.component.html',
  styleUrls: ['./edit-fan-page.component.scss']
})
export class EditFanPageComponent implements OnInit {

  fanPageOwner:any;
  slug:string;
  allowed:any;
  price:any;
  twitter:string = "";
  facebook:string = "";
  youtube:string = "";
  website:string = "";
  instagram:string = "";
  podcast:string = "";
  msgs:any[] = [];

  constructor(
    private route: ActivatedRoute,
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    private router: Router
  ) { }

  ngOnInit() {
    this.fanPageOwner = { profileExcerpt: '', sendInventory: {}, socialLinks: {} };
    this.fanPageOwner.sendInventory.allowed = 1000;
    this.fanPageOwner.sendInventory.price = 0;
    this.route.params.subscribe(params => {
      this.slug = params.id.replace(/ +/g, "");
      this.firebaseService.getUserBySlug(this.slug)
        .pipe(first()).subscribe((response: any) => {
          const user = response[0];
          this.fanPageOwner = user.payload.doc.data();
          this.fanPageOwner.id = user.payload.doc.id;
          const admins = ["TuK223ZDk9XtTfAJWW1eL85KHjg1", "Wez0HtM8lsbImfrgufKfTaUb4AI3"];
          this.afAuth.authState.subscribe((currentUser) => {
            if (!admins.includes(currentUser.uid) && currentUser.uid !== user.payload.doc.id) {
              debugger;
              this.router.navigate([`/`])
              return;
            }
            this.allowed = this.fanPageOwner.sendInventory && this.fanPageOwner.sendInventory.allowed ? this.fanPageOwner.sendInventory.allowed : 100;
            this.price = this.fanPageOwner.sendInventory && this.fanPageOwner.sendInventory.price ? this.fanPageOwner.sendInventory.price : 0;
            this.twitter = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.twitter ? this.fanPageOwner.socialLinks.twitter : '';
            this.facebook = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.facebook ? this.fanPageOwner.socialLinks.facebook : '';
            this.youtube = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.youtube ? this.fanPageOwner.socialLinks.youtube : '';
            this.website = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.website ? this.fanPageOwner.socialLinks.website : '';
            this.instagram = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.instagram ? this.fanPageOwner.socialLinks.instagram : '';
            this.podcast = this.fanPageOwner.socialLinks && this.fanPageOwner.socialLinks.podcast ? this.fanPageOwner.socialLinks.podcast : '';
          });
        });
    });
  }

  save() {
    const data = {
      profileExcerpt: this.fanPageOwner.profileExcerpt,
      phone: this.fanPageOwner.phone,
      ['sendInventory.allowed']: typeof this.allowed === "string" ? parseInt(this.allowed) : this.allowed,
      ['sendInventory.price']: typeof this.price === "string" ? parseInt(this.price) : this.price,
      ['socialLinks.twitter']: this.twitter,
      ['socialLinks.facebook']:  this.facebook,
      ['socialLinks.youtube']: this.youtube,
      ['socialLinks.website']: this.website,
      ['socialLinks.instagram']: this.instagram,
      ['socialLinks.podcast']: this.podcast
    };

    this.firebaseService.updateUser(this.fanPageOwner.id, data);
    this.msgs.push({severity:'success', summary:'Success', detail:'Fan page saved.'});
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { VoteHistoryComponent } from './vote-history/vote-history.component';
import { PollHistoryComponent } from './poll-history/poll-history.component';
import { PollShowComponent } from './poll-show/poll-show.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent} from "./home/home.component";
import { VoteHistoryPersonalComponent } from "./vote-history-personal/vote-history-personal.component";
import { StatsComponent } from "./stats/stats.component";
import { PartnersComponent } from "./partners/partners.component";
import { LeaderboardComponent } from "./leaderboard/leaderboard.component";
import { AccuracyComponent } from "./accuracy/accuracy.component";
import { LeaguesComponent } from "./leagues/leagues.component";
import { PollsIndexComponent } from "./polls-index/polls-index.component";
import { EmbedComponent } from "./embed/embed.component";
import { DataComponent } from "./data/data.component";
import { ApiAccessComponent } from "./api-access/api-access.component";
import { FanPageComponent } from "./fan-page/fan-page.component";
import { SendPollCheckoutComponent } from "./send-poll-checkout/send-poll-checkout.component";
import { EditFanPageComponent } from "./edit-fan-page/edit-fan-page.component";
import { GroupIndexComponent } from "./group-index/group-index.component";
import { GroupShowComponent } from "./group-show/group-show.component";

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full'},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'api-access', component: ApiAccessComponent},
  { path: 'terms-of-service', component: TermsComponent},
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'stats', component: StatsComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'leaderboard', component: LeaderboardComponent },
  { path: 'accuracy', component: AccuracyComponent },
  { path: 'embed', component: EmbedComponent },
  { path: 'feed',
    children: [
      { path: 'football', component: DashboardComponent },
      { path: 'baseball', component: DashboardComponent }
    ]
  },
  { path: 'search', component: VoteHistoryComponent },
  { path: 'pickem', component: LeaguesComponent },
  { path: 'my-votes', component: VoteHistoryPersonalComponent },
  { path: 'polls', component: PollHistoryComponent },
  { path: 'polls-index', component: PollsIndexComponent },
  { path: 'user',
    children: [
      { path: ':id', component: ProfileComponent }
    ]
  },
  { path: 'send',
    children: [
      { path: ':id', component: FanPageComponent }
    ]
  },
  {
    path: 'send',
    children: [
      { path: ':id',
        children: [
          { path: 'checkout', component: SendPollCheckoutComponent },
          { path: 'edit', component: EditFanPageComponent }
        ]
     }
    ]
  },
  { path: 'poll',
    children: [
      { path: 'football',
        children: [
          { path: ':id', component: PollShowComponent }
        ]
      },
      { path: 'baseball',
        children: [
          { path: ':id', component: PollShowComponent }
        ]
      }
    ]
  },
  { path: 'data', component: DataComponent },
  { path: 'communities', component: GroupIndexComponent },
  { path: 'communities',
    children: [
      { path: ':id',
        component: GroupShowComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor(private seo: SeoService, public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Terms of Service',
      description: `Poll Sports Terms of Service.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `terms-of-service`
    });
  }

}

import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private title: Title) { }

  generateTags(config) {
    // default values
    // config = {
    //   title: 'Poll Sports',
    //   description: 'Instant fantasy football adivce through real-time polls',
    //   image: 'https://pollsports.com/assets/logo-blue-prod.png',
    //   slug: ''
    // }
    this.title.setTitle(config.title);
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@pollsports' });
    this.meta.updateTag({ name: 'twitter:creator', content: '@pollsportsapp' });
    this.meta.updateTag({ name: 'twitter:title', content: config.title });
    this.meta.updateTag({ name: 'twitter:description', content: config.description });
    this.meta.updateTag({ name: 'twitter:image', content: config.image });

    this.meta.updateTag({ name: 'Description', content: config.description });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Poll Sports' });
    this.meta.updateTag({ property: 'og:title', content: config.title });
    this.meta.updateTag({ property: 'og:description', content: config.description });
    this.meta.updateTag({ property: 'og:image', content: config.image });
    this.meta.updateTag({ property: 'og:url', content: `https://pollsports.com/${config.slug}` });
  }
}

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppConfig } from '../app-config';
import { AngularFireFunctions } from '@angular/fire/functions';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipsModule } from 'primeng/chips';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ListboxModule } from 'primeng/listbox';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { SidebarModule } from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { BlockUIModule } from 'primeng/blockui';
import { TabMenuModule } from 'primeng/tabmenu';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { CarouselModule } from 'primeng/carousel';
import { MomentModule } from 'ngx-moment';
import { NgxPaginationModule } from 'ngx-pagination'; // <-- import the module
import { InputTextareaModule } from 'primeng/inputtextarea';
import bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'



import { environment } from '../environments/environment';
import { PollComponent } from './poll/poll.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserFormComponent } from './user-form/user-form.component';
import { HeaderComponent } from './header/header.component';
import { VoteHistoryComponent } from './vote-history/vote-history.component';
import { MenuComponent } from './menu/menu.component';
import { PollHistoryComponent } from './poll-history/poll-history.component';
import { PollFormComponent } from './poll-form/poll-form.component';
import { PollShowComponent } from './poll-show/poll-show.component';
import { CommentsContainerComponent } from './comments-container/comments-container.component';
import { CommentComponent } from './comment/comment.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { VotesContainerComponent } from './votes-container/votes-container.component';
import { VoteComponent } from './vote/vote.component';
import { FilterComponent } from './filter/filter.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { PointsDialogComponent } from './points-dialog/points-dialog.component';
import { FooterComponent } from './footer/footer.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PollLegendComponent } from './poll-legend/poll-legend.component';
import { UpvoteButtonComponent } from './upvote-button/upvote-button.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { StatsComponent } from './stats/stats.component';
import { PartnersComponent } from './partners/partners.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { EmbedComponent } from './embed/embed.component';
import { AccuracyComponent } from './accuracy/accuracy.component';
import { VoteHistoryPersonalComponent } from './vote-history-personal/vote-history-personal.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { LeaguesComponent } from './leagues/leagues.component';
import { DataComponent } from './data/data.component';
import { ApiAccessComponent } from './api-access/api-access.component';
import { FanPageComponent } from './fan-page/fan-page.component';
import { SendPollCheckoutComponent } from './send-poll-checkout/send-poll-checkout.component';
import { EditFanPageComponent } from './edit-fan-page/edit-fan-page.component';
import { InfoBlurbComponent } from './info-blurb/info-blurb.component';
import { PollsIndexComponent } from './polls-index/polls-index.component';
import { GroupIndexComponent } from './group-index/group-index.component';
import { GroupShowComponent } from './group-show/group-show.component';

export function initConfig(config: AppConfig){return () => config.load()}

const bugsnagClient = bugsnag('649ea55d54a77774fb4571bc2879a68e')

export function errorHandlerFactory() {
  return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
  declarations: [
    AppComponent,
    PollComponent,
    DashboardComponent,
    UserFormComponent,
    HeaderComponent,
    VoteHistoryComponent,
    MenuComponent,
    PollHistoryComponent,
    PollFormComponent,
    PollShowComponent,
    CommentsContainerComponent,
    CommentComponent,
    VotesContainerComponent,
    VoteComponent,
    FilterComponent,
    MobileMenuComponent,
    PointsDialogComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    CookiePolicyComponent,
    PollLegendComponent,
    UpvoteButtonComponent,
    ProfileComponent,
    HomeComponent,
    StatsComponent,
    PartnersComponent,
    PlayerDetailsComponent,
    LeaderboardComponent,
    EmbedComponent,
    AccuracyComponent,
    VoteHistoryPersonalComponent,
    CheckoutComponent,
    LeaguesComponent,
    DataComponent,
    ApiAccessComponent,
    FanPageComponent,
    SendPollCheckoutComponent,
    EditFanPageComponent,
    InfoBlurbComponent,
    PollsIndexComponent,
    GroupIndexComponent,
    GroupShowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features,
    DialogModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule,
    SelectButtonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ChipsModule,
    ConfirmDialogModule,
    ListboxModule,
    CardModule,
    SidebarModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    BlockUIModule,
    TabMenuModule,
    DropdownModule,
    MultiSelectModule,
    MessagesModule,
    MessageModule,
    TooltipModule,
    PanelMenuModule,
    ToastModule,
    CarouselModule,
    TableModule,
    NgxPaginationModule,
    InputTextareaModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        'm': 59
      }
    })
  ],
  providers: [
    AppConfig,
    ConfirmationService,
    MessageService,
    AngularFireFunctions,
    Title,
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

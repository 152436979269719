import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app-config';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import * as firebase from 'firebase';

const scheduleDates = {
  "2020": {
    "week1": {
      startDate: "09/08/20",
      endDate: "09/15/20"
    },
    "week2": {
      startDate: "09/15/20",
      endDate: "09/22/20"
    },
    "week3": {
      startDate: "09/22/20",
      endDate: "09/29/20"
    },
    "week4": {
      startDate: "09/29/20",
      endDate: "10/06/20"
    },
    "week5": {
      startDate: "10/06/20",
      endDate: "10/13/20"
    },
    "week6": {
      startDate: "10/13/20",
      endDate: "10/20/20"
    },
    "week7": {
      startDate: "10/20/20",
      endDate: "10/27/20"
    },
    "week8": {
      startDate: "10/27/20",
      endDate: "11/03/20"
    },
    "week9": {
      startDate: "11/03/20",
      endDate: "11/10/20"
    },
    "week10": {
      startDate: "11/10/20",
      endDate: "11/17/20"
    },
    "week11": {
      startDate: "11/17/20",
      endDate: "11/24/20"
    },
    "week12": {
      startDate: "11/24/20",
      endDate: "12/01/20"
    },
    "week13": {
      startDate: "12/01/20",
      endDate: "12/08/20"
    },
    "week14": {
      startDate: "12/08/20",
      endDate: "12/15/20"
    },
    "week15": {
      startDate: "12/15/20",
      endDate: "12/22/20"
    },
    "week16": {
      startDate: "12/22/20",
      endDate: "12/29/20"
    },
    "week17": {
      startDate: "12/29/20",
      endDate: "01/04/21"
    }
  }
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  constructor(
    public db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private _config: AppConfig
  ) { }

  hasVisitedToday() {
    return new Promise((resolve, reject) => {
      const uid = this.afAuth.auth.currentUser.uid;
      const start = new Date(new Date().setHours(0,0,0,0));
      const end = new Date(new Date().setHours(23,59,59,999));
      this.db.collection('visits', ref => ref
        .where('createdAt', '>', start)
        .where('createdAt', '<', end)
        .where('uid', '==', uid)
      ).snapshotChanges().pipe(first()).subscribe(visits => {
        if (visits && visits.length > 0) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  createGroup(group) {
    return new Promise((resolve, reject) => {
      this.db.collection('groups').add({
        name: group.name,
        slug: group.name.replace(/\s/g, '').toLowerCase().replace(/[^\w\s]/gi, ''),
        private: true,
        creatorUid: group.creatorUid,
        memberIds: [group.creatorUid],
        members: [{uid: group.creatorUid, username: group.creatorUsername}],
        memberCount: 1,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      }).then((createdGroup) => {
        this.createContest({groupId: createdGroup.id, groupName: group.name}).then((createdContest) => {
          resolve({groupId: createdGroup.id, name: group.name});
        });
      }).catch((err) => {
        resolve(err);
      });
    });
  }

  getPublicGroups() {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      let query = db.collection("groups")
        .where('private', '==', false)
        .orderBy('memberCount', 'desc')

      query.get({source: 'server'}).then(async (documentSnapshots) => {
        resolve(documentSnapshots.docs);
      });
    });
  }

  getUserGroups(uid) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      let query = db.collection("groups")
        .where('memberIds', 'array-contains', uid)
        .orderBy('memberCount', 'desc')

      query.get({source: 'server'}).then(async (documentSnapshots) => {
        resolve(documentSnapshots.docs);
      });
    });
  }

  getGroup(id) {
    return this.db.collection('groups').doc(id).snapshotChanges();
  }

  createContest(group) {
    return new Promise((resolve, reject) => {
      const week = this._config.WEEK;
      const yearString = environment.currentYear;
      const startDate = scheduleDates[yearString]["week"+week].startDate.split("/");
      const year = parseInt(yearString);
      const month = parseInt(startDate[0])-1;
      const day = parseInt(startDate[1]);
      const start = new Date(year, month, day, 14, 0, 0).valueOf();

      const endDate = scheduleDates[yearString]["week"+week].endDate.split("/");
      const endMonth = parseInt(endDate[0])-1;
      const endDay = parseInt(endDate[1]);
      const end = new Date(year, endMonth, endDay, 0, 0, 0).valueOf();

      const af = this.db.collection('contests').doc(`${group.groupId}_${year}_${week}`);
      af.set({
        groupId: group.groupId,
        startDate: start,
        endDate: end,
        week: week,
        groupName: group.groupName,
        year: year,
        custom: false,
        results: [],
        pollIds: [],
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      }).then(() => {
        af.ref.get().then(doc => {
          resolve(doc.data())
        });
      }).catch((err) => {
        reject();
      });
    });
  }

  getContest(contest) {
    return this.db.collection('contests').doc(`${contest.groupId}_${contest.year}_${contest.week}`).snapshotChanges();
  }

  getOrCreateContest(contest) {
    const week = this._config.WEEK;
    const year = environment.currentYear;
    return new Promise((resolve, reject) => {
      const getContest = this.db.collection('contests').doc(`${contest.groupId}_${year}_${week}`).snapshotChanges();
      getContest.pipe(first()).subscribe((fetchedContest) => {
        if (!fetchedContest.payload.exists) {
          this.createContest(contest).then((createdContest) => {
            resolve(createdContest)
          });
        } else {
          resolve(fetchedContest.payload.data())
        }
      });
    })
  }

  getPreviousWeeksContest(contest) {
    let week;
    if (contest.week) {
      week = contest.week
    } else {
      week = this._config.WEEK - 1;
    }
    const year = environment.currentYear;
    return new Promise((resolve, reject) => {
      const getContest = this.db.collection('contests').doc(`${contest.groupId}_${year}_${week}`).snapshotChanges();
      getContest.pipe(first()).subscribe((fetchedContest) => {
        if (!fetchedContest.payload.exists) {
          resolve(false);
        } else {
          resolve(fetchedContest.payload.data());
        }
      });
    });
  }

  getOrCreateGlobalContest(defaultWeek=null) {
    return new Promise((resolve, reject) => {
      const week = defaultWeek ? defaultWeek : this._config.WEEK;
      const year = environment.currentYear;
      const getGlobalContest = this.db.collection('contests_global').doc(`${year}_${week}`).snapshotChanges();
      getGlobalContest.pipe(first()).subscribe((fetchedGlobalContest) => {
        if (!fetchedGlobalContest.payload.exists) {
          this.createGlobalContest().then((createdGlobalContest) => {
            resolve(createdGlobalContest)
          });
        } else {
          resolve(fetchedGlobalContest.payload.data())
        }
      });
    })
  }

  createGlobalContest() {
    return new Promise((resolve, reject) => {
      const week = this._config.WEEK;
      const yearString = environment.currentYear;
      const year = parseInt(yearString);
      const af = this.db.collection('contests_global').doc(`${year}_${week}`);

      const getGlobalContest = af.snapshotChanges();
      // Doing this twice b/c in prod a global contest got wipped clean and re-created.
      getGlobalContest.pipe(first()).subscribe((fetchedGlobalContest) => {
        if (!fetchedGlobalContest.payload.exists) {
          af.set({
            pollIds: [],
            week: week,
            year: year,
            scoredChoices: {},
            userScores: {},
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(() => {
            af.ref.get().then(doc => {
              resolve(doc.data())
            });
          }).catch((err) => {
            reject();
          });
        } else {
          resolve(fetchedGlobalContest.payload.data())
        }
      });
    });
  }

  joinGroup(groupId, user) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      const groupRef = db.collection("groups").doc(groupId);
      db.runTransaction(transaction => {
        return transaction.get(groupRef).then(doc => {
          let groupData = doc.data();
          let memberIds = groupData.memberIds;
          let members = groupData.members;
          let memberCount = memberIds.length+1;

          if (!memberIds.includes(user.uid)) {
            memberIds.push(user.uid);
            members.push(user)
          }

          transaction.update(groupRef, { memberIds: memberIds, members: members, memberCount:  memberCount});
        });
      }).then(() => {
        resolve()
      });
    });
  }

  addGlobalContestPoll(pollKey) {
    return new Promise((resolve, reject) => {
      const week = this._config.WEEK;
      const yearString = environment.currentYear;
      const year = parseInt(yearString);
      const db = firebase.firestore();
      const globalContestRef = db.collection("contests_global").doc(`${year}_${week}`);
      db.runTransaction(transaction => {
        return transaction.get(globalContestRef).then(doc => {
          let globalContestData = doc.data();
          let pollIds = globalContestData.pollIds;
          pollIds.indexOf(pollKey) === -1 ? pollIds.push(pollKey) : resolve(false);
          transaction.update(globalContestRef, { pollIds: pollIds });
        });
      }).then(() => {
        resolve()
      });
    });
  }

  removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b)
  };

  removeGlobalContestPoll(pollKey) {
    return new Promise((resolve, reject) => {
      const week = this._config.WEEK;
      const yearString = environment.currentYear;
      const year = parseInt(yearString);
      const db = firebase.firestore();
      const globalContestRef = db.collection("contests_global").doc(`${year}_${week}`);
      db.runTransaction(transaction => {
        return transaction.get(globalContestRef).then(doc => {
          let globalContestData = doc.data();
          let pollIds = globalContestData.pollIds;
          const pollKeyIndex = pollIds.indexOf(pollKey);
          if (pollKeyIndex > -1) {
            pollIds.splice(pollKeyIndex, 1);
          }
          transaction.update(globalContestRef, { pollIds: pollIds });
        });
      }).then(() => {
        resolve()
      });
    });
  }

  createVisit() {
    const uid = this.afAuth.auth.currentUser.uid;
    const timestamp = firebase.firestore.FieldValue.serverTimestamp();
    this.db.collection('visits').doc(`${uid}_${Date.now()}`).set({
      uid: uid,
      createdAt: timestamp
    }).then(() => {
      this.createNotification({
        recipientId: uid,
        senderId: '',
        type: 6,
        poll: '',
        sport: '',
        senderUid: uid
      });
    })
    .catch(err => {
      console.log("Error creating visit", err);
    });
  }

  getVoteHistory(week) {
    const uid = this.afAuth.auth.currentUser.uid;
    return new Promise((resolve, reject) => {

      const db = firebase.firestore();
      let dateOfPollVoteUpdate = new Date(new Date("06/24/19").setHours(0,0,0,0))
      let query = db.collection("votes")
        .where('uid', '==', uid)
        .where('createdAt', '>', dateOfPollVoteUpdate)
        .where('week', '==', week)
        .orderBy('createdAt', 'desc')

      query.get({source: 'server'}).then(async (documentSnapshots) => {
        const docs = documentSnapshots.docs;
        let polls = [];
        if (docs.length > 0) {
          for (const vote of docs) {
            const pollId = await vote.get('poll');
            this.db.collection('polls').doc(pollId).snapshotChanges().pipe(first()).subscribe((poll) => {
              polls.push(poll);
            });
          }
        }
        resolve({polls: polls});
      });
    });
  }

  getSimilarPolls(pollType, playerName) {
    let dateOfPollVoteUpdate = new Date(new Date("06/24/19").setHours(0,0,0,0))
    return this.db.collection('polls', ref => ref
      .where('players', 'array-contains', playerName)
      .where('pollType', '==', pollType)
      .where('expired', '==', false)
      .where('createdAt', '>', dateOfPollVoteUpdate)
      .orderBy('createdAt', 'desc'))
      .snapshotChanges();
  }

  getDuplicatePolls(queryParams) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      let query = db.collection("polls").orderBy('createdAt', 'desc')
      const titleQueries = ['SIUW', 'SIDPFP', 'PRICECHECK', 'FAAB'];
      const queryByTitle = titleQueries.includes(queryParams.pollType);
      const isDynasty = queryParams.tags.some(tag => tag.code === 'DYNASTY');
      const isKeeper = queryParams.tags.some(tag => tag.code === 'KEEPER');
      if (queryByTitle) {
        query = query.where('title', '==', queryParams.title)
      } else {
        query = query.where('choicesQuery', '==', queryParams.choicesQuery)
      }
      if (isDynasty) {
        query = query.where('tags', 'array-contains', {code: "DYNASTY", name: "Dynasty"})
      } else if (isKeeper) {
        query = query.where('tags', 'array-contains', {code: "KEEPER", name: "Keeper"})
      } else {
        query = query.where('tags', 'array-contains', {code: "REDRAFT", name: "Redraft"})
      }
      if (queryParams.pollType) { query = query.where('pollType', '==', queryParams.pollType) };
      query = query.where('expired', '==', false);
      query.get({source: 'server'}).then(async (documentSnapshots) => {
        resolve({polls: documentSnapshots.docs});
      }).catch(err => console.log(err));
    });
  }

  getPollsById(ids) {
    return new Promise((resolve, reject) => {
      let polls = []
      let cnt = 0
      ids.forEach(async (pollId) => {
        const pollDoc = await this.db.collection('polls').doc(pollId);
        pollDoc.ref.get().then(async (poll) => {
          polls.push(poll);
          if (cnt >= ids.length-1) {
            resolve(polls)
          }
          cnt++
        });
      });
    })
  }

  getPolls(poll) {
    return new Promise((resolve, reject) => {

      const db = firebase.firestore();
      // Default to football
      poll.sport = 1;
      let query = db.collection("polls").orderBy('createdAt', 'desc')
      let season2020 = new Date(new Date("01/01/20").setHours(21,0,0,0));
      if (poll.pollTypes) {
        poll.pollTypes.forEach(async (type) => {
          query = await query.where('pollType', '==', type) ;
        });
      }
      if (poll.getActive) { query = query.where('expired', '==', false) };
      if (poll.league) { query = query.where('league', '==', true) };
      if (poll.uid) { query = query.where('uid', '==', poll.uid) };
      if (poll.sport) { query = query.where('sport', '==', poll.sport) };
      if (poll.limit) { query = query.limit(poll.limit) };
      if (poll.type) { query = query.where('pollType', '==', poll.type) };
      if (poll.week) { query = query.where('week', '==', poll.week) };
      if (poll.boosted) { query = query.where('boosted', '==', true) };
      if (poll.recipientId) { query = query.where('recipientIds', 'array-contains', poll.recipientId)}
      // ToDo: This doesn't work. Why?
      if (poll.lastVisible) { query.startAfter(poll.lastVisible) };

      if (poll.thisSeason) {
        query = query.where('createdAt', '>', season2020)
      }
      if (poll.searchResults) {
        let offseasonDate = new Date(new Date("07/01/20").setHours(0,0,0,0))
        query = query.where('createdAt', '>', offseasonDate)
      }
      query.get().then(async (documentSnapshots) => {
        var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        resolve({polls: documentSnapshots.docs, lastVisible: lastVisible});
      }).catch(err => console.log(err));
    });
  }

  getMostRecentPoll() {
    return new Promise((resolve, reject) => {

      const db = firebase.firestore();
      // Default to football
      let query = db.collection("polls").orderBy('createdAt', 'desc')
      query = query.limit(1)
      query = query.where('expired', '==', false)
      query.get({source: 'server'}).then(async (documentSnapshots) => {
        resolve({poll: documentSnapshots.docs[documentSnapshots.docs.length-1]});
      }).catch(err => console.log(err));
    });
  }

  getStats() {
    return this.db.collection('stats').doc("count").snapshotChanges();
  }

  // duplicate function to get total polls for stats purposes.
  getStatsPolls(poll) {
    return new Promise((resolve, reject) => {

      const db = firebase.firestore();
      // Default to football
      poll.sport = 1;
      let query = db.collection("polls").orderBy('createdAt', 'desc')
      if (poll.uid) { query = query.where('uid', '==', poll.uid) };
      if (poll.sport) { query = query.where('sport', '==', poll.sport) };
      if (poll.limit) { query = query.limit(poll.limit) };
      // ToDo: This doesn't work. Why?
      if (poll.lastVisible) { query.startAfter(poll.lastVisible) };
      query.get({source: 'server'}).then(async (documentSnapshots) => {
        var lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
        resolve({polls: documentSnapshots.docs, lastVisible: lastVisible});
      }).catch(err => console.log(err));
    });
  }

  getUsers(user) {
    return this.db.collection('users', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (user.uid) { query = query.where('uid', '==', user.uid) };
      if (user.limit) { query = query.limit(user.limit) };
      if (user.pro) { query = query.where('isPro', '==', true) };
      if (user.orderBy) {
        query = query.orderBy(user.orderBy, 'desc')
      } else {
        query = query.orderBy('createdAt', 'desc')
      }
      return query
    }).snapshotChanges();
  }

  dashboardSubscription(poll) {
    let sport = poll.sport ? poll.sport : 1
    return this.db.collection('polls', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (poll.uid) { query = query.where('uid', '==', poll.uid) };
      if (poll.recipientId) { query = query.where('recipientIds', 'array-contains', poll.recipientId) };
      query = query.where('sport', '==', sport).orderBy('createdAt', 'desc')
      if (poll.limit) { query = query.limit(poll.limit) };
      return query;
    }).stateChanges();
  }

  pollHistorySubscription(uid) {
    return this.db.collection('polls', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('uid', '==', uid).orderBy('createdAt', 'desc')
      return query;
    }).stateChanges();
  }

  getPoll(pollKey) {
    return this.db.collection('polls').doc(pollKey).snapshotChanges();
  }

  getComments(comment) {
    return this.db.collection('comments', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (comment.poll) { query = query.where('poll', '==', comment.poll) };
      if (comment.uid) { query = query.where('uid', '==', comment.uid) };
      if (comment.awarded) { query = query.where('awarded', '==', comment.awarded) };
      if (comment.limit) { query = query.limit(comment.limit) };
      // May need to revisit and makes sure nested awarded comments show up in profile page.
      // query = query.where('parent', '==', '')
      if (comment.parent) { query.where('parent', '==', comment.parent) };
      query = query.orderBy('createdAt')
      return query;
    }).snapshotChanges();
  }

  getAwardedComments(uid) {
    return this.db.collection('comments', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('uid', '==', uid).where('awarded', '==', true)
      return query;
    }).snapshotChanges();
  }

  getComment(commendId) {
    return this.db.collection('comments').doc(commendId).snapshotChanges();
  }

  // Two functions until firebase starts supporting OR operator for queries
  getNotifications() {
    return this.db.collection('notifications', ref => ref.where('recipientId', '==', 'all').where('active', '==', true).orderBy('createdAt', 'desc')).snapshotChanges();
  }
  getUserNotifications(uid) {
    return this.db.collection('notifications', ref => ref.where('recipientId', '==', uid).where('active', '==', true).orderBy('createdAt', 'desc')).snapshotChanges();
  }

  createNotification(notification) {
    let body;
    let title = 'New Comment'
    switch(notification.type) {
      case 1:
        body = `${notification.senderId} commented on your poll.`;
        break;
      case 2:
        body = `${notification.senderId} replied to your comment.`;
        break;
      case 3:
        body = `${notification.senderId} awarded your comment as a top comment. You just earned 100 credibility points!`;
        title = 'You got an award!';
        break;
      case 4:
        body = `${notification.senderId} liked your comment. You just earned 10 credibility points!`;
        title = 'New Like';
        break;
      case 5:
        body = `${notification.senderId} commented on a poll you're following.`;
        title = 'New Comment';
        break;
      case 6:
        body = `You just earned 10 credibility points for visitng today. Earn 1 point for every poll that you vote on :)`;
        title = 'Credibility +10!'
        break;

    }
    if (notification.type === 4) {
      // check if notification already exists
      this.db.collection('notifications', ref => ref
        .where('recipientId', '==', notification.recipientId)
        .where('senderUid', '==', notification.senderUid)
        .where('poll', '==', notification.poll)
        .where('commentId', '==', notification.commentId)
        .where('type', '==', 4)
      ).snapshotChanges().pipe(first()).subscribe((notifications) => {
        if (notifications.length > 0) {
          return;
        } else {
          this.db.collection('notifications').add({
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            active: true,
            recipientId: notification.recipientId,
            senderId: notification.senderId,
            senderUid: notification.senderUid,
            poll: notification.poll,
            sport: notification.sport,
            type: notification.type,
            commentId: notification.commentId,
            message: `<b>${title}</b><p>${body}</p>`
          });
        }
      });
    } else {
      this.db.collection('notifications').add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        active: true,
        recipientId: notification.recipientId,
        senderId: notification.senderId,
        senderUid: notification.senderUid,
        poll: notification.poll,
        sport: notification.sport,
        type: notification.type,
        message: `<b>${title}</b><p>${body}</p>`
      });
    }
  }

  getNotificationUser(key) {
    return this.db.collection('notification_users').doc(key).snapshotChanges();
  }

  dismissNotification(value) {
    return new Promise((resolve, reject) => {
      this.db.collection('notification_users').doc(`${value.notificationId}_${value.uid}`).set({
        notificationId: value.notificationId,
        uid: value.uid,
        dismissedAt: firebase.firestore.FieldValue.serverTimestamp()
      }).then((notificationDismissed) => {
        this.deactivateNotification(value.notificationId).then(() => {
          resolve()
        })
      }).catch(err => {
        console.log("Error dismissing notification", err);
      });
    })
  }

  deactivateNotification(notificationId) {
    return new Promise((resolve, reject) => {
      const notificationDoc = this.db.collection('notifications').doc(notificationId);
      notificationDoc.ref.get().then((notification) => {
        const notificationData = notification.data();
        const recipientId = notificationData.recipientId;
        if (recipientId !== 'all') {
          notificationDoc.update({active: false});
        }
        resolve()
      });
    });
  }

  updateComment(commentId, value) {
    return new Promise((resolve, reject) => {
      this.db.collection('comments').doc(commentId).update(value).then(() => {
        resolve();
      });
    });
  }

  expirePoll(pollId) {
    return new Promise((resolve, reject) => {
      const pollRef = this.db.collection('polls').doc(pollId).ref
      pollRef.get().then(docSnapshot => {
        let expires = docSnapshot.get('expires')
        this.db.collection('polls').doc(pollId).update({expired: true}).then(() => {
          resolve();
        });
      });
    });
  }

  createMessage(message) {
    message.poll = message.poll ? message.poll : '';
    message.sport = message.sport ? message.sport : '';
    this.db.collection('messages').add({
      recipientId: message.recipientId,
      senderId: message.senderId,
      type: message.type,
      senderUid: message.senderUid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    }).then(createdMessage => {
      let notificationDetails:any = {
        recipientId: message.recipientId,
        senderId: message.senderId,
        type: message.type,
        poll: message.poll,
        sport: message.sport,
        senderUid: message.senderUid
      }
      if (message.commentId) {
        notificationDetails.commentId = message.commentId;
      }
      this.createNotification(notificationDetails);
    });
  }

  createComment(comment) {
    return new Promise((resolve, reject) => {
      const uid = this.afAuth.auth.currentUser.uid;
      let parent = comment.parent ? comment.parent : '';
      this.db.collection('comments').add({
        text: comment.text,
        poll: comment.poll,
        user: comment.user,
        uid: uid,
        children: [],
        parent: parent,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      }).then(comment => {
        console.log("Comment created successfully", comment);
        resolve(comment);
      }).catch(err => {
        console.log("Error creating comment", err);
        reject();
      });
    })
  }

  // Generate unqiue ID for poll choices
  // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c:any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }

  updatePoll(pollId, value) {
    this.db.collection('polls').doc(pollId).update(value);
  }

  skipPoll(pollId, uid) {
    return new Promise((resolve, reject) => {
      const db = firebase.firestore();
      const pollRef = db.collection("polls").doc(pollId);

      db.runTransaction(transaction => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(pollRef).then(doc => {
          if (!doc.data().usersWhoHaveVoted) {
            transaction.update(pollRef, {
              usersWhoHaveVoted: [uid]
            });
          } else {
            const usersWhoHaveVoted = doc.data().usersWhoHaveVoted;
            usersWhoHaveVoted.push(uid);
            transaction.update(pollRef, { usersWhoHaveVoted: usersWhoHaveVoted });
          }
        });
      }).then(function () {
        console.log("Transaction successfully committed!");
        resolve();
      }).catch(function (error) {
        console.log("Transaction failed: ", error);
        reject();
      });
    });
  }

  awardComment(pollId, commentId) {
    return new Promise((resolve, reject) => {
      this.db.collection('polls').doc(pollId).update({
        totalCommentsAwarded: firebase.firestore.FieldValue.increment(1),
        commentsAwarded: firebase.firestore.FieldValue.arrayUnion(commentId)
      }).then(() => {
        this.db.collection('comments').doc(commentId).update({
          awarded: true
        }).then(() => {
          resolve();
        })
      })
    })
  }

  incrementCommentLike(commentId) {
    this.db.collection('comments').doc(commentId).update({
      upvotes: firebase.firestore.FieldValue.increment(1)
    });
  }

  decrementCommentLike(commentId) {
    this.db.collection('comments').doc(commentId).update({
      upvotes: firebase.firestore.FieldValue.increment(-1)
    });
  }

  createLeague(league) {
    return new Promise((resolve, reject) => {
      const uid = this.afAuth.auth.currentUser.uid;
      if (league.id) {
        const leagueRef = this.db.collection('leagues').doc(league.id).ref
        leagueRef.get().then(docSnapshot => {
          if (docSnapshot.exists) {
            leagueRef.update({
              uid: uid,
              name: league.name,
              tags: league.tags
            });
          }
        })
      } else {
        this.db.collection('leagues').add({
          uid: uid,
          name: league.name,
          tags: league.tags
        }).then(createdLeague => {
          resolve(createdLeague)
        }).catch(err => console.log("Error creating league", err));
      }
    });
  }

  getLeagues(uid) {
    this.db.collection("leagues")
    return this.db.collection('leagues', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = query.where('uid', '==', uid).orderBy('name', 'asc')
      return query;
    }).snapshotChanges();
  }

  deleteLeague(id) {
    return new Promise((resolve, reject) => {
      this.db.collection("leagues").doc(id).delete().then(() => {
        resolve()
      }).catch(err => reject(err))
    });
  }

  createPoll(poll) {
    return new Promise((resolve, reject) => {
      const userDoc = this.db.collection('users').doc(poll.uid).ref
      userDoc.get().then((user) => {
        const userData = user.data();
        const uid = poll.uid;
        const username = userData.username;
        let pollChoices = [];
        for (const key in poll.choices) {
          let choice = {
            id: this.uuidv4(),
            votes: 0,
            players: poll.choices[key].map((item) => { return item })
          };
          pollChoices.push(choice);
        }
        let meetsRequirement;
        let players = pollChoices.map((choice) => {
          if (choice.players) {
            let displayName = choice.players.map((player) => {
              if (poll.pollType === "WDIS" && player) {
                if (choice.players.length === 1 && player.position !== "DEF" && player.position !== "K" && player.position && player.team) {
                  meetsRequirement = meetsRequirement === false ? false : true;
                } else {
                  meetsRequirement = false;
                }
              }
              if (player) {
                return player.displayName;
              }
            });
            return displayName;
          }
        });
        players = [].concat(...players)
        if (poll.pollType === 'SIDPFP' || poll.pollType === 'SIUW') {
          pollChoices[0].players[0].displayName = "Yes"
          pollChoices[1].players[0].displayName = "No"
        }
        if (poll.pollType === 'SIUW') {
          players[0] = pollChoices[0].players[0].name;
        }

        const isDynasty = poll.tags.some(tag => tag.code === 'DYNASTY');
        const isKeeper = poll.tags.some(tag => tag.code === 'KEEPER');
        let leagueType;
        if (isDynasty) {
          leagueType = 'Dynasty';
        } else if (isKeeper) {
          leagueType = 'Keeper';
        } else {
          leagueType = 'Redraft';
        }

        this.db.collection('polls').add({
          title: poll.title,
          pollType: poll.pollType,
          tags: poll.tags,
          user: username,
          sport: poll.sport,
          winner: null,
          choicesAllowed: poll.choicesAllowed,
          commentTotal: 0,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          choices: pollChoices,
          uid: uid,
          helperText: poll.helperText ? poll.helperText : '',
          players: players,
          allowAnonymousVotes: poll.allowAnonymousVotes,
          expired: false,
          recipientIds: poll.recipientIds,
          year: new Date().getFullYear(),
          choicesQuery: poll.choicesQuery,
          votes: 0,
          isAccuracyEligible: meetsRequirement ? meetsRequirement : false,
          leagueType: leagueType
        }).then(createdPoll => {
          if (createdPoll) {
            const roles = userData.roles;
            const isAdmin = roles && roles.hasOwnProperty('admin') ? roles.admin : false;
            const points = userData.points;
            userDoc.update({
              points: isAdmin ? points : points-environment.points.required,
              recentPoll: firebase.firestore.FieldValue.serverTimestamp()
            });
            if (poll.helperText) {
              this.createComment({
                text: poll.helperText,
                poll: createdPoll.id,
                user: username,
              });
            }
            // HACK. This fixes bug when you try to create another poll with the same player w/o refreshing.
            if (poll.pollType === 'SIDPFP' || poll.pollType === 'SIUW') {
              pollChoices[0].players[0].displayName = `${pollChoices[0].players[0].fname} ${pollChoices[0].players[0].lname}`
              pollChoices[1].players[0].displayName = `${pollChoices[1].players[0].fname} ${pollChoices[1].players[0].lname}`
            }
          }
          resolve(poll);
        }).catch(err => {
          console.log("err", err);
          reject();
        });
      });
    });
  }


  incrementChoice(choiceKey, poll, currentUser, credibility, accuracyTotal=0, isInfluencer=false) {
    const isVoteFromInfluencer = isInfluencer;
    return new Promise((resolve, reject) => {
      const uid = this.afAuth.auth.currentUser.uid;
      const pollDoc:any = this.db.collection('polls').doc(poll.id);
      const voteKey = `${poll.id}_${choiceKey}_${uid}`;
      const isAnonymous = currentUser ? false : true;
      const userCredibility = credibility ? credibility : 0;
      // Make sure that people with 0 accuracy aren't treated as top 1%
      const accuracy_range = this._config.ACCURACY_RANGE ? this._config.ACCURACY_RANGE : [];
      let percentile = accuracyTotal && accuracy_range.length ? 1-(this.percentRank(accuracy_range, accuracyTotal)) : 100;
      percentile = accuracyTotal === 0 || percentile === 0 ? 100 : percentile;
      pollDoc.ref.get().then(poll => {
        this.db.collection('votes').doc(voteKey).valueChanges().pipe(first()).subscribe(async (vote:any) => {
          let pollData = poll.data();
          let pollType = pollData.pollType;
          let isAccuracyEligible = pollData.isAccuracyEligible ? pollData.isAccuracyEligible : false;
          let choicesAllowed = pollData.choicesAllowed;
          let invalid = false;

          // Validate Who Do I Start? polls with multiple answers.
          if (choicesAllowed && choicesAllowed > 1) {
            const votes = await this.db.collection('votes', ref => ref
              .where('poll', '==', poll.id)
              .where('uid', '==', uid)
            ).snapshotChanges().pipe(first()).toPromise();

            if (votes.length + 1 > pollData.choicesAllowed) {
              reject()
              invalid = true;
              return false;
            }
          }

          // Kill switch if WDISM poll fails validation.
          if (invalid) {
            return false;
          }

          if (vote && choicesAllowed === 1) {
            reject()
            return false;
          } else {
            let userCreatedPoll = (pollData.uid == uid);
            let choices = pollData.choices;
            let usersWhoHaveVoted = pollData.usersWhoHaveVoted || [];
            let sport = pollData.sport;
            let week = pollData.week ? pollData.week : this._config.WEEK;
            const choiceIndex = choices.findIndex((choice => choice.id == choiceKey));
            let players = choices[choiceIndex].players;
            const year = environment.currentYear;
            this.createVote({
              key: voteKey,
              choice: choiceKey,
              poll: poll.id,
              user: (isAnonymous ? 'guest-'+uid.substr(-7) : currentUser),
              uid: uid,
              players: players,
              sport: sport,
              credibility: userCredibility,
              pollType: pollType,
              week: week,
              percentile: percentile,
              isInfluencer: isVoteFromInfluencer,
              choicesAllowed: choicesAllowed,
              isAccuracyEligible: isAccuracyEligible,
              year: parseInt(year)
            }).then((createdVote:any) => {

              const db = firebase.firestore();
              const pollRef = db.collection("polls").doc(poll.id);
              db.runTransaction(transaction => {
                return transaction.get(pollRef).then(doc => {
                  let pollData = doc.data();
                  let choices = pollData.choices;
                  choices[choiceIndex].votes = ++choices[choiceIndex].votes;

                  if (!pollData.usersWhoHaveVoted) {
                    transaction.update(pollRef, {
                      usersWhoHaveVoted: [uid],
                      choices: choices
                    });
                  } else {
                    const usersWhoHaveVoted = pollData.usersWhoHaveVoted;
                    usersWhoHaveVoted.push(uid);
                    transaction.update(pollRef, { usersWhoHaveVoted: usersWhoHaveVoted, choices: choices });
                  }
                });
              }).then(() => {
                const userDoc:any = this.db.collection('users').doc(uid);
                if (createdVote.choice) {
                  if (!isAnonymous) {
                    userDoc.ref.get().then(user => {
                      let points = user.data().points
                      userDoc.update({
                        points: ++points,
                        recentVote: firebase.firestore.FieldValue.serverTimestamp()
                      });
                    });
                  }
                  resolve();
                } else {
                  reject();
                }
              }).catch((error) => {
                reject();
                console.log("Users who have voted transaction failed: ", error);
              });
            }).catch(err => {
              console.log("Error casting vote", err);
              reject();
            });
          }
        });
      });
    });
  }

  decrementChoice(choiceKey, poll) {
    return new Promise((resolve, reject) => {
      const currentUser = this.afAuth.auth.currentUser;
      const isAnonymous = currentUser.isAnonymous;
      const uid = currentUser.uid;
      const pollDoc:any = this.db.collection('polls').doc(poll.id);
      const voteKey = `${poll.id}_${choiceKey}_${uid}`;
      pollDoc.ref.get().then(poll => {
        let pollData = poll.data();
        let userCreatedPoll = (pollData.uid == uid);
        let choices = pollData.choices;
        let usersWhoHaveVoted = pollData.usersWhoHaveVoted || [];
        // Remove UID from array of users who have voted
        usersWhoHaveVoted = usersWhoHaveVoted.filter(id => id !== uid);
        const choiceIndex = choices.findIndex((choice => choice.id == choiceKey));
        this.deleteVote(voteKey).then(() => {
          if (!isAnonymous) {
            const userDoc:any = this.db.collection('users').doc(uid);
            userDoc.ref.get().then(user => {
              let points = user.data().points
              this.db.collection('votes', ref => ref.where('poll', '==', poll.id)).valueChanges().pipe(first()).subscribe(votes => {
                let pollVotesCount = votes.length;
                userDoc.update({
                  points: (!userCreatedPoll && pollVotesCount == 0) ? --points-1 : --points
                });
              });
            });
          }
        }).then(() => {
          choices[choiceIndex].votes = --choices[choiceIndex].votes
          if (choices[choiceIndex].votes < 0) {
            choices[choiceIndex].votes = 0
          }
          pollDoc.update({
            choices: choices,
            usersWhoHaveVoted: usersWhoHaveVoted
          });
          resolve();
        }).catch(err => {
          console.log("Error deleting vote", err);
          reject();
        });
      });
    });
  }

  createVote(value) {
    return new Promise((resolve, reject) => {
      const af = this.db.collection("votes").doc(value.key);
      af.set({
        choice: value.choice,
        poll: value.poll,
        user: value.user,
        players: value.players,
        uid: value.uid,
        sport: value.sport,
        credibility: value.credibility,
        pollType: value.pollType,
        week: value.week,
        percentile: value.percentile,
        isInfluencer: value.isInfluencer,
        choicesAllowed: value.choicesAllowed,
        isAccuracyEligible: value.isAccuracyEligible,
        year: value.year,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      }).then(() => {
        af.ref.get().then(doc => {
          resolve(doc.data());
        })
      }).catch(err => {
        reject();
      })
    });
  }

  deleteVote(voteKey) {
    return new Promise((resolve, reject) => {
      this.db.collection('votes').doc(voteKey).delete().then((vote:any) => {
        console.log("Vote deleted successfully");
        resolve(vote);
      }).catch(err => {
        console.log("Error deleting vote", err);
        reject();
      });
    })
  }

  getVotes(vote) {
    return this.db.collection('votes', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (vote.choice) { query = query.where('choice', '==', vote.choice) };
      if (vote.poll) { query = query.where('poll', '==', vote.poll) };
      if (vote.uid) { query = query.where('uid', '==', vote.uid) };
      if (vote.limit) { query = query.limit(vote.limit) };
      query = query.orderBy('createdAt', 'desc')
      return query;
    }).snapshotChanges();
  }

  lastLoginTimestamp(uid) {
    this.db.collection('users').doc(uid).update({
      lastLogin: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  getUserByUsername(username) {
    return this.db.collection('users', ref => ref.where('username', '==', username).limit(1)).snapshotChanges();
  }

  getUserBySlug(slug) {
    let downcase = slug.toLowerCase();
    return this.db.collection('users', ref => ref.where('slug', '==', downcase).limit(1)).snapshotChanges();
  }

  getUser(userKey) {
    return this.db.collection('users').doc(userKey).snapshotChanges();
  }

  updateUser(userKey, value) {
    return this.db.collection('users').doc(userKey).update(value);
  }

  deletePoll(poll) {
    this.db.collection('polls').doc(poll.id).delete().then(deletedPoll => {
      const userDoc:any = this.db.collection('users').doc(poll.uid);
      userDoc.ref.get().then(user => {
        let points = user.data().points
        userDoc.update({
          points: points+environment.points.required

        });
      });
      // ToDo: Move this to a cloud function.
      console.log("Poll deleted successfully", deletedPoll);
      this.db.collection('comments', ref => ref.where('poll', '==', poll.id)).snapshotChanges().pipe(first()).subscribe((comments) => {
        comments.forEach(comment => {
          this.deleteComment(comment.payload.doc.id);
        });
      });
      // ToDo: Move this to a cloud function.
      this.db.collection('votes', ref => ref.where('poll', '==', poll.id)).snapshotChanges().pipe(first()).subscribe((votes) => {
        votes.forEach(vote => {
          this.deleteVote(vote.payload.doc.id);
        });
      })
    }).catch(err => {
      console.log("Error deleting poll", err);
    });
  }

  // Instead of deleting, set active = false, that way UI can display child comments
  deleteComment(commentKey) {
    this.db.collection('comments').doc(commentKey).delete().then((comment) => {
      console.log("Comment deleted successfully");
    }).catch(err => {
      console.log("Error deleting comment", err);
    });
  }

  percentRank(array, n) {
    array = array.sort(function(a, b){return b-a});
      var L = 0;
      var S = 0;
      var N = array.length

      for (var i = 0; i < array.length; i++) {
          if (array[i] < n) {
              L += 1
          } else if (array[i] === n) {
              S += 1
          } else {

          }
      }

      var pct = (L + (0.5 * S)) / N

      return pct
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAaYBD1FZUzvvobVJ4aN8P2L9ykUrfqbMM",
    authDomain: "poll-sports-stage.firebaseapp.com",
    databaseURL: "https://poll-sports-stage.firebaseio.com",
    projectId: "poll-sports-stage",
    storageBucket: "poll-sports-stage.appspot.com",
    messagingSenderId: "134297136288",
    appId: "1:134297136288:web:66e84be7ad1868860aedc5"
  },
  points: {
    // Points required to post a poll question
    required: 1,
    // Points earned when voting on a poll
    earned: 1,
    // Default points provided to new accounts
    default: 50
  },
  currentYear: "2020",
  accuracyVotesThreshold: 30,
  mailchimpHiddenInput: 'b_9a10d01d53cc47349a4615fc4_e386189b3f',
  mailchimpList: "",
  playersJsonFootball: "https://us-central1-poll-sports-stage.cloudfunctions.net/football",
  playersJsonBaseball: "https://us-central1-poll-sports-stage.cloudfunctions.net/baseball",
  cloudfunctionsUrl: "https://us-central1-poll-sports-stage.cloudfunctions.net",
  domain: "https://staging.pollsports.com",
  stripeKey: "pk_test_kb2uW0wQdxFbJQ1a0kVPIRjS00WAJVmp5G"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';

declare var heap:any;
declare var mixpanel:any;

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor() { }

  track(eventName, options={}) {
    if (typeof heap !== 'undefined') {
      heap.track(eventName, options);
    }
    if (typeof mixpanel !== 'undefined') {
      mixpanel.track(eventName, options)
    }
  }

  identify(email) {
    if (typeof mixpanel !== 'undefined') {
      mixpanel.identify(email);
    }
    if (typeof heap !== 'undefined') {
      heap.identify(email)
    }
  }

  mixpanelSetPeople(email) {
    if (typeof mixpanel !== 'undefined') {
      var d = new Date();
      mixpanel.people.set({
        "$email": email, // only special properties need the $
        "Sign up date": d.toISOString(),// Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
        "USER_ID": email, // use human-readable names
      });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { SeoService } from '../services/seo.service';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-api-access',
  templateUrl: './api-access.component.html',
  styleUrls: ['./api-access.component.scss']
})
export class ApiAccessComponent implements OnInit {

  constructor(private seo: SeoService, public nav: NavbarService) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | API',
      description: `Poll Sports API access.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `api-access`
    });
  }

}

import { Injectable } from '@angular/core';
import { TrackingService } from '../services/tracking.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { auth } from 'firebase/app';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as firebase from 'firebase';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState:any = null;
  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private http: HttpClient,
    private trackingService: TrackingService
  ) {}

  emailSignUp(email, password, username) {
    return new Promise((resolve, reject) => {
      // Check if username is already taken.
      // Firebase is stupid and is case sensitive
      // this hack will prevent duplicate usernames with different casing.
      let displayName = username;
      let downcasedUsername = username.toLowerCase();
      this.db.collection('usernames').doc(downcasedUsername).valueChanges().pipe(first()).subscribe((foundUsername:any) => {
        if (foundUsername) {
          reject({code: 1});
          return false;
        } else {
          const credential = firebase.auth.EmailAuthProvider.credential(email, password);
          firebase.auth().currentUser.linkWithCredential(credential).then((usercred:any) => {
            var user = usercred.user;
            console.log("Account linking success", user.uid);
            this.db.collection('users').doc(user.uid).set({
              email: email,
              points: environment.points.default,
              username: displayName,
              accuracy: 0.0,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              recentComment: firebase.firestore.FieldValue.serverTimestamp(),
              recentPoll: firebase.firestore.FieldValue.serverTimestamp(),
              recentVote: firebase.firestore.FieldValue.serverTimestamp(),
              slug: downcasedUsername,
              referrer: this.getCookie("referrer"),
              utm_source: this.getCookie("utm_source"),
              utm_campaign: this.getCookie("utm_campaign"),
              utm_medium: this.getCookie("utm_medium")
            }).then(() => {
              this.db.collection('usernames').doc(downcasedUsername).set({
                uid: user.uid
              });
              this.trackingService.identify(email);
              this.trackingService.track('Signup', {
                email: email,
                uid: user.uid,
                username: displayName,
                referrer: this.getCookie("referrer"),
                utm_source: this.getCookie("utm_source"),
                utm_campaign: this.getCookie("utm_campaign"),
                utm_medium: this.getCookie("utm_medium")
              });
              this.trackingService.mixpanelSetPeople(email);
              (async () => {
                const db = firebase.firestore();
                const querySnapshot = await db.collection('votes').where('uid', '==', user.uid).get({source: 'server'});
                const docs = querySnapshot.docs;
                if (docs.length > 0) {
                  for (const vote of docs) {
                    vote.ref.update({
                      'user': displayName
                    });
                  }
                }
              })();
              // Subscribe new account to Mailchimp
              if (environment.production) {
                const params = new HttpParams()
                      .set('EMAIL', email)
                      .set('REFERRER', '')
                      .set(environment.mailchimpHiddenInput, ''); // hidden input name

                const mailChimpEndpoint = environment.mailchimpList;
                const mailChimpUrl = mailChimpEndpoint + params.toString();

                this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
                  if (response.result && response.result !== 'error') {
                  }
                }, error => {
                  console.error(error);
                });
              }
              resolve(user);
            }).catch((err) => {
              reject(err);
            });
          }, (error) => {
            console.log("Account linking error", error);
            reject(error);
          });
        }
      });
    });
  }

  emailLogin(email, password) {
    return new Promise((resolve, reject) => {
      this.afAuth.auth.signInWithEmailAndPassword(email, password).then((user) => {
        this.db.collection('users').doc(user.user.uid).update({
          lastLogin: firebase.firestore.FieldValue.serverTimestamp()
        });
        this.trackingService.identify(email);
        resolve(user);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  signInAnonymously() {
    firebase.auth().signInAnonymously().catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  resetPassword(email) {
    var auth = firebase.auth();
    return auth.sendPasswordResetEmail(email)
      .then(() => console.log("email sent"))
      .catch((error) => console.log(error))
  }

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }

}

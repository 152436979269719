import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../services/firebase.service';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.scss']
})
export class PlayerDetailsComponent implements OnInit {
  title:string = "Details";
  visible:boolean = false;
  selectedPlayers:any;
  loading:boolean = false;

  @Input()
  displayPlayerDetails:boolean;

  @Input()
  playerIds:any

  @Input()
  games:any;

  @Input()
  poll:any;

  @Output()
  hideDetails = new EventEmitter<string>();


  constructor(private http: HttpClient, private firebaseService: FirebaseService) { }

  ngOnInit() {
    this.fetchDetails(this.poll);
    // this.http.get("https://www.rotoballer.com/partner-rssxml?partner=ru&key=JX1~F.Hy09s6rGp_nOFDtLhXEcn8hqz-VY-YvNnJjEBeIS5kJ.Hj.vT3VR8h").subscribe((news:any) => {
    //   console.log("News", news);
    // })
  }

  fetchDetails(poll) {
    this.title = `Week ${poll.week}`;
    this.selectedPlayers = []
    this.loading = true
    if (poll.players && poll.matchups.length < 1) {
      let promises = []
      for (const playerName of poll.players) {
        let playerId = this.playerIds[playerName]
        if (playerId) {
          promises.push(
            new Promise((resolve, reject) => {
              // TODO: Move this to cloud function
              this.http.get(environment.cloudfunctionsUrl+"/stats/"+playerId).subscribe((response:any) => {
                const player = response.Player;
                let game = this.games.filter((game) => game.awayTeam === player.team || game.homeTeam === player.team)[0]
                let matchup = {displayName: playerName, team: player.team, position: player.position, homeTeam: game.homeTeam, awayTeam: game.awayTeam};
                resolve(matchup);
              }, error => {
                console.log("Error retrieving football player");
                resolve({})
              })
            })
          );
        }
      }
      const results = Promise.all(promises).then((players) => {
        // remove duplicates
        let filteredPlayers = players.reduce((acc, current) => {
          const x = acc.find(item => item.displayName === current.displayName);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.selectedPlayers = filteredPlayers;
        this.firebaseService.updatePoll(poll.id, {matchups: this.selectedPlayers})
        this.loading = false;
      })
    } else {
      this.selectedPlayers = poll.matchups
      this.loading = false;
    }
  }

  close() {
    this.hideDetails.emit();
  }

}

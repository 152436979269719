import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { NavbarService } from '../services/navbar.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { NgxPaginationModule } from 'ngx-pagination';
import { SeoService } from '../services/seo.service';
import { AppConfig } from '../../app-config';


@Component({
  selector: 'app-vote-history',
  templateUrl: './vote-history.component.html',
  styleUrls: ['./vote-history.component.scss']
})
export class VoteHistoryComponent implements OnInit {
  polls:any = [];
  preFilterPolls:any;
  loading:boolean = true;
  displayAccountModal:boolean = false;
  p: number = 1;
  currentUser:any
  currentUsername:any;
  currentEmail:any;
  credibility:number;
  accuracyTotal:any;

  constructor(
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private seo: SeoService,
    public nav: NavbarService,
    private location: Location,
    private _config: AppConfig
  ) { }

  ngOnInit() {
    this.nav.show();
    this.seo.generateTags({
      title: 'Poll Sports | Search polls',
      description: `Search fantasy football polls by league type and scoring format.`,
      image: 'https://pollsports.com/assets/logo-blue-prod.png',
      slug: `search`
    });
    this.route.queryParams.subscribe(params => {
      if (params['page'] !== undefined) {
        this.p = parseInt(params['page']);
      }
    });
    this.polls = [];
    this.preFilterPolls = [];
    this.afAuth.authState.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser) {
        this.firebaseService.getUser(this.currentUser.uid).pipe(first()).subscribe((user:any) => {
          this.currentUsername = user.payload.get('username') ? user.payload.get('username') : '';
          this.currentEmail = user.payload.get('email') ? user.payload.get('email') : '';
          this.credibility = user.payload.get('credibility') ? user.payload.get('credibility') : 0;
          this.accuracyTotal = user.payload.get('accuracyTotal') ? user.payload.get('accuracyTotal') : 0;
        });
      }
    });
    this.getPolls();
  }

  showLogin() {
    this.displayAccountModal = true;
  }

  hideAccountModal() {
    this.displayAccountModal = false;
  }

  updatePolls(event) {
    this.polls = event;
  }

  deletePoll(poll) {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete this poll?',
        accept: () => {
          this.firebaseService.deletePoll(poll);
        }
    });
  }

  pageChanged(event) {
    this.p = event ? event : 1;
    let currentParams = window.location.search.split("?page=")[1] && window.location.search.split("?page=")[1].substring(1);
    this.location.replaceState(`search?page=${this.p}${currentParams ? currentParams : ''}`)
  }

  getPolls() {
    let fetchedWeek:any = this._config.WEEK
    this.firebaseService.getPolls({week: parseInt(fetchedWeek), getActive: true}).then((results:any) => {
      this.polls = results.polls;
      this.preFilterPolls = this.polls;
      this.loading = false;
    });
  }

  nextPage() {
    return this.p + 1
  }

  previousPage() {
    if (this.p > 1) {
      return this.p - 1
    }
  }

}
